import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Dragger from 'antd/es/upload/Dragger'
import TextArea from 'antd/es/input/TextArea'
import { useAppSelector } from '../../../reducers/hooks'
import { Form, FormInstance, Input, Typography } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { selectSelectedContract } from '../../../reducers/ContractReducer'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const contract = useAppSelector(selectSelectedContract())

  useEffect(() => {
    form.setFieldsValue({
      id: contract?.id,
      notes: contract?.notes ?? '',
    })
  }, [contract])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        style={{ display: 'none' }}
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
      >
        <TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="file"
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.ReplaceTheFile,
        })}
      >
        <Dragger
          style={{
            borderStyle: 'dashed',
            borderColor: '#dc4446',
            background: '#281f20',
          }}
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <p style={{ color: '#dc4446' }} className="ant-upload-drag-icon">
            {intl.formatMessage({
              id: LocalizationKeys.Misc.Form.UploadFileToReplaceCurrent,
            })}
          </p>
        </Dragger>
      </Form.Item>
    </Form>
  )
}

export default Update
