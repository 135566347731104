import { setSelectedArtist } from '../../reducers/ArtistReducer'
import { setSelectedBooking } from '../../reducers/BookingReducer'
import { setSelectedBookingType } from '../../reducers/BookingTypeReducer'
import { setSelectedContactAssociation } from '../../reducers/ContactAssociationReducer'
import { setSelectedContact } from '../../reducers/ContactReducer'
import { setSelectedContactType } from '../../reducers/ContactTypeReducer'
import { setSelectedContract } from '../../reducers/ContractReducer'
import { setSelectedContractStatus } from '../../reducers/ContractStatusReducer'
import { setSelectedCurrency } from '../../reducers/CurrencyReducer'
import { setSelectedEvent } from '../../reducers/EventReducer'
import { setSelectedEventStatus } from '../../reducers/EventStatusReducer'
import { setSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { setSelectedFile } from '../../reducers/FileReducer'
import { setSelectedGround } from '../../reducers/GroundReducer'
import { setSelectedHotel } from '../../reducers/HotelReducer'
import { setSelectedInvoice } from '../../reducers/InvoiceReducer'
import { setSelectedNotification } from '../../reducers/NotificationReducer'
import { setSelectedPayment } from '../../reducers/PaymentReducer'
import { setSelectedPerformance } from '../../reducers/PerformanceReducer'
import { setSelectedRider } from '../../reducers/RiderReducer'
import { setSelectedRiderStatus } from '../../reducers/RiderStatusReducer'
import { setSelectedRoom } from '../../reducers/RoomReducer'
import { setSelectedStage } from '../../reducers/StageReducer'
import { setSelectedStation } from '../../reducers/StationReducer'
import { setSelectedStationType } from '../../reducers/StationTypeReducer'
import { setSelectedTravel } from '../../reducers/TravelReducer'
import { setSelectedTravelType } from '../../reducers/TravelTypeReducer'
import { setSelectedUser } from '../../reducers/UserReducer'
import { setSelectedVenue } from '../../reducers/VenueReducer'
import { setSelectedVenueType } from '../../reducers/VenueTypeReducer'
import { SBModel } from '../../utils/helpers/crud/models'
import { SBRMType, SBRMTypeInfosType } from './SBRMModel'

export const SBRMTypeInfos: SBRMTypeInfosType<SBModel> = {
  [SBRMType.artist]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedArtist,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'artist',
  },
  [SBRMType.venue]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedVenue,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'venue',
  },
  [SBRMType.venueType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedVenueType,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'venueType',
  },
  [SBRMType.stage]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStage,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'stage',
  },
  [SBRMType.event]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedEvent,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'event',
  },
  [SBRMType.eventStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedEventStatus,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'eventStatus',
  },
  [SBRMType.booking]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedBooking,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
      comment: { rightPanelContent: 'comments' },
    },
    reducerName: 'booking',
  },
  [SBRMType.bookingType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedBookingType,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'bookingType',
  },
  [SBRMType.contract]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContract,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      view: { leftPanelContent: 'file-viewer' },
      comment: {
        rightPanelContent: 'comments',
        leftPanelContent: 'file-viewer',
      },
    },
    filePropertyName: 'file',
    reducerName: 'contract',
  },
  [SBRMType.contractStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContractStatus,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'contractStatus',
  },
  [SBRMType.invoice]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedInvoice,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      view: { leftPanelContent: 'file-viewer' },
      comment: {
        leftPanelContent: 'file-viewer',
        rightPanelContent: 'comments',
      },
    },
    filePropertyName: 'files',
    reducerName: 'invoice',
  },
  [SBRMType.payment]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPayment,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    filePropertyName: 'pop',
    reducerName: 'payment',
  },
  [SBRMType.hotel]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedHotel,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'hotel',
  },
  [SBRMType.room]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRoom,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'room',
  },
  [SBRMType.rider]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRider,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      view: { leftPanelContent: 'file-viewer' },
      comment: {
        rightPanelContent: 'comments',
        leftPanelContent: 'file-viewer',
      },
    },
    filePropertyName: 'file',
    reducerName: 'rider',
  },
  [SBRMType.riderStatus]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedRiderStatus,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'riderStatus',
  },
  [SBRMType.file]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedFile,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form', leftPanelContent: 'file-viewer' },
      view: { leftPanelContent: 'file-viewer' },
    },
    filePropertyName: null,
    reducerName: 'file',
  },
  [SBRMType.user]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedUser,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'user',
  },
  [SBRMType.contact]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContact,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'contact',
  },
  [SBRMType.contactType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContactType,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'contactType',
  },
  [SBRMType.contactAssociation]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedContactAssociation,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'contactAssociation',
  },
  [SBRMType.travel]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTravel,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'travel',
  },
  [SBRMType.travelType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTravelType,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'travelType',
  },
  [SBRMType.ground]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedGround,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'ground',
  },
  [SBRMType.performance]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedPerformance,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'performance',
  },
  [SBRMType.station]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStation,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'station',
  },
  [SBRMType.stationType]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStationType,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'stationType',
  },
  [SBRMType.customAddress]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedStationType, // TO BE CHANGED
    },
    layout: {
      create: { rightPanelContent: 'form' },
      duplicate: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'address',
  },
  [SBRMType.notification]: {
    creatable: false,
    updatable: true,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedNotification,
    },
    layout: {},
    reducerName: 'notification',
  },
  [SBRMType.externalAdvancingLink]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedExternalAdvancingLink,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
      comment: { rightPanelContent: 'comments' },
    },
    reducerName: 'externalAdvancingLink',
  },
  [SBRMType.currency]: {
    creatable: false,
    updatable: false,
    viewable: false,
    deletable: false,
    duplicable: false,
    methods: {
      setSelected: setSelectedCurrency,
    },
    layout: {
      create: { rightPanelContent: 'form' },
      update: { rightPanelContent: 'form' },
      view: { rightPanelContent: 'form' },
    },
    reducerName: 'currency',
  },
}
