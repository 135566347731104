import { SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import {
  getTimetableSectionData,
  selectPerformances,
} from '../../reducers/PerformanceReducer'
import { Card, List, Skeleton, Space } from 'antd'
import { Performance } from '../performance/Performance'
import { Booking } from '../booking/Booking'
import { Stage } from '../stage/Stage'
import { Performance as PerformanceModel } from '../../models/Performance'

const TimetableSection = ({}: SectionProps) => {
  const dispatch = useAppDispatch()

  const performances = useAppSelector(selectPerformances())
  const { isLoading } = useAppSelector((state) => state.performance)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  useEffect(() => {
    if (link === undefined) return
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getTimetableSectionData(query))
  }, [link])

  if (isLoading) return <Skeleton />

  const performancesGrouppedByStage = performances.reduce(
    (acc: Record<number, PerformanceModel[]>, performance) => {
      if (acc.hasOwnProperty(performance.stage)) {
        acc[performance.stage].push(performance)
      } else {
        acc[performance.stage] = [performance]
      }
      return acc
    },
    {}
  )

  return (
    <>
      {Object.entries(performancesGrouppedByStage).map(
        ([stage, performances]) => (
          <Space
            direction="vertical"
            key={stage}
            style={{ width: '100%' }}
            size={0}
          >
            <Card
              bordered
              title={<Stage.Tag id={Number(stage)} />}
              styles={{
                body: { padding: 0 },
                header: {
                  padding: 10,
                  minHeight: 'unset',
                },
              }}
            >
              <List
                dataSource={performances}
                renderItem={(performance) => (
                  <List.Item style={{ padding: 10 }}>
                    <Booking.Avatar id={performance.booking ?? 0} />
                    <Performance.Cell id={performance.id} />
                  </List.Item>
                )}
              />
            </Card>
          </Space>
        )
      )}
    </>
  )
}

export default TimetableSection
