import { Space, Tag } from 'antd'
import { Invoice } from '../../models/Invoice'
import { useAppSelector } from '../../reducers/hooks'
import { currencyFormat } from '@supplement-bacon/alela-uikit'
import { selectInvoiceById } from '../../reducers/InvoiceReducer'
import { selectCurrencyById } from '../../reducers/CurrencyReducer'

interface Props {
  id?: number
  model?: Invoice
}

export const AmountsTag = ({ id, model }: Props) => {
  const invoice = model ? model : useAppSelector(selectInvoiceById(id ?? 0))
  const invoiceCurrency = useAppSelector(
    selectCurrencyById(invoice?.currency ?? 0)
  )
  console.log(invoice)
  if (invoice === undefined) return <></>

  const getColor = () => {
    if (
      invoiceCurrency == undefined ||
      invoice === undefined ||
      invoice.sumPaid === undefined
    )
      return undefined

    // We can color only if all payments have the same currency as the invoice
    if (
      invoice.sumPaid.length !== 1 ||
      invoice.sumPaid[0].currency.id !== invoiceCurrency.id
    )
      return undefined

    const sumPaid = invoice.sumPaid[0].sum
    if (sumPaid === invoice.amountIncludingTaxes) {
      return 'green'
    }
    if (sumPaid < invoice.amountIncludingTaxes) {
      return 'orange'
    }
    if (sumPaid > invoice.amountIncludingTaxes) {
      return 'red'
    }
  }

  return (
    <Tag color={getColor()}>
      {invoice.sumPaid !== undefined && invoice.sumPaid.length > 0 && (
        <Space direction="horizontal">
          <Space direction="vertical" size={0}>
            {invoice.sumPaid?.map((sumPaid) =>
              currencyFormat(sumPaid.sum, sumPaid.currency)
            )}
          </Space>
          /{currencyFormat(invoice.amountIncludingTaxes, invoiceCurrency)}
        </Space>
      )}
      {invoice.sumPaid === undefined ||
        (invoice.sumPaid.length === 0 &&
          currencyFormat(invoice.amountIncludingTaxes, invoiceCurrency))}
    </Tag>
  )
}

export type AmountsTagType = { AmountsTag: typeof AmountsTag }
