import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
  SBAPIFetch,
} from '../utils/helpers/SBAPIHelper'
import { Travel, TravelSearch } from '../models/Travel'
import { TRAVELS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { travelSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { getExternalAdvancingSectionData } from './ExternalAdvancingLinkReducer'

const initialState: SBState<Travel> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'travel',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getTravelsSuccess,
  setQuery: setTravelQuery,
  reset: resetTravelState,
  resetQueryAndIds: resetTravelQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Travel }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTravels = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedTravel = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTravelById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTravelsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTravel =
  (travel: Travel | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        travel ? getIdOrModelId<Travel>(travel) : undefined
      )
    )

export const getTravels = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Travel>(
    TRAVELS_URL,
    params,
    [travelSchema],
    slice.actions
  )

export const getTravelWithId = (id: number) =>
  SBAPIFetchDispatch<Travel>(
    `${TRAVELS_URL}/${id}`,
    travelSchema,
    slice.actions
  )

export const createTravel = (travel: Travel) =>
  SBAPICreate<Travel>(travel, TRAVELS_URL, travelSchema, slice.actions)

export const updateTravel = (travel: Travel) =>
  SBAPIUpdate<Travel>(travel, `${TRAVELS_URL}/${travel.id}`, slice.actions)

export const deleteTravel = (travel: Travel) =>
  SBAPIDelete<Travel>(travel, `${TRAVELS_URL}/${travel.id}`, slice.actions)

export const searchFlight = (data: { number: string; date: string }) =>
  SBAPIFetch<Travel[]>(`${TRAVELS_URL}/search/flight`, data)

export const searchTrain = (data: { number: string; date: string }) =>
  SBAPIFetch<TravelSearch[]>(`${TRAVELS_URL}/search/train`, data)

export const getExternalAdvancingTravelSectionData = (params: TableParams) =>
  getExternalAdvancingSectionData(
    'travel',
    params,
    [travelSchema],
    slice.actions
  )
