import { Avatar, Space, Typography } from 'antd'
import { selectArtistById } from '../../reducers/ArtistReducer'
import { useAppSelector } from '../../reducers/hooks'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { AvatarProps } from 'antd/es/avatar'
import { Artist } from '../../models/Artist'

const { Text } = Typography

interface Props {
  id?: number
  model?: Artist
  linkToDetails?: boolean
  displayName?: boolean
  size?: AvatarProps['size']
}

export const ArtistAvatar = ({
  id,
  model,
  linkToDetails = true,
  displayName = true,
  size = 'default',
}: Props) => {
  const artist = model ? model : useAppSelector(selectArtistById(id ?? 0))

  return artist ? (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/artists/${artist.id}`}
    >
      <Space direction="horizontal">
        {artist.spotify_image && (
          <Avatar shape="circle" src={artist.spotify_image} size={size} />
        )}
        {!artist.spotify_image && (
          <Avatar
            size={size}
            style={{
              color: '#fff',
              backgroundColor: '#5d5df6',
              cursor: 'pointer',
            }}
          >
            <div style={{ textTransform: 'uppercase' }}>
              {artist.name.slice(0, 1)}
            </div>
          </Avatar>
        )}

        {displayName && (
          <Text style={{ wordBreak: 'keep-all' }}>{artist.name}</Text>
        )}
      </Space>
    </ConditionalLinkWrapper>
  ) : (
    <></>
  )
}

export type ArtistAvatarType = { Avatar: typeof ArtistAvatar }
