import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { Col, List, Row, Skeleton, Space } from 'antd'
import { useIntl } from 'react-intl'
import {
  getExternalAdvancingRiderSectionData,
  selectRiders,
} from '../../reducers/RiderReducer'
import { RiderStatus } from '../rider-status/RiderStatus'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import ApprovableItemCard from '../ApprovableItemCard'
import { File } from '../file/File'

const RiderSection = ({}: SectionProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const riders = useAppSelector(selectRiders())
  const { isLoading } = useAppSelector((state) => state.rider)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getExternalAdvancingRiderSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.rider) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (isLoading) return <Skeleton />

  return (
    <List
      dataSource={riders}
      renderItem={(rider) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={rider}>
            <Row>
              <Col span={12} style={{ alignContent: 'center' }}>
                <File.Cell id={rider.file} maxWidth={300} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'center' }}
              >
                <RiderStatus.Tag id={rider.status} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'right' }}
              >
                <Actions
                  section={ALMSectionKey.technical}
                  actions={['view', 'update', 'delete']}
                  entity={SBRMType.rider}
                  entityId={rider.id}
                />
              </Col>
            </Row>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default RiderSection
