import { FC } from 'react'
import { HotelAvatar, HotelAvatarType } from './Avatar'
import { HotelOption, HotelOptionType } from './Option'

export const Hotel: FC &
  HotelAvatarType &
  HotelOptionType = (): JSX.Element => <></>

Hotel.Avatar = HotelAvatar
Hotel.Option = HotelOption
