import { Space } from 'antd'
import { Comment } from './Comment'
import { useAppSelector } from '../../reducers/hooks'
import { selectComments } from '../../reducers/CommentReducer'

interface Props {}

export const CommentWrapper = ({}: Props) => {
  const comments = useAppSelector(selectComments())

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Comment.List ids={comments.map((c) => c.id)} />
    </Space>
  )
}

export type CommentWrapperType = { Wrapper: typeof CommentWrapper }
