import { RootState } from '../../reducers/store'
import { PayloadAction } from '@reduxjs/toolkit'
import { SBModel, initialQuery } from './crud/models'
import { TableParams } from '../../models/TableParams'

/**
 * TYPES
 */

export interface SBState<T> {
  isLoading: boolean
  error: Error | string | null
  query: TableParams
  items: { [key: string]: T }
  ids: number[]
  selectedId: number | undefined
}

export type SBSelectRaw<T> = (state: RootState) => T

/**
 * REDUCERS
 */

export const startLoading = (state: any) => ({
  ...state,
  isLoading: true,
  error: null,
})
export const stopLoading = (state: any) => ({
  ...state,
  isLoading: false,
})
export const hasError = (state: any, action: PayloadAction) => ({
  ...state,
  isLoading: false,
  error: action.payload,
})
export const setQuery = (state: any, action: PayloadAction<TableParams>) => ({
  ...state,
  query: action.payload,
})
export const setPagination = (
  state: any,
  action: PayloadAction<TableParams['pagination']>
) => ({
  ...state,
  query: {
    ...state.query,
    pagination: { ...state.query.pagination, ...action.payload },
  },
})
export const setSelectedId = (
  state: any,
  action: PayloadAction<number | string | undefined>
) => ({
  ...state,
  selectedId: action.payload,
})
export const setIds = (
  state: any,
  action: PayloadAction<(string | number)[]>
) => ({
  ...state,
  ids: action.payload,
})
export const resetQueryAndIds = (
  state: any,
  action: PayloadAction<TableParams>
) => ({
  ...state,
  ids: [],
  query: {
    ...initialQuery,
    ...(action.payload ?? {}),
  },
})
export const reset = (state: any) => ({
  ...state,
  isLoading: false,
  error: null,
  query: initialQuery,
  items: {},
  ids: [],
  selectedId: undefined,
})
export const getItemsSuccess = (state: any, action: PayloadAction<any>) => ({
  ...state,
  items: { ...state.items, ...action.payload },
})
export const updateItemSuccess = (
  state: any,
  action: PayloadAction<SBModel>
) => {
  const next = { ...state.items }
  next[action.payload.id] = { ...next[action.payload.id], ...action.payload }
  return {
    ...state,
    isLoading: false,
    items: next,
  }
}
export const deleteItemSuccess = (
  state: any,
  action: PayloadAction<SBModel>
) => {
  const next = { ...state.items }
  delete next[action.payload.id]
  return {
    ...state,
    isLoading: false,
    items: next,
    ids: state.ids.filter((i: any) => i.id !== action.payload.id),
  }
}

export const baseReducers = {
  startLoading,
  stopLoading,
  hasError,
  setIds,
  setQuery,
  setPagination,
  setSelectedId,
  reset,
  resetQueryAndIds,
  getItemsSuccess,
  updateItemSuccess,
  deleteItemSuccess,
}

/**
 * Merge two array of object without creating duplicates
 * In case of value present in both, the value in new the array is kept
 *
 * @param initialData Array<any>
 * @param newData Array<any>
 */
export function mergeObjectArraysWithoutDuplicates(
  initialData: Array<any>,
  newData: Array<any>
) {
  const ids = new Set(newData.map((i) => i.id))
  return [...newData, ...initialData.filter((i) => !ids.has(i.id))]
}

export function mergeNumberArraysWithoutDuplicates(
  initialData: Array<number>,
  newData: Array<number>
) {
  const concat = initialData.concat(newData)
  return concat.filter((item, pos) => concat.indexOf(item) === pos)
}

export function getIdsFromArray<T>(
  array: T[],
  transform: (item: T) => number
): number[] {
  return array.reduce<number[]>(
    (accumulator: number[], currentItem: T) =>
      mergeNumberArraysWithoutDuplicates(accumulator, [transform(currentItem)]),
    []
  )
}

export function getIdOrModelId<T extends SBModel>(arg: T | number): number {
  return Number.isNaN(arg) ? (arg as T).id : Number(arg)
}
export function getUUIDOrModelUUID<T>(arg: T | string) {
  return typeof arg === 'string' ? arg : (arg as any).id
}
