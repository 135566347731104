import { Link } from 'react-router-dom'
import RightHeader from './RightHeader'
import { useAppSelector } from '../../reducers/hooks'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import { Avatar, Layout, Space, Tag, Typography } from 'antd'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import dayjs from 'dayjs'

const { Header } = Layout
const { Text, Title } = Typography

interface MainHeaderProps {}

const MainHeader = ({}: MainHeaderProps) => {
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const headerStyle: React.CSSProperties = {
    backgroundColor: '#1E1E1E',
    justifyContent: 'space-between',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  }

  return (
    <div className={'fixed z-20 w-full'}>
      <Header style={headerStyle}>
        {link === undefined && (
          <div className="logo d-flex">
            <img
              src={'/assets/images/logocurve.png'}
              height={45}
              style={{ marginTop: 12 }}
            />
          </div>
        )}
        {link !== undefined && (
          <Space size={12}>
            <Link to={'/event-selector'} style={{ color: 'white' }}>
              <ArrowLeftOutlined />
            </Link>
            <Avatar
              shape="square"
              size={40}
              src={link.booking.event?.header_picture}
            />
            <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
              {link.booking.event?.name}
            </Title>
            <Tag>
              <CalendarOutlined style={{ marginRight: 5 }} />
              {dayjs(link.booking.event?.start_date).format(
                'DD MMM YY - HH:mm'
              )}{' '}
              <ArrowRightOutlined />{' '}
              {dayjs(link.booking.event?.end_date).format('DD MMM YY - HH:mm')}
            </Tag>
          </Space>
        )}
        <div
          style={{
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <RightHeader />
        </div>
      </Header>
    </div>
  )
}

export default MainHeader
