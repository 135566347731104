import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import axios from 'axios'
import { User } from '../models/User'
import { Role } from '../models/Role'
import { userSchema } from '../models/schema'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ACCOUNT_USERS_URL, USER_ROLE_URL } from '../utils/urls'

const initialState: SBState<User> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getUsersSuccess,
  setQuery: setUserQuery,
  reset: resetUserState,
  resetQueryAndIds: resetUserQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: User }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectUsers = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllUsers = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )

export const selectSelectedUser = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectUserById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectUsersByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedUser =
  (user: User | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<User>(user)))

export const getAccountUsers = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<User>(
    ACCOUNT_USERS_URL,
    params,
    [userSchema],
    slice.actions
  )
