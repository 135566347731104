import { FormInstance } from 'antd'
import { MetaData, MetaDataKey } from '../../models/MetaData'

export enum SBRMType {
  artist = 'artist',
  event = 'event',
  eventStatus = 'event status',
  booking = 'booking',
  bookingType = 'booking type',
  contract = 'contract',
  contractStatus = 'contract status',
  invoice = 'invoice',
  payment = 'payment',
  hotel = 'hotel',
  room = 'room',
  rider = 'rider',
  riderStatus = 'rider status',
  file = 'file',
  user = 'user',
  venue = 'venue',
  venueType = 'venue type',
  stage = 'stage',
  contact = 'contact',
  contactType = 'contact type',
  contactAssociation = 'contact association',
  travel = 'travel',
  travelType = 'travel type',
  ground = 'ground',
  performance = 'performance',
  station = 'station',
  stationType = 'station type',
  customAddress = 'customaddress',
  notification = 'notification',
  externalAdvancingLink = 'external advancing link',
  currency = 'currency',
}
export const isSBRMType = (value: string): value is SBRMType =>
  Object.values<string>(SBRMType).includes(value)

export enum SBRMContactAssociationType {
  booking = 'booking',
  event = 'event',
  artist = 'artist',
  venue = 'venue',
  supplier = 'supplier',
}

export type SBRMAction =
  | 'create'
  | 'duplicate'
  | 'update'
  | 'view'
  | 'delete'
  | 'comment'
export const isSBRMAction = (value: string): value is SBRMAction =>
  ['create', 'duplicate', 'update', 'view', 'delete', 'comment'].includes(value)

export type SBRMReducer =
  | 'event'
  | 'eventStatus'
  | 'artist'
  | 'venue'
  | 'venueType'
  | 'stage'
  | 'booking'
  | 'bookingType'
  | 'user'
  | 'hotel'
  | 'room'
  | 'contact'
  | 'contactType'
  | 'contactAssociation'
  | 'file'
  | 'fileType'
  | 'contract'
  | 'contractStatus'
  | 'invoice'
  | 'payment'
  | 'rider'
  | 'riderStatus'
  | 'travel'
  | 'travelType'
  | 'ground'
  | 'performance'
  | 'station'
  | 'stationType'
  | 'address'
  | 'notification'
  | 'externalAdvancingLink'
  | 'currency'

export interface SBRMFormProps {
  isNested: boolean
}
export interface SBRMFormInterface {
  handleCreate: () => Promise<boolean>
  handleUpdate: () => Promise<boolean>
  handleDelete: () => Promise<boolean>
  handleReset: () => void
}

export interface SBRMFormStepProps {
  form: FormInstance
  isNested: boolean
}

export const getMetadata = (
  metadata: MetaData[] | undefined,
  key: MetaDataKey
) => {
  const extractedData = (metadata ?? []).find((m: MetaData) => m.key === key)
  return extractedData ? extractedData.value : undefined
}

type SBRMLayoutAction = {
  rightPanelContent?: 'form' | 'comments'
  leftPanelContent?: 'file-viewer'
}

type SBRMLayout = {
  update?: SBRMLayoutAction
  view?: SBRMLayoutAction
  create?: SBRMLayoutAction
  duplicate?: SBRMLayoutAction
  delete?: SBRMLayoutAction
  comment?: SBRMLayoutAction
}

export type SBRMTypeInfosType<T> = Record<
  SBRMType,
  {
    // --- Not used yet START
    creatable: boolean
    updatable: boolean
    viewable: boolean
    deletable: boolean
    duplicable: boolean
    // --- Not used yet END
    methods: {
      setSelected: any
    }
    layout: SBRMLayout
    filePropertyName?: undefined | null | string // undefined -> no file | null -> resource is file | string -> property to access file
    reducerName: SBRMReducer
  }
>
