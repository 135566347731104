import { FC } from 'react'
import { Infos, InfosType } from './Infos'
import { Cascader, CascaderType } from './Cascader'
import { CascaderItem, CascaderItemType } from './CascaderItem'
import { DurationFormItem, DurationFormItemType } from './DurationFormItem'
import {
  PickupTimeFormItem,
  PickupTimeFormItemType,
} from './PickupTimeFormItem'
import { GroundForm, GroundFormType } from './form/Form'

export const Ground: FC &
  CascaderType &
  InfosType &
  CascaderItemType &
  DurationFormItemType &
  PickupTimeFormItemType &
  GroundFormType = (): JSX.Element => <></>

Ground.Infos = Infos
Ground.Cascader = Cascader
Ground.CascaderItem = CascaderItem
Ground.DurationFormItem = DurationFormItem
Ground.PickupTimeFormItem = PickupTimeFormItem
Ground.Form = GroundForm
