import { Approvable, SBModel } from '../utils/helpers/crud/models'
import { Booking } from './Booking'
import { Contact } from './Contact'
import { StationWithRelation } from './Station'

export interface Travel extends SBModel, Approvable {
  inbound: boolean
  number: string
  departureTime: string
  arrivalTime: string

  // Relations
  departure: number
  arrival: number
  type: number
  booking: number
  contacts: number[]
}

export interface TravelWithRelation extends SBModel, Approvable {
  inbound: boolean
  number: string
  departureTime: string
  arrivalTime: string

  // Relations
  departure: StationWithRelation
  arrival: StationWithRelation
  type: TravelType
  booking?: Booking
  contacts: Contact[]
}

export interface TravelType extends SBModel {
  name: string
  icon?: string
  color?: string
}

export const TRAVEL_TYPE_TRAIN = 1
export const TRAVEL_TYPE_FLIGHT = 2

export interface TravelSearch {
  number: string
  type: TravelType
  stops: { departure: string; station: StationWithRelation }[]
}

export interface TravelSearchSelection {
  number: string
  type: TravelType
  departure: StationWithRelation
  departureTime: string
  arrival: StationWithRelation
  arrivalTime: string
}
