import { Artist as ArtistModel } from '../../../../models/Artist'
import {
  Contact as ContactModel,
  ContactType,
} from '../../../../models/Contact'
import { Hotel as HotelModel } from '../../../../models/Hotel'
import { TravelType } from '../../../../models/Travel'
import { SBRMType } from '../../../../modules/sbrm/SBRMModel'
import { SBModel } from '../../../../utils/helpers/crud/models'
import {
  ARTISTS_URL,
  CONTACTS_URL,
  CONTACT_TYPES_URL,
  GROUND_URL,
  HOTELS_URL,
  ROLES_URL,
  STATIONS_URL,
  STATION_TYPES_URL,
  TRAVELS_URL,
  TRAVEL_TYPE_URL,
  CONTRACT_STATUSES_URL,
  RIDER_STATUSES_URL,
} from '../../../../utils/urls'
import { Artist } from '../../../artist/Artist'
import { Contact } from '../../../contact/Contact'
import { Station as StationComponent } from '../../../station/Station'
import { StationType, StationWithRelation } from '../../../../models/Station'
import { Hotel } from '../../../hotel/Hotel'
import { ContractStatus } from '../../../../models/ContractStatus'
import { RiderStatus } from '../../../../models/RiderStatus'
import { SBAsyncSelectConfig } from '../types'

const defaultConfig: SBAsyncSelectConfig<SBModel> = {
  [SBRMType.artist]: {
    endpoint: ARTISTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Artist.Avatar
        model={item as unknown as ArtistModel}
        linkToDetails={false}
        size="default"
      />
    ),
  },
  [SBRMType.contractStatus]: {
    endpoint: CONTRACT_STATUSES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as ContractStatus).name}</>,
  },
  [SBRMType.hotel]: {
    endpoint: HOTELS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Hotel.Option model={item as unknown as HotelModel} />
    ),
  },
  [SBRMType.riderStatus]: {
    endpoint: RIDER_STATUSES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as RiderStatus).name}</>,
  },
  [SBRMType.contact]: {
    endpoint: CONTACTS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <Contact.Avatar
        size={'small'}
        model={item as unknown as ContactModel}
        displayCompany={false}
        editable={false}
      />
    ),
  },
  [SBRMType.contactType]: {
    endpoint: CONTACT_TYPES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as ContactType).name}</>,
  },
  [SBRMType.travel]: {
    endpoint: TRAVELS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.travelType]: {
    endpoint: TRAVEL_TYPE_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as TravelType).name}</>,
  },
  [SBRMType.ground]: {
    endpoint: GROUND_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
  [SBRMType.station]: {
    endpoint: STATIONS_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => (
      <StationComponent.Option item={item as unknown as StationWithRelation} />
    ),
  },
  [SBRMType.stationType]: {
    endpoint: STATION_TYPES_URL,
    canbeRadio: true,
    displayEmpty: true,
    option: (item: SBModel) => <>{(item as unknown as StationType).name}</>,
  },
  [SBRMType.customAddress]: {
    endpoint: ROLES_URL,
    canbeRadio: false,
    displayEmpty: true,
    option: (item: SBModel) => <></>,
  },
}

export default defaultConfig
