import './App.css'
import { App as ANTDApp, ConfigProvider, theme } from 'antd'
import { Outlet } from 'react-router-dom'
import NewVersionChecker from './components/NewVersionChecker'
import { MaintenanceMode } from './components/MaintenanceMode'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import { useLang } from './i18n/useLang'
import frFR from 'antd/locale/fr_FR'
import { setupAxios } from './utils/setupAxios'
import axios from 'axios'
import { ArgsProps } from 'antd/lib/notification/interface'
import { AuthInit, AuthProvider } from './utils/auth/Auth'
import SBRM from './modules/sbrm/SBRM'
import getConfigInstance from './components/custom-inputs/sb-async-select/config/SBAsyncSelectConfig'
import defaultConfig from './components/custom-inputs/sb-async-select/data/default'

const { defaultAlgorithm, darkAlgorithm } = theme

function App() {
  const { notification } = ANTDApp.useApp()
  const { selectedLocale } = useLang()

  /**
   * Setup dayjs locale globally
   */
  dayjs.locale(selectedLocale)

  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios, (args: ArgsProps) => {
    notification.error({ ...args, placement: 'bottomRight' })
  })

  /**
   * SBAsyncSelect configuration
   */
  const { setValue } = getConfigInstance()
  setValue(defaultConfig)

  return (
    <ConfigProvider
      locale={selectedLocale === 'fr' ? frFR : undefined}
      theme={{
        algorithm: darkAlgorithm,
        token: {
          colorPrimary: '#5d5df6',
          colorBgBase: '#000000',
          colorBgLayout: '#1b1a1a',
          colorBgContainer: '#1E1E1E',
        },
      }}
    >
      <ANTDApp>
        <MaintenanceMode>
          <>
            <AuthProvider>
              <AuthInit>
                <>
                  <Outlet />
                  <SBRM />
                </>
              </AuthInit>
            </AuthProvider>
            {/* <NewVersionChecker /> */}
          </>
        </MaintenanceMode>
      </ANTDApp>
    </ConfigProvider>
  )
}

export default App
