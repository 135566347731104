import { ALMSectionKey } from '../models/types'
import { SBRMType } from '../modules/sbrm/SBRMModel'

enum LocalizationKey {
  /**
   * AUTH
   */
  AuthForgotPasswordButton = 'Auth-Forgot-Password-Button',
  AuthForgotPasswordSuccess = 'Auth-Forgot-Password-Success',
  AuthForgotPasswordFailure = 'Auth-Forgot-Password-Failure',
  AuthForgotPasswordBackToLogin = 'Auth-Forgot-Password-Back-To-Login',

  AuthInvitationErrorWhileAccepting = 'Auth-Invitation-ErrorWhileAccepting',
  AuthInvitationErrorWhileLoading = 'Auth-Invitation-ErrorWhileLoading',
  AuthInvitationTitle = 'Auth-Invitation-Title',
  AuthInvitationNeedsYou = 'Auth-Invitation-NeedsYou',
  AuthInvitationAccept = 'Auth-Invitation-Accept',
  AuthInvitationRegister = 'Auth-Invitation-Register',
  AuthInvitationAlreadyHaveAnAccount = 'Auth-Invitation-AlreadyHaveAnAccount',

  AuthLoginError = 'Auth-Login-Error',
  AuthLoginSSOError = 'Auth-Login-SSO-Error',
  AuthLoginSSO = 'Auth-Login-SSO',
  AuthLogin = 'Auth-Login',
  AuthLoginForgotPassword = 'Auth-Login-Forgot-Password',
  AuthLoginContinueWithSSO = 'Auth-Login-Continue-With-SSO',
  AuthLoginLoginWithMicrosoft = 'Auth-Login-Login-With-Microsoft',
  AuthLoginEmailConnexion = 'Auth-Login-Email-Connexion',
  AuthLoginRedirect = 'Auth-Login-Redirect',

  AuthRegisterError = 'Auth-Register-Error',
  AuthRegisterErrorEmailAlreadyExists = 'Auth-Register-Error-Email-Already-Exists',
  AuthRegisterTitle = 'Auth-Register-Title',
  AuthRegisterInvitedYouToJoinTheTeam = 'Auth-Register-Invited-You',
  AuthRegister = 'Auth-Register',
  AuthRegisterAlreadyHaveAnAccount = 'Auth-Register-Already-Have-An-Account',
  AuthRegisterSuccess = 'Auth-Register-Success',
  AuthRegisterGoToLogin = 'Auth-Register-Go-To-Login',

  /**
   * PAGE
   */
  PageArtistsTitle = 'Page-Artists-Title',
  PageArtistsName = 'Page-Artists-Name',
  PageArtistsBookingsCount = 'Page-Artists-Bookings-Count',
  PageArtistLastFee = 'Page-Artist-Last-Fee',
  PageArtistAverageFee = 'Page-Artist-Average-Fee',
  PageArtistMinimumFee = 'Page-Artist-Minimum-Fee',
  PageArtistMaximumFee = 'Page-Artist-Maximum-Fee',
  PageArtistBookings = 'Page-Artist-Bookings',
  PageArtistListen = 'Page-Artist-Listen',
  PageArtistCrew = 'Page-Artist-Crew',
  PageArtistFiles = 'Page-Artist-Files',

  PagePreferenceTitle = 'Page-Preference-Title',
  PagePreferenceLocale = 'Page-Preference-Locale',

  PageProfileTitle = 'Page-Profile-Title',
  PageProfileUpdateSuccess = 'Page-Profile-Update-Success',
  PageProfileUpdateFailure = 'Page-Profile-Update-Failure',

  PageAccountActivityTitle = 'Page-Account-Activity-Title',
  PageAccountActivityToolbar = 'Page-Account-Activity-Toolbar',

  PageSettingsOrganizationTitle = 'Page-Settings-Organization-Title',
  PageSettingsOrganizationBillingInfos = 'Page-Settings-Organization-BillingInfos',
  PageSettingsOrganizationPlan = 'Page-Settings-Organization-Plan',
  PageSettingsOrganizationRoles = 'Page-Settings-Organization-Roles',

  PageFinancialInvoiceTitle = 'Page-Financial-Invoice-Title',
  PageFinancialReportsTitle = 'Page-Financial-Reports-Title',

  PageCRMContactsTitle = 'Page-CRM-Contacts-Title',
  PageCRMContactsName = 'Page-CRM-Contacts-Name',
  PageCRMContactsSearch = 'Page-CRM-Contacts-Search',
  PageCRMContactsMerge = 'Page-CRM-Contacts-Merge',
  PageCRMContactLoading = 'Page-CRM-Contact-Loading',
  PageCRMContactBack = 'Page-CRM-Contact-Back',
  PageCRMContactWorkFor = 'Page-CRM-Contact-Work-For',
  PageCRMContactMergerSuccess = 'Page-CRM-ContactMerger-Success',
  PageCRMContactMergerFailure = 'Page-CRM-ContactMerger-Failure',
  PageCRMContactMergerTitle = 'Page-CRM-ContactMerger-Title',
  PageCRMContactMergerDescription = 'Page-CRM-ContactMerger-Description',
  PageCRMContactMergerSelectContact = 'Page-CRM-ContactMerger-SelectContact',
  PageCRMContactMergerContactToKeep = 'Page-CRM-ContactMerger-ContactToKeep',
  PageCRMContactMergerContactToDelete = 'Page-CRM-ContactMerger-ContactToDelete',
  PageCRMContactMergerMergeButton = 'Page-CRM-ContactMerger-MergeButton',

  PageCRMSuppliersTitle = 'Page-CRM-Suppliers-Title',
  PageCRMSuppliersName = 'Page-CRM-Suppliers-Name',
  PageCRMSuppliersType = 'Page-CRM-Suppliers-Type',
  PageCRMSuppliersSupplierBalance = 'Page-CRM-Suppliers-SupplierBalance',
  PageCRMSuppliersContacts = 'Page-CRM-Suppliers-Contacts',
  PageCRMSuppliersSearch = 'Page-CRM-Suppliers-Search',
  PageCRMSupplierLoading = 'Page-CRM-Supplier-Loading',
  PageCRMSupplierBack = 'Page-CRM-Supplier-Back',
  PageCRMSupplierUpdatePicture = 'Page-CRM-Supplier-UpdatePicture',
  PageCRMSupplierTeam = 'Page-CRM-Supplier-Team',
  PageCRMSupplierInvoices = 'Page-CRM-Supplier-Invoices',

  PageCRMUserLoading = 'Page-CRM-User-Loading',
  PageCRMUserBack = 'Page-CRM-User-Back',
  PageCRMUserAssociatedContact = 'Page-CRM-User-AssociatedContact',
  PageCRMUserEvents = 'Page-CRM-User-Events',
  PageCRMUserActivities = 'Page-CRM-User-Activities',

  PageCRMTeamName = 'Page-CRM-Team-Name',
  PageCRMTeamRole = 'Page-CRM-Team-Role',
  PageCRMTeamTitle = 'Page-CRM-Team-Title',
  PageCRMTeamInvitations = 'Page-CRM-Team-Invitations',

  PageCRMInvitationsTitle = 'Page-CRM-Invitations-Title',
  PageCRMInvitationsBack = 'Page-CRM-Invitations-Back',
  PageCRMInvitationsInvitedAt = 'Page-CRM-Invitations-InvitedAt',

  PageEventDetailsContacts = 'Page-EventDetails-Contacts',
  PageEventDetailsFiles = 'Page-EventDetails-Files',
  PageEventDetailsTimetable = 'Page-EventDetails-Timetable',
  PageEventDetailsLineUpArtist = 'Page-EventDetails-LineUp-Artist',
  PageEventDetailsLineUpPrice = 'Page-EventDetails-LineUp-Price',
  PageEventDetailsLineUpType = 'Page-EventDetails-LineUp-Type',
  PageEventDetailsLineUpMemoDeal = 'Page-EventDetails-LineUp-MemoDeal',
  PageEventDetailsLineUpOwner = 'Page-EventDetails-LineUp-Owner',
  PageEventDetailsLineUp = 'Page-EventDetails-LineUp',
  PageEventDetailsContractsTitle = 'Page-EventsDetails-Contracts-Title',
  PageEventDetailsContractsBooking = 'Page-EventsDetails-Contracts-Booking',
  PageEventDetailsContractsStatus = 'Page-EventsDetails-Contracts-Status',
  PageEventDetailsContractsNotes = 'Page-EventsDetails-Contracts-Notes',
  PageEventDetailsInvoicesTitle = 'Page-EventsDetails-Invoices-Title',
  PageEventDetailsInvoicesAmountPaid = 'Page-EventsDetails-Invoices-AmountPaid',
  PageEventDetailsInvoicesAmountToPay = 'Page-EventsDetails-Invoices-AmountToPay',
  PageEventDetailsInvoicesInvoicesPaid = 'Page-EventsDetails-Invoices-InvoicesPaid',
  PageEventDetailsInvoicesInvoicesToPay = 'Page-EventsDetails-Invoices-InvoicesToPay',
  PageEventDetailsReportingTitle = 'Page-EventsDetails-Reporting-Title',
  PageEventDetailsGroundsBookings = 'Page-EventsDetails-Grounds-Bookings',
  PageEventDetailsGroundsInfos = 'Page-EventsDetails-Grounds-Infos',
  PageEventDetailsGroundsDriver = 'Page-EventsDetails-Grounds-Driver',
  PageEventDetailsGroundsPassengers = 'Page-EventsDetails-Grounds-Passengers',
  PageEventDetailsGroundsNotes = 'Page-EventsDetails-Grounds-Notes',
  PageEventDetailsGroundsGround = 'Page-EventsDetails-Grounds-Ground',
  PageEventDetailsGroundsGroundNotes = 'Page-EventsDetails-Grounds-GroundNotes',
  PageEventDetailsHotelsBooking = 'Page-EventsDetails-Hotels-Booking',
  PageEventDetailsHotelsHotel = 'Page-EventsDetails-Hotels-Hotel',
  PageEventDetailsHotelsPrice = 'Page-EventsDetails-Hotels-Price',
  PageEventDetailsHotelsDates = 'Page-EventsDetails-Hotels-Dates',
  PageEventDetailsHotelsNights = 'Page-EventsDetails-Hotels-Nights',
  PageEventDetailsHotelsTravelers = 'Page-EventsDetails-Hotels-Travelers',
  PageEventDetailsHotelsNotes = 'Page-EventsDetails-Hotels-Notes',
  PageEventDetailsHotelsTitle = 'Page-EventsDetails-Hotels-Title',
  PageEventDetailsPartyCrew = 'Page-EventsDetails-PartyCrew',
  PageEventDetailsTechnicalBooking = 'Page-EventsDetails-Technical-Booking',
  PageEventDetailsTechnicalStatus = 'Page-EventsDetails-Technical-Status',
  PageEventDetailsTechnicalNotes = 'Page-EventsDetails-Technical-Notes',
  PageEventDetailsTechnicalTitle = 'Page-EventsDetails-Technical-Title',
  PageEventDetailsTravelsBooking = 'Page-EventsDetails-Travels-Booking',
  PageEventDetailsTravelsType = 'Page-EventsDetails-Travels-Type',
  PageEventDetailsTravelsTravel = 'Page-EventsDetails-Travels-Travel',
  PageEventDetailsTravelsTravelers = 'Page-EventsDetails-Travels-Travelers',
  PageEventDetailsTravelsTitle = 'Page-EventsDetails-Travels-Title',

  PageReleaseNotesTitle = 'Page-ReleaseNotes-Title',

  PageCalendarToday = 'Page-Calendar-Today',
  PageCalendarAll = 'Page-Calendar-All',
  PageCalendarMore = 'Page-Calendar-More',

  PageSettingsVenuesName = 'Page-Settings-Venues-Name',
  PageSettingsVenuesType = 'Page-Settings-Venues-Type',
  PageSettingsVenuesStages = 'Page-Settings-Venues-Stages',
  PageSettingsVenuesOpeningRange = 'Page-Settings-Venues-OpeningRange',
  PageSettingsVenuesTitle = 'Page-Settings-Venues-Title',
  PageSettingsVenueStaff = 'Page-Settings-Venue-Staff',
  PageSettingsVenueStages = 'Page-Settings-Venue-Stages',
  PageSettingsVenueFiles = 'Page-Settings-Venue-Files',
  PageSettingsVenuesSeeArchived = 'Page-Settings-Venues-SeeArchived',
  PageSettingsArchivedVenuesTitle = 'Page-Settings-ArchivedVenues-Title',
  PageSettingsArchivedVenuesRestore = 'Page-Settings-ArchivedVenues-Restore',

  PageSettingsContactTypesTitle = 'Page-Settings-ContactTypes-Title',
  PageSettingsContractStatusTitle = 'Page-Settings-ContractStatus-Title',
  PageSettingsEventStatusDefaultStatus = 'Page-Settings-EventStatus-DefaultStatus',
  PageSettingsEventStatusVisibleBy = 'Page-Settings-EventStatus-VisibleBy',
  PageSettingsEventStatusTitle = 'Page-Settings-EventStatus-Title',
  PageSettingsHotelsAddress = 'Page-Settings-Hotels-Address',
  PageSettingsHotelsStars = 'Page-Settings-Hotels-Stars',
  PageSettingsHotelsTitle = 'Page-Settings-Hotels-Title',
  PageSettingsRiderStatusTitle = 'Page-Settings-RiderStatus-Title',
  PageSettingsStationsTitle = 'Page-Settings-Stations-Title',
  PageSettingsVenueTypesTitle = 'Page-Settings-VenueTypes-Title',

  PageSettingsReportingItemsTitle = 'Page-Settings-Reporting-Items-Title',
  PageSettingsReportingItemsReorder = 'Page-Settings-Reporting-Items-Reorder',
  PageSettingsReportingReorderItemsPageTitle = 'Page-Settings-Reporting-ReorderItemsPage-Title',
  PageSettingsReportingReorderItemsPageCosts = 'Page-Settings-Reporting-ReorderItemsPage-Costs',
  PageSettingsReportingReorderItemsPageRevenues = 'Page-Settings-Reporting-ReorderItemsPage-Revenues',

  PageSettingsOrganizationPreferencesTitle = 'Page-Settings-OrganizationPreferences-Title',
  PageSettingsOrganizationPreferencesUnitsOfMeasurementDescription = 'Page-Settings-OrganizationPreferences-UnitsOfMeasurementDescription',
  PageSettingsOrganizationPreferencesLocaleDescription = 'Page-Settings-OrganizationPreferences-LocaleDescription',
  PageSettingsOrganizationPreferencesMetricLabel = 'Page-Settings-OrganizationPreferences-MetricLabel',
  PageSettingsOrganizationPreferencesImperialLabel = 'Page-Settings-OrganizationPreferences-ImperialLabel',

  PageEventSelectorTitle = 'Page-EventSelector-Title',

  PageExternalAdvancingYouCanEditUntill = 'Page-ExternalAdvancing-YouCanEditUntill',
  PageExternalAdvancingOpenChat = 'Page-ExternalAdvancing-OpenChat',
  PageExternalAdvancingYourContact = 'Page-ExternalAdvancing-YourContact',
  PageExternalAdvancingYourArtist = 'Page-ExternalAdvancing-YourArtist',
  PageExternalAdvancingDeadlineReadOnly = 'Page-ExternalAdvancing-DeadlineReadOnly',
  PageExternalAdvancingDeadlineDescription = 'Page-ExternalAdvancing-DeadlineDescription',

  /**
   * LAYOUT
   */
  LayoutEventDetailsSiderLineup = 'Layout-EventDetailsSider-Lineup',
  LayoutEventDetailsSiderTimetable = 'Layout-EventDetailsSider-Timetable',
  LayoutEventDetailsSiderAdmin = 'Layout-EventDetailsSider-Admin',
  LayoutEventDetailsSiderContracts = 'Layout-EventDetailsSider-Contracts',
  LayoutEventDetailsSiderInvoices = 'Layout-EventDetailsSider-Invoices',
  LayoutEventDetailsSiderReporting = 'Layout-EventDetailsSider-Reporting',
  LayoutEventDetailsSiderProduction = 'Layout-EventDetailsSider-Production',
  LayoutEventDetailsSiderTravels = 'Layout-EventDetailsSider-Travels',
  LayoutEventDetailsSiderHotels = 'Layout-EventDetailsSider-Hotels',
  LayoutEventDetailsSiderGround = 'Layout-EventDetailsSider-Ground',
  LayoutEventDetailsSiderTechnical = 'Layout-EventDetailsSider-Technical',
  LayoutEventDetailsSiderPartyCrew = 'Layout-EventDetailsSider-PartyCrew',
  LayoutEventDetailsSiderFiles = 'Layout-EventDetailsSider-Files',
  LayoutEventDetailsSiderContacts = 'Layout-EventDetailsSider-Contacts',
  LayoutEventDetailsSiderSettings = 'Layout-EventDetailsSider-Settings',
  LayoutEventDetailsSiderTasks = 'Layout-EventDetailsSider-Tasks',

  LayoutMainHeaderEvents = 'Layout-MainHeader-Events',
  LayoutMainHeaderArtists = 'Layout-MainHeader-Artists',
  LayoutMainHeaderFinancials = 'Layout-MainHeader-Financials',
  LayoutMainHeaderCRM = 'Layout-MainHeader-CRM',

  LayoutProfileSiderInformations = 'Layout-ProfileSider-Informations',
  LayoutProfileSiderActivity = 'Layout-ProfileSider-Activity',
  LayoutProfileSiderPreferences = 'Layout-ProfileSider-Preferences',
  LayoutProfileSiderLogout = 'Layout-ProfileSider-Logout',
  LayoutProfileSiderLogoutConfirmation = 'Layout-ProfileSider-Logout-Confirmation',

  LayoutRightHeaderProfile = 'Layout-RightHeader-Profile',
  LayoutRightHeaderSettings = 'Layout-RightHeader-Settings',
  LayoutRightHeaderLang = 'Layout-RightHeader-Lang',

  LayoutSettingsSiderUploadOrganizationImage = 'Layout-SettingsSider-UploadOrganizationImage',
  LayoutSettingsSiderVenues = 'Layout-SettingsSider-Venues',
  LayoutSettingsSiderHotels = 'Layout-SettingsSider-Hotels',
  LayoutSettingsSiderStations = 'Layout-SettingsSider-Stations',
  LayoutSettingsSiderReportingItems = 'Layout-SettingsSider-ReportingItems',
  LayoutSettingsSiderVenueTypes = 'Layout-SettingsSider-VenueTypes',
  LayoutSettingsSiderContactTypes = 'Layout-SettingsSider-ContactTypes',
  LayoutSettingsSiderEventStatuses = 'Layout-SettingsSider-EventStatuses',
  LayoutSettingsSiderContractStatuses = 'Layout-SettingsSider-ContractStatuses',
  LayoutSettingsSiderRiderStatuses = 'Layout-SettingsSider-RiderStatuses',
  LayoutSettingsSiderOrganization = 'Layout-SettingsSider-Organization',
  LayoutSettingsSiderPreferences = 'Layout-SettingsSider-Preferences',

  LayoutAuthContactUs = 'Layout-Auth-ContactUs',
  LayoutAuthDiscover = 'Layout-Auth-Discover',
  LayoutAuthLegal = 'Layout-Auth-Legal',

  LayoutFinancialInvoices = 'Layout-Financial-Invoices',
  LayoutFinancialReports = 'Layout-Financial-Reports',

  LayoutCRMContacts = 'Layout-CRM-Contacts',
  LayoutCRMSuppliers = 'Layout-CRM-Suppliers',
  LayoutCRMOrganization = 'Layout-CRM-Organization',

  LayoutFooterReleaseNotes = 'Layout-Footer-ReleaseNotes',

  /**
   * File Sharing
   */
  FileSharingExpires = 'FileSharing-Expires',
  FileSharingDownload = 'FileSharing-Download',

  /**
   * FILE VIEWER
   */
  FileViewerDownload = 'FileViewer-Download',
  FileViewerPDFViewerError = 'FileViewer-PDFViewer-ViewerError',

  /**
   * SEARCH DRAWER
   */
  SearchDrawerSearch = 'SearchDrawer-Search',
  SearchDrawerPlaceholder = 'SearchDrawer-Placeholder',
  SearchDrawerEvents = 'SearchDrawer-Events',
  SearchDrawerVenues = 'SearchDrawer-Venues',
  SearchDrawerArtists = 'SearchDrawer-Artists',
  SearchDrawerSuppliers = 'SearchDrawer-Suppliers',

  /**
   * SBRM
   */
  SBRMEntityNotDefined = 'SBRM-EntityNotDefined',
  SBRMClose = 'SBRM-Close',
  SBRMDelete = 'SBRM-Delete',
  SBRMSave = 'SBRM-Save',
  SBRMUpdate = 'SBRM-Update',
  SBRMContactAssociatedWithUserNotEditable = 'SBRM-ContactAssociatedWithUserNotEditable',
  SBRMAddNew = 'SBRM-AddNew',
  SBRMDuplicate = 'SBRM-Duplicate',
  SBRMView = 'SBRM-View',
  SBRMComment = 'SBRM-Comment',
  SBRMTitleAddNew = 'SBRM-Title-AddNew',
  SBRMTitleCreateNew = 'SBRM-Title-CreateNew',
  SBRMTitleEdit = 'SBRM-Title-Edit',
  SBRMTitleDelete = 'SBRM-Title-Delete',
  SBRMTitleComment = 'SBRM-Title-Comment',
  SBRMTitleView = 'SBRM-Title-View',
  SBRMTitleUpload = 'SBRM-Title-Upload',
  SBRMTitleDuplicate = 'SBRM-Title-Duplicate',
  SBRMTitleAssign = 'SBRM-Title-Assign',
  SBRMTitleInvite = 'SBRM-Title-Invite',
  SBRMTitleCancel = 'SBRM-Title-Cancel',

  /**
   * SBAsyncSelect
   */
  SBAsyncSelectNotFound = 'SBAsyncSelect-NotFound',

  /**
   * COMPONENTS
   */
  ComponentsBookingContactsTableContactsFound = 'Components-Booking-ContactsTable-ContactsFound',
  ComponentsBookingMissingContactsInPartyCrewTitle = 'Components-Booking-MissingContactsInPartyCrew-Title',
  ComponentsBookingTableEvent = 'Components-Booking-Table-Event',
  ComponentsBookingTableVenue = 'Components-Booking-Table-Venue',
  ComponentsBookingTablePrice = 'Components-Booking-Table-Price',
  ComponentsBookingTableMemoDeal = 'Components-Booking-Table-MemoDeal',
  ComponentsCommentEditorMentionPlaceholder = 'Components-Comment-Editor-Mention-Placeholder',
  ComponentsCommentEditorGIFPlaceholder = 'Components-Comment-Editor-GIF-Placeholder',
  ComponentsCommentEditorSend = 'Components-Comment-Editor-Send',
  ComponentsCommentListSendFirstMessage = 'Components-Comment-List-SendFirstMessage',
  ComponentsCommentListUseTag = 'Components-Comment-List-UseTag',
  ComponentsCommentSubscribeToConversationSubscribe = 'Components-Comment-SubscribeToConversation-Subscribe',
  ComponentsCommentSubscribeToConversationUnsubscribe = 'Components-Comment-SubscribeToConversation-Unsubscribe',
  ComponentsContactLinkedToUser = 'Components-Contact-LinkedToUser-Title',
  ComponentsContactTableWithAssociationContact = 'Components-Contact-TableWithAssociation-Contact',
  ComponentsContactTableWithAssociationType = 'Components-Contact-TableWithAssociation-Type',
  ComponentsContactTypeListCheckboxesAll = 'Components-ContactType-ListCheckboxes-All',
  ComponentsContactTypeListCheckboxesCreateType = 'Components-ContactType-ListCheckboxes-CreateType',
  ComponentsCustomInputsSelectFooterArtistSpotify = 'Components-CustomInputs-Select-Footer-Artist-Spotify',
  ComponentsCustomInputsSelectFooterArtistManual = 'Components-CustomInputs-Select-Footer-Artist-Manual',
  ComponentsCustomInputsSelectFooterArtistSpotifyUrl = 'Components-CustomInputs-Select-Footer-Artist-SpotifyUrl',
  ComponentsCustomInputsSelectFooterArtistArtistName = 'Components-CustomInputs-Select-Footer-Artist-ArtistName',
  ComponentsCustomInputsDropzone = 'Components-CustomInputs-Dropzone',
  ComponentsEventAutoImportVenueContacts = 'Components-Event-AutoImportVenueContacts',
  ComponentsEventCover = 'Components-Event-Cover',
  ComponentsFileCell = 'Components-File-Cell',
  ComponentsFileShareShare = 'Components-File-Share-Share',
  ComponentsFileSharePublicLinkGenerated = 'Components-File-Share-PublicLinkGenerated',
  ComponentsFileSharePublicLinkError = 'Components-File-Share-PublicLinkError',
  ComponentsFileSharePublicLinkCopiedToClipboard = 'Components-File-Share-PublicLinkCopiedToClipboard',
  ComponentsFileShareCopyPublicLink = 'Components-File-Share-CopyPublicLink',
  ComponentsFileUpload = 'Components-File-Upload',
  ComponentsInvitationResendEmail = 'Components-Invitation-ResendEmail',
  ComponentsPerformanceTableArtist = 'Components-Performance-Table-Artist',
  ComponentsPerformanceTableSetTime = 'Components-Performance-Table-SetTime',
  ComponentsPerformanceTableStage = 'Components-Performance-Table-Stage',
  ComponentsSBTableEmpty = 'Components-SBTable-Empty',
  ComponentsSBActivityLogCreated = 'Components-SBActivityLog-Created',
  ComponentsSBActivityLogUpdated = 'Components-SBActivityLog-Updated',
  ComponentsSBActivityLogDeleted = 'Components-SBActivityLog-Deleted',
  ComponentsNewVersionCheckerTitle = 'Components-NewVersionChecker-Title',
  ComponentsNewVersionCheckerDescription = 'Components-NewVersionChecker-Description',
  ComponentsNewVersionCheckerRefresh = 'Components-NewVersionChecker-Refresh',
  ComponentsMaintenanceModeTitle = 'Components-MaintenanceMode-Title',
  ComponentsMaintenanceModeDescription = 'Components-MaintenanceMode-Description',
  ComponentsMaintenanceModeReload = 'Components-MaintenanceMode-Reload',
  ComponentsGroundCascaderSelectBooking = 'Components-Ground-Cascader-SelectBooking',
  ComponentsGroundCascaderItemFrom = 'Components-Ground-Cascader-From',
  ComponentsGroundCascaderItemTo = 'Components-Ground-Cascader-To',
  ComponentsGroundCascaderItemHotels = 'Components-Ground-CascaderItem-Hotels',
  ComponentsGroundCascaderItemVenues = 'Components-Ground-CascaderItem-Venues',
  ComponentsGroundCascaderItemInbound = 'Components-Ground-CascaderItem-Inbound',
  ComponentsGroundCascaderItemOutbound = 'Components-Ground-CascaderItem-Outbound',
  ComponentsGroundCascaderItemCustomAddress = 'Components-Ground-CascaderItem-CustomAddress',
  ComponentsGroundCascaderItemNewCustomAddress = 'Components-Ground-CascaderItem-NewCustomAddress',
  ComponentsInvoiceFiltersLast7Days = 'Components-Invoice-Filters-Last7Days',
  ComponentsInvoiceFiltersLast14Days = 'Components-Invoice-Filters-Last14Days',
  ComponentsInvoiceFiltersLast30Days = 'Components-Invoice-Filters-Last30Days',
  ComponentsInvoiceFiltersLast90Days = 'Components-Invoice-Filters-Last90Days',
  ComponentsInvoiceFiltersLast1Year = 'Components-Invoice-Filters-Last1Year',
  ComponentsInvoiceFiltersPaid = 'Components-Invoice-Filters-Paid',
  ComponentsInvoiceFiltersPartiallyPaid = 'Components-Invoice-Filters-PartiallyPaid',
  ComponentsInvoiceFiltersUnpaid = 'Components-Invoice-Filters-Unpaid',
  ComponentsInvoiceFiltersAll = 'Components-Invoice-Filters-All',
  ComponentsInvoiceFiltersVenue = 'Components-Invoice-Filters-Venue',
  ComponentsInvoiceFiltersDueDate = 'Components-Invoice-Filters-DueDate',
  ComponentsInvoiceFiltersEventDate = 'Components-Invoice-Filters-EventDate',
  ComponentsInvoiceFiltersSearch = 'Components-Invoice-Filters-Search',
  ComponentsInvoiceFiltersSearchPlaceholder = 'Components-Invoice-Filters-SearchPlaceholder',
  ComponentsInvoiceTableReference = 'Components-Invoice-Table-Reference',
  ComponentsInvoiceTableSupplier = 'Components-Invoice-Table-Supplier',
  ComponentsInvoiceTableAmount = 'Components-Invoice-Table-Amount',
  ComponentsInvoiceTableDueDate = 'Components-Invoice-Table-DueDate',
  ComponentsInvoiceTableEvent = 'Components-Invoice-Table-Event',
  ComponentsInvoiceTableLinkedTo = 'Components-Invoice-Table-LinkedTo',
  ComponentsInvoiceTableAddPayment = 'Components-Invoice-Table-AddPayment',
  ComponentsNotificationDropdownTitle = 'Components-Notification-Dropdown-Title',
  ComponentsNotificationDropdownDescription1 = 'Components-Notification-Dropdown-Description1',
  ComponentsNotificationDropdownDescription2 = 'Components-Notification-Dropdown-Description2',
  ComponentsNotificationDropdownAccept = 'Components-Notification-Dropdown-Accept',
  ComponentsNotificationDropdownError = 'Components-Notification-Dropdown-Error',
  ComponentsNotificationDropdownEndMesssage = 'Components-Notification-Dropdown-EndMesssage',
  ComponentsNotificationDropdownRead = 'Components-Notification-Dropdown-Read',
  ComponentsNotificationMarkAllAsRead = 'Components-Notification-MarkAllAsRead',
  ComponentsOrganizationBillingInfosManage = 'Components-Organization-BillingInfos-Manage',
  ComponentsOrganizationPlanYourOrganization = 'Components-Organization-Plan-YourOrganization',
  ComponentsOrganizationPlanUsers = 'Components-Organization-Plan-Users',
  ComponentsOrganizationPlanPricePerUser = 'Components-Organization-Plan-PricePerUser',
  ComponentsOrganizationPlanTotalPrice = 'Components-Organization-Plan-TotalPrice',
  ComponentsOrganizationPlanToEditGoToPortal = 'Components-Organization-Plan-ToEditGoToPortal',
  ComponentsOrganizationPlanMoveTheCursor = 'Components-Organization-Plan-MoveTheCursor',
  ComponentsOrganizationPlanTrialsEndsOn = 'Components-Organization-Plan-TrialsEndsOn',
  ComponentsOrganizationPlanTrialsEndsOnDescription = 'Components-Organization-Plan-TrialsEndsOnDescription',
  ComponentsOrganizationPlanNoDefaultPaymentMethod = 'Components-Organization-Plan-NoDefaultPaymentMethod',
  ComponentsOrganizationPlanNoDefaultPaymentMethodDescription = 'Components-Organization-Plan-NoDefaultPaymentMethodDescription',
  ComponentsOrganizationPlanReset = 'Components-Organization-Plan-Reset',
  ComponentsOrganizationRolesPermissions = 'Components-Organization-Roles-Permissions',
  ComponentsOrganizationRolesMarketing = 'Components-Organization-Roles-Marketing',
  ComponentsOrganizationRolesVisitor = 'Components-Organization-Roles-Visitor',
  ComponentsOrganizationRolesProductionManager = 'Components-Organization-Roles-ProductionManager',
  ComponentsOrganizationRolesfinancialManager = 'Components-Organization-Roles-financialManager',
  ComponentsOrganizationRolesGeneralManager = 'Components-Organization-Roles-GeneralManager',
  ComponentsOrganizationRolesAdmin = 'Components-Organization-Roles-Admin',
  ComponentsPaymentListAddPayment = 'Components-Payment-List-AddPayment',
  ComponentsPaymentListAddFirstPayment = 'Components-Payment-List-AddFirstPayment',
  ComponentsTravelSearcherNoTravelFound = 'Components-Travel-Searcher-NoTravelFound',
  ComponentsTravelSearcherError = 'Components-Travel-Searcher-Error',
  ComponentsTravelSearcherFillBeforeSearching = 'Components-Travel-Searcher-FillBeforeSearching',
  ComponentsTravelSearcherCreateManually = 'Components-Travel-Searcher-CreateManually',
  ComponentsTravelSearcherSearch = 'Components-Travel-Searcher-Search',
  ComponentsTravelSearcherSelect = 'Components-Travel-Searcher-Select',
  ComponentsTravelSearcherChange = 'Components-Travel-Searcher-Change',
  ComponentsTravelSearcherValidate = 'Components-Travel-Searcher-Validate',
  ComponentsUserAssociateContactDescription = 'Components-User-AssociateContact-Description',
  ComponentsUserAssociateContactPofileLabel = 'Components-User-AssociateContact-PofileLabel',
  ComponentsUserAssociateContactDescription2 = 'Components-User-AssociateContact-Description2',
  ComponentsUserAssociateContactSearchPlaceholder = 'Components-User-AssociateContact-SearchPlaceholder',
  ComponentsUserAssociateContactAssociate = 'Components-User-AssociateContact-Associate',
  ComponentsUserAvatarDeletedUser = 'Components-User-Avatar-DeletedUser',
  ComponentsReportingWizardStep1NoItemsDescription = 'Components-Reporting-Wizard-Step1-NoItemsDescription',
  ComponentsReportingWizardStep1NoItemsTitle = 'Components-Reporting-Wizard-Step1-NoItemsTitle',
  ComponentsReportingWizardStep1NoItemsGoToSettings = 'Components-Reporting-Wizard-Step1-NoItemsGoToSettings',
  ComponentsReportingWizardStep1Costs = 'Components-Reporting-Wizard-Step1-Costs',
  ComponentsReportingWizardStep1Revenues = 'Components-Reporting-Wizard-Step1-Revenues',
  ComponentsReportingWizardError = 'Components-Reporting-Wizard-Error',
  ComponentsReportingWizardAtLeast1Filled = 'Components-Reporting-Wizard-AtLeast1Filled',
  ComponentsReportingWizardPreview = 'Components-Reporting-Wizard-Preview',
  ComponentsReportingWizardMisc = 'Components-Reporting-Wizard-Misc',
  ComponentsReportingWizardCostsAndRevenues = 'Components-Reporting-Wizard-CostsAndRevenues',
  ComponentsReportingEventAlertDescription = 'Components-Reporting-EventAlert-Description',
  ComponentsReportingEventAlertTitle = 'Components-Reporting-EventAlert-Title',
  ComponentsReportingEventAlertButton = 'Components-Reporting-EventAlert-Button',
  ComponentsReportingOverviewMargin = 'Components-Reporting-Overview-Margin',
  ComponentsReportingOverviewAttendees = 'Components-Reporting-Overview-Attendees',
  ComponentsReportingOverviewBalance = 'Components-Reporting-Overview-Balance',
  ComponentsReportingOverviewCosts = 'Components-Reporting-Overview-Costs',
  ComponentsReportingOverviewRevenues = 'Components-Reporting-Overview-Revenues',
  ComponentsReportingChartEvolution = 'Components-Reporting-Chart-Evolution',
  ComponentsWeatherCardTitle = 'Components-Weather-Card-Title',
  ComponentsWeatherCardTemp = 'Components-Weather-Card-Temp',
  ComponentsWeatherCardTemperature = 'Components-Weather-Card-Temperature',
  ComponentsWeatherCardHumm = 'Components-Weather-Card-Humm',
  ComponentsWeatherCardWind = 'Components-Weather-Card-Wind',
  ComponentsWeatherCardRain = 'Components-Weather-Card-Rain',
  ComponentsCompleteYourProfile = 'Components-CompleteYourProfile',

  /**
   * MISC
   */
  MiscButtonUpdate = 'Misc-Button-Update',

  MiscTableActions = 'Misc-Table-Actions',
  MiscTableName = 'Misc-Table-Name',
  MiscTableColor = 'Misc-Table-Color',
  MiscTableIcon = 'Misc-Table-Icon',
  MiscTableSearchSearch = 'Misc-Table-Search-Search',
  MiscTableSearchReset = 'Misc-Table-Search-Reset',
  MiscTableSearchClose = 'Misc-Table-Search-Close',

  MiscFormRole = 'Misc-Form-Role',
  MiscFormTitle = 'Misc-Form-Title',
  MiscFormDescription = 'Misc-Form-Description',
  MiscFormName = 'Misc-Form-Name',
  MiscFormFirstName = 'Misc-Form-FirstName',
  MiscFormLastName = 'Misc-Form-LastName',
  MiscFormEmail = 'Misc-Form-Email',
  MiscFormPhone = 'Misc-Form-Phone',
  MiscFormPassword = 'Misc-Form-Password',
  MiscFormPasswordConfirmation = 'Misc-Form-Password-Confirmation',
  MiscFormRememberMe = 'Misc-Form-RememberMe',
  MiscFormAdd = 'Misc-Form-Add',
  MiscFormImport = 'Misc-Form-Import',
  MiscFormUpdate = 'Misc-Form-Update',
  MiscFormCancelModifications = 'Misc-Form-CancelModifications',
  MiscFormAddress = 'Misc-Form-Address',
  MiscFormAddressSearch = 'Misc-Form-Address-Search',
  MiscFormAddressCloseManual = 'Misc-Form-Address-CloseManual',
  MiscFormAddressOpenManual = 'Misc-Form-Address-OpenManuel',
  MiscFormAddressLabel = 'Misc-Form-Address-Label',
  MiscFormAddressStreetNumber = 'Misc-Form-Address-StreetNumber',
  MiscFormAddressStreet = 'Misc-Form-Address-Street',
  MiscFormAddressCity = 'Misc-Form-Address-City',
  MiscFormAddressPostCode = 'Misc-Form-Address-PostCode',
  MiscFormAddressState = 'Misc-Form-Address-State',
  MiscFormAddressCountry = 'Misc-Form-Address-Country',
  MiscFormAddressLatitude = 'Misc-Form-Address-Latitude',
  MiscFormAddressLongitude = 'Misc-Form-Address-Longitude',
  MiscFormCompanyName = 'Misc-Form-CompanyName',
  MiscFormTaxNumber = 'Misc-Form-TaxNumber',
  MiscFormHowToCreate = 'Misc-Form-HowToCreate',
  MiscFormAreYouSureToDelete = 'Misc-Form-AreYouSureToDelete',
  MiscFormBooking = 'Misc-Form-Booking',
  MiscFormEvent = 'Misc-Form-Event',
  MiscFormArtist = 'Misc-Form-Artist',
  MiscFormType = 'Misc-Form-Type',
  MiscFormPrice = 'Misc-Form-Price',
  MiscFormMemoDeal = 'Misc-Form-MemoDeal',
  MiscFormActionCannotBeUndone = 'Misc-Form-ActionCannotBeUndone',
  MiscFormBookingResourcesDeleteDescription = 'Misc-Form-BookingResourcesDeleteDescription',
  MiscFormInputValueToConfirm = 'Misc-Form-InputValueToConfirm',
  MiscFormOwner = 'Misc-Form-Owner',
  MiscFormComment = 'Misc-Form-Comment',
  MiscFormCompany = 'Misc-Form-Company',
  MiscFormContact = 'Misc-Form-Contact',
  MiscFormContactAssociationEventTitle = 'Misc-Form-ContactAssociation-EventTitle',
  MiscFormContactAssociationEventDescription = 'Misc-Form-ContactAssociation-EventDescription',
  MiscFormContactAssociationBookinTitle = 'Misc-Form-ContactAssociation-BookingTitle',
  MiscFormColor = 'Misc-Form-Color',
  MiscFormIcon = 'Misc-Form-Icon',
  MiscFormStatus = 'Misc-Form-Status',
  MiscFormAssignee = 'Misc-Form-Assignee',
  MiscFormNotes = 'Misc-Form-Notes',
  MiscFormReplaceTheFile = 'Misc-Form-ReplaceTheFile',
  MiscFormUploadFileToReplaceCurrent = 'Misc-Form-UploadFileToReplaceCurrent',
  MiscFormVenues = 'Misc-Form-Venues',
  MiscFormDate = 'Misc-Form-Date',
  MiscFormDates = 'Misc-Form-Dates',
  MiscFormEventUpdateDateChanging = 'Misc-Form-EventUpdate-DateChanging',
  MiscFormEventUpdateDateChangingWarning = 'Misc-Form-EventUpdate-DateChangingWarning',
  MiscFormVisibleBy = 'Misc-Form-VisibleBy',
  MiscFormIsDefaultStatus = 'Misc-Form-IsDefaultStatus',
  MiscFormSelectABooking = 'Misc-Form-SelectABooking',
  MiscFormPickupTime = 'Misc-Form-PickupTime',
  MiscFormDueDate = 'Misc-Form-DueDate',
  MiscFormDuration = 'Misc-Form-Duration',
  MiscFormPassengers = 'Misc-Form-Passengers',
  MiscFormDriver = 'Misc-Form-Driver',
  MiscFormVehicleDetails = 'Misc-Form-VehicleDetails',
  MiscFormStars = 'Misc-Form-Stars',
  MiscFormInvoiceHowToCreate = 'Misc-Form-Invoice-HowToCreate',
  MiscFormInvoiceUploadPDF = 'Misc-Form-Invoice-UploadPDF',
  MiscFormInvoiceManual = 'Misc-Form-Invoice-Manual',
  MiscFormInvoiceFileSizeWarning = 'Misc-Form-Invoice-FileSizeWarning',
  MiscFormInvoiceReference = 'Misc-Form-Invoice-Reference',
  MiscFormInvoiceAmount = 'Misc-Form-Invoice-Amount',
  MiscFormInvoiceAmountInclTaxes = 'Misc-Form-Invoice-AmountInclTaxes',
  MiscFormInvoiceAmountExclTaxes = 'Misc-Form-Invoice-AmountExclTaxes',
  MiscFormInvoiceDueDate = 'Misc-Form-Invoice-DueDate',
  MiscFormInvoiceSupplier = 'Misc-Form-Invoice-Supplier',
  MiscFormInvoicePayments = 'Misc-Form-Invoice-Payments',
  MiscFormInvoiceOptions = 'Misc-Form-Invoice-Options',
  MiscFormInvoiceCategory = 'Misc-Form-Invoice-Category',
  MiscFormPaymentCalculatePaidAmount = 'Misc-Form-Payment-CalculatePaidAmount',
  MiscFormPaymentOperationType = 'Misc-Form-Payment-OperationType',
  MiscFormPaymentBankTransfer = 'Misc-Form-Payment-BankTransfer',
  MiscFormPaymentCard = 'Misc-Form-Payment-Card',
  MiscFormPaymentCheck = 'Misc-Form-Payment-Check',
  MiscFormPaymentCash = 'Misc-Form-Payment-Cash',
  MiscFormPaymentOperationDate = 'Misc-Form-Payment-OperationDate',
  MiscFormPaymentPOP = 'Misc-Form-Payment-POP',
  MiscFormReportItemCost = 'Misc-Form-ReportItem-Cost',
  MiscFormReportItemRevenue = 'Misc-Form-ReportItem-Revenue',
  MiscFormStage = 'Misc-Form-Stage',
  MiscFormRoomHotel = 'Misc-Form-Room-Hotel',
  MiscFormRoomReference = 'Misc-Form-Room-Reference',
  MiscFormRoomCategory = 'Misc-Form-Room-Category',
  MiscFormRoomTravelers = 'Misc-Form-Room-Travelers',
  MiscFormRoomPrice = 'Misc-Form-Room-Price',
  MiscFormRoomRoomCredits = 'Misc-Form-Room-RoomCredits',
  MiscFormStationNoStationFound = 'Misc-Form-Station-NoStationFound',
  MiscFormStationError = 'Misc-Form-Station-Error',
  MiscFormStationMinLength = 'Misc-Form-Station-MinLength',
  MiscFormStationTransportType = 'Misc-Form-Station-TransportType',
  MiscFormStationAirport = 'Misc-Form-Station-Airport',
  MiscFormStationTrainStation = 'Misc-Form-Station-TrainStation',
  MiscFormStationSearch = 'Misc-Form-Station-Search',
  MiscFormStationAdded = 'Misc-Form-Station-Added',
  MiscFormRegistrationNumber = 'Misc-Form-RegistrationNumber',
  MiscFormTravelType = 'Misc-Form-Travel-Type',
  MiscFormTravelInbound = 'Misc-Form-Travel-Inbound',
  MiscFormTravelOutbound = 'Misc-Form-Travel-Outbound',
  MiscFormTravelTransportType = 'Misc-Form-Travel-TransportType',
  MiscFormTravelPlane = 'Misc-Form-Travel-Plane',
  MiscFormTravelTrain = 'Misc-Form-Travel-Train',
  MiscFormTravelFlightNumber = 'Misc-Form-Travel-FlightNumber',
  MiscFormTravelTrainNumber = 'Misc-Form-Travel-TrainNumber',
  MiscFormTravelDeparture = 'Misc-Form-Travel-Departure',
  MiscFormTravelDepartureTime = 'Misc-Form-Travel-DepartureTime',
  MiscFormTravelArrival = 'Misc-Form-Travel-Arrival',
  MiscFormTravelArrivalTime = 'Misc-Form-Travel-ArrivalTime',
  MiscFormUserResourcesDeleteDescription = 'Misc-Form-UserResourcesDeleteDescription',
  MiscFormEventColorOnCalendar = 'Misc-Form-EventColorOnCalendar',
  MiscFormOpening = 'Misc-Form-Opening',
  MiscFormClosing = 'Misc-Form-Closing',
  MiscFormClosingIsOnNextDay = 'Misc-Form-ClosingIsOnNextDay',
  MiscFormPrevious = 'Misc-Form-Previous',
  MiscFormNext = 'Misc-Form-Next',
  MiscFormCurrency = 'Currency',
  MiscFormUnitOfMeasurement = 'Unit of measurement',
  MiscFormLocale = 'Locale',

  MiscErrorRequire = 'Misc-Error-Require',
  MiscErrorTypeString = 'Misc-Error-TypeString',
  MiscErrorTypeNumber = 'Misc-Error-TypeNumber',
  MiscErrorTypeLink = 'Misc-Error-TypeLink',
  MiscErrorTypeEmail = 'Misc-Error-TypeEmail',
  MiscErrorNotValidPhone = 'Misc-Error-NotValidPhone',
  MiscErrorMinLength = 'Misc-Error-MinLength',
  MiscErrorCaps = 'Misc-Error-Caps',
  MiscErrorLowercase = 'Misc-Error-Lowercase',
  MiscErrorContainsNumber = 'Misc-Error-ContainsNumber',
  MiscErrorContainsSymbol = 'Misc-Error-ContainsSymbol',
  MiscErrorMatchValue = 'Misc-Error-MatchValue',

  MiscColorPink = 'Misc-Color-Pink',
  MiscColorRed = 'Misc-Color-Red',
  MiscColorYellow = 'Misc-Color-Yellow',
  MiscColorOrange = 'Misc-Color-Orange',
  MiscColorCyan = 'Misc-Color-Cyan',
  MiscColorGreen = 'Misc-Color-Green',
  MiscColorBlue = 'Misc-Color-Blue',
  MiscColorPurple = 'Misc-Color-Purple',
  MiscColorGeekblue = 'Misc-Color-Geekblue',
  MiscColorMagenta = 'Misc-Color-Magenta',
  MiscColorVolcano = 'Misc-Color-Volcano',
  MiscColorGold = 'Misc-Color-Gold',
  MiscColorLime = 'Misc-Color-Lime',

  /**
   * ENTITY
   */
  EntityArtist = 'Entity-artist',
  EntityEvent = 'Entity-event',
  EntityEventStatus = 'Entity-event status',
  EntityBooking = 'Entity-booking',
  EntityBookingType = 'Entity-booking type',
  EntityContract = 'Entity-contract',
  EntityContractStatus = 'Entity-contract status',
  EntityInvoice = 'Entity-invoice',
  EntityPayment = 'Entity-payment',
  EntityHotel = 'Entity-hotel',
  EntityRoom = 'Entity-room',
  EntityRider = 'Entity-rider',
  EntityRiderStatus = 'Entity-rider status',
  EntityFile = 'Entity-file',
  EntityUser = 'Entity-user',
  EntityVenue = 'Entity-venue',
  EntityVenueType = 'Entity-venue type',
  EntityStage = 'Entity-stage',
  EntityContact = 'Entity-contact',
  EntityContactType = 'Entity-contact type',
  EntityContactAssociation = 'Entity-contact association',
  EntityTravel = 'Entity-travel',
  EntityTravelType = 'Entity-travel type',
  EntityGround = 'Entity-ground',
  EntityPerformance = 'Entity-performance',
  EntityStation = 'Entity-station',
  EntityStationType = 'Entity-station type',
  EntityPublicLink = 'Entity-public link',
  EntityRole = 'Entity-role',
  EntityCustomAddress = 'Entity-custom address',
  EntitySupplier = 'Entity-supplier',
  EntitySupplierType = 'Entity-supplier type',
  EntityInvoiceCategory = 'Entity-invoice category',
  EntityInvitation = 'Entity-invitation',
  EntityNotification = 'Entity-notification',
  EntityReport = 'Entity-report',
  EntityReportItem = 'Entity-report item',
  EntityTask = 'Entity-task',
  EntityTaskStatus = 'Entity-task status',
  EntityExternalAdvancingLink = 'Entity-external advancing Link',
  EntityCurrency = 'Entity-currency',

  /**
   * EXTERNAL ADVANCING
   */
  ExternalAdvancingDeal = 'ExternalAdvancing-Deal',
  ExternalAdvancingTimetable = 'ExternalAdvancing-Timetable',
  ExternalAdvancingContract = 'ExternalAdvancing-Contract',
  ExternalAdvancingInvoices = 'ExternalAdvancing-Invoices',
  ExternalAdvancingTravels = 'ExternalAdvancing-Travels',
  ExternalAdvancingHotels = 'ExternalAdvancing-Hotels',
  ExternalAdvancingGround = 'ExternalAdvancing-Ground',
  ExternalAdvancingTechnical = 'ExternalAdvancing-Technical',
  ExternalAdvancingPartyCrew = 'ExternalAdvancing-PartyCrew',

  /**
   * Help
   */
  HelpArticleAddAndManageHotelRooms = 'Help-Article-AddAndManageHotelRooms',
  HelpArticleManageEventStatusAndPermissions = 'Help-Article-ManageEventStatusAndPermissions',
  HelpArticleAddAndManageGroundTransfers = 'Help-Article-AddAndManageGroundTransfers',
  HelpArticleAddAndManageTravels = 'Help-Article-AddAndManageTravels',
  HelpArticleCreateAVenue = 'Help-Article-CreateAVenue',
  HelpArticleAddAndManagePartyCrew = 'Help-Article-AddAndManagePartyCrew',
  HelpArticleCreateAndManageYourEvent = 'Help-Article-CreateAndManageYourEvent',
  HelpArticleAddAndManageTimetable = 'Help-Article-AddAndManageTimetable',
  HelpArticleShareAFile = 'Help-Article-ShareAFile',
  HelpArticleAddAndManageTechnicalSheets = 'Help-Article-AddAndManageTechnicalSheets',
  HelpArticleManageTeamAndPermissions = 'Help-Article-ManageTeamAndPermissions',
  HelpArticleAddAndManageContact = 'Help-Article-AddAndManageContact',
  HelpArticleAddContractOnEvent = 'Help-Article-AddContractOnEvent',
  HelpArticleAddAndManageHotels = 'Help-Article-AddAndManageHotels',
  HelpArticleAddAndManageLineUp = 'Help-Article-AddAndManageLineUp',
  HelpArticleHowToAddAnInvoice = 'Help-Article-HowToAddAnInvoice',
  HelpArticleHowToMarkAnInvoiceAsPaid = 'Help-Article-HowToMarkAnInvoiceAsPaid',
  HelpArticleHowToGenerateRoadsheet = 'Help-Article-HowToGenerateRoadsheet',
  HelpAlertTitle = 'Help-Alert-Title',
  HelpAlertDescription = 'Help-Alert-Description',
  HelpAlertButton = 'Help-Alert-Button',
}

const Misc = {
  Button: { Update: LocalizationKey.MiscButtonUpdate },
  Table: {
    Actions: LocalizationKey.MiscTableActions,
    Name: LocalizationKey.MiscTableName,
    Color: LocalizationKey.MiscTableColor,
    Icon: LocalizationKey.MiscTableIcon,
    Search: {
      Search: LocalizationKey.MiscTableSearchSearch,
      Reset: LocalizationKey.MiscTableSearchReset,
      Close: LocalizationKey.MiscTableSearchClose,
    },
  },
  Form: {
    Role: LocalizationKey.MiscFormRole,
    Title: LocalizationKey.MiscFormTitle,
    Description: LocalizationKey.MiscFormDescription,
    Name: LocalizationKey.MiscFormName,
    FirstName: LocalizationKey.MiscFormFirstName,
    LastName: LocalizationKey.MiscFormLastName,
    Email: LocalizationKey.MiscFormEmail,
    Phone: LocalizationKey.MiscFormPhone,
    Password: LocalizationKey.MiscFormPassword,
    PasswordConfirmation: LocalizationKey.MiscFormPasswordConfirmation,
    RememberMe: LocalizationKey.MiscFormRememberMe,
    Add: LocalizationKey.MiscFormAdd,
    Import: LocalizationKey.MiscFormImport,
    Update: LocalizationKey.MiscFormUpdate,
    CancelModifications: LocalizationKey.MiscFormCancelModifications,
    Currency: LocalizationKey.MiscFormCurrency,
    UnitOfMeasurement: LocalizationKey.MiscFormUnitOfMeasurement,
    Locale: LocalizationKey.MiscFormLocale,
    Address: {
      FormLabel: LocalizationKey.MiscFormAddress,
      Search: LocalizationKey.MiscFormAddressSearch,
      CloseManual: LocalizationKey.MiscFormAddressCloseManual,
      OpenManual: LocalizationKey.MiscFormAddressOpenManual,
      Label: LocalizationKey.MiscFormAddressLabel,
      StreetNumber: LocalizationKey.MiscFormAddressStreetNumber,
      Street: LocalizationKey.MiscFormAddressStreet,
      City: LocalizationKey.MiscFormAddressCity,
      PostCode: LocalizationKey.MiscFormAddressPostCode,
      State: LocalizationKey.MiscFormAddressState,
      Country: LocalizationKey.MiscFormAddressCountry,
      Latitude: LocalizationKey.MiscFormAddressLatitude,
      Longitude: LocalizationKey.MiscFormAddressLongitude,
    },
    CompanyName: LocalizationKey.MiscFormCompanyName,
    TaxNumber: LocalizationKey.MiscFormTaxNumber,
    HowToCreate: LocalizationKey.MiscFormHowToCreate,
    AreYouSureToDelete: LocalizationKey.MiscFormAreYouSureToDelete,
    Booking: LocalizationKey.MiscFormBooking,
    Event: LocalizationKey.MiscFormEvent,
    Artist: LocalizationKey.MiscFormArtist,
    Type: LocalizationKey.MiscFormType,
    Price: LocalizationKey.MiscFormPrice,
    MemoDeal: LocalizationKey.MiscFormMemoDeal,
    ActionCannotBeUndone: LocalizationKey.MiscFormActionCannotBeUndone,
    BookingResourcesDeleteDescription:
      LocalizationKey.MiscFormBookingResourcesDeleteDescription,
    InputValueToConfirm: LocalizationKey.MiscFormInputValueToConfirm,
    Owner: LocalizationKey.MiscFormOwner,
    Comment: LocalizationKey.MiscFormComment,
    Company: LocalizationKey.MiscFormCompany,
    Contact: LocalizationKey.MiscFormContact,
    ContactAssociation: {
      EventTitle: LocalizationKey.MiscFormContactAssociationEventTitle,
      EventDescription:
        LocalizationKey.MiscFormContactAssociationEventDescription,
      BookingTitle: LocalizationKey.MiscFormContactAssociationBookinTitle,
    },
    Color: LocalizationKey.MiscFormColor,
    Icon: LocalizationKey.MiscFormIcon,
    Status: LocalizationKey.MiscFormStatus,
    Assignee: LocalizationKey.MiscFormAssignee,
    Notes: LocalizationKey.MiscFormNotes,
    ReplaceTheFile: LocalizationKey.MiscFormReplaceTheFile,
    UploadFileToReplaceCurrent:
      LocalizationKey.MiscFormUploadFileToReplaceCurrent,
    Venues: LocalizationKey.MiscFormVenues,
    Date: LocalizationKey.MiscFormDate,
    Dates: LocalizationKey.MiscFormDates,
    EventUpdate: {
      DateChanging: LocalizationKey.MiscFormEventUpdateDateChanging,
      DateChangingWarning:
        LocalizationKey.MiscFormEventUpdateDateChangingWarning,
    },
    VisibleBy: LocalizationKey.MiscFormVisibleBy,
    IsDefaultStatus: LocalizationKey.MiscFormIsDefaultStatus,
    SelectABooking: LocalizationKey.MiscFormSelectABooking,
    PickupTime: LocalizationKey.MiscFormPickupTime,
    DueDate: LocalizationKey.MiscFormDueDate,
    Duration: LocalizationKey.MiscFormDuration,
    Passengers: LocalizationKey.MiscFormPassengers,
    Driver: LocalizationKey.MiscFormDriver,
    VehicleDetails: LocalizationKey.MiscFormVehicleDetails,
    Stars: LocalizationKey.MiscFormStars,
    Invoice: {
      HowToCreate: LocalizationKey.MiscFormInvoiceHowToCreate,
      UploadPDF: LocalizationKey.MiscFormInvoiceUploadPDF,
      Manual: LocalizationKey.MiscFormInvoiceManual,
      FileSizeWarning: LocalizationKey.MiscFormInvoiceFileSizeWarning,
      Reference: LocalizationKey.MiscFormInvoiceReference,
      Amount: LocalizationKey.MiscFormInvoiceAmount,
      AmountInclTaxes: LocalizationKey.MiscFormInvoiceAmountInclTaxes,
      AmountExclTaxes: LocalizationKey.MiscFormInvoiceAmountExclTaxes,
      DueDate: LocalizationKey.MiscFormInvoiceDueDate,
      Supplier: LocalizationKey.MiscFormInvoiceSupplier,
      Payments: LocalizationKey.MiscFormInvoicePayments,
      Options: LocalizationKey.MiscFormInvoiceOptions,
      Category: LocalizationKey.MiscFormInvoiceCategory,
    },
    Payment: {
      CalculatePaidAmount: LocalizationKey.MiscFormPaymentCalculatePaidAmount,
      OperationType: LocalizationKey.MiscFormPaymentOperationType,
      BankTransfer: LocalizationKey.MiscFormPaymentBankTransfer,
      Card: LocalizationKey.MiscFormPaymentCard,
      Check: LocalizationKey.MiscFormPaymentCheck,
      Cash: LocalizationKey.MiscFormPaymentCash,
      OperationDate: LocalizationKey.MiscFormPaymentOperationDate,
      POP: LocalizationKey.MiscFormPaymentPOP,
    },
    ReportItem: {
      Cost: LocalizationKey.MiscFormReportItemCost,
      Revenue: LocalizationKey.MiscFormReportItemRevenue,
    },
    Stage: LocalizationKey.MiscFormStage,
    Room: {
      Hotel: LocalizationKey.MiscFormRoomHotel,
      Reference: LocalizationKey.MiscFormRoomReference,
      Category: LocalizationKey.MiscFormRoomCategory,
      Travelers: LocalizationKey.MiscFormRoomTravelers,
      Price: LocalizationKey.MiscFormRoomPrice,
      RoomCredits: LocalizationKey.MiscFormRoomRoomCredits,
    },
    Station: {
      NoStationFound: LocalizationKey.MiscFormStationNoStationFound,
      Error: LocalizationKey.MiscFormStationError,
      MinLength: LocalizationKey.MiscFormStationMinLength,
      TransportType: LocalizationKey.MiscFormStationTransportType,
      Airport: LocalizationKey.MiscFormStationAirport,
      TrainStation: LocalizationKey.MiscFormStationTrainStation,
      Search: LocalizationKey.MiscFormStationSearch,
      Added: LocalizationKey.MiscFormStationAdded,
    },
    RegistrationNumber: LocalizationKey.MiscFormRegistrationNumber,
    Travel: {
      Type: LocalizationKey.MiscFormTravelType,
      Inbound: LocalizationKey.MiscFormTravelInbound,
      Outbound: LocalizationKey.MiscFormTravelOutbound,
      TransportType: LocalizationKey.MiscFormTravelTransportType,
      Plane: LocalizationKey.MiscFormTravelPlane,
      Train: LocalizationKey.MiscFormTravelTrain,
      FlightNumber: LocalizationKey.MiscFormTravelFlightNumber,
      TrainNumber: LocalizationKey.MiscFormTravelTrainNumber,
      Departure: LocalizationKey.MiscFormTravelDeparture,
      DepartureTime: LocalizationKey.MiscFormTravelDepartureTime,
      Arrival: LocalizationKey.MiscFormTravelArrival,
      ArrivalTime: LocalizationKey.MiscFormTravelArrivalTime,
    },
    UserResourcesDeleteDescription:
      LocalizationKey.MiscFormUserResourcesDeleteDescription,
    EventColorOnCalendar: LocalizationKey.MiscFormEventColorOnCalendar,
    Opening: LocalizationKey.MiscFormOpening,
    Closing: LocalizationKey.MiscFormClosing,
    ClosingIsOnNextDay: LocalizationKey.MiscFormClosingIsOnNextDay,
    Previous: LocalizationKey.MiscFormPrevious,
    Next: LocalizationKey.MiscFormNext,
  },
  Error: {
    Require: LocalizationKey.MiscErrorRequire,
    TypeString: LocalizationKey.MiscErrorTypeString,
    TypeNumber: LocalizationKey.MiscErrorTypeNumber,
    TypeLink: LocalizationKey.MiscErrorTypeLink,
    TypeEmail: LocalizationKey.MiscErrorTypeEmail,
    NotValidPhone: LocalizationKey.MiscErrorNotValidPhone,
    MinLength: LocalizationKey.MiscErrorMinLength,
    Caps: LocalizationKey.MiscErrorCaps,
    Lowercase: LocalizationKey.MiscErrorLowercase,
    ContainsNumber: LocalizationKey.MiscErrorContainsNumber,
    ContainsSymbol: LocalizationKey.MiscErrorContainsSymbol,
    MismatchValue: LocalizationKey.MiscErrorMatchValue,
  },
  Color: {
    Pink: LocalizationKey.MiscColorPink,
    Red: LocalizationKey.MiscColorRed,
    Yellow: LocalizationKey.MiscColorYellow,
    Orange: LocalizationKey.MiscColorOrange,
    Cyan: LocalizationKey.MiscColorCyan,
    Green: LocalizationKey.MiscColorGreen,
    Blue: LocalizationKey.MiscColorBlue,
    Purple: LocalizationKey.MiscColorPurple,
    Geekblue: LocalizationKey.MiscColorGeekblue,
    Magenta: LocalizationKey.MiscColorMagenta,
    Volcano: LocalizationKey.MiscColorVolcano,
    Gold: LocalizationKey.MiscColorGold,
    Lime: LocalizationKey.MiscColorLime,
  },
}

const Entity: Record<SBRMType, string> = {
  artist: LocalizationKey.EntityArtist,
  event: LocalizationKey.EntityEvent,
  ['event status']: LocalizationKey.EntityEventStatus,
  booking: LocalizationKey.EntityBooking,
  ['booking type']: LocalizationKey.EntityBookingType,
  contract: LocalizationKey.EntityContract,
  ['contract status']: LocalizationKey.EntityContractStatus,
  invoice: LocalizationKey.EntityInvoice,
  payment: LocalizationKey.EntityPayment,
  hotel: LocalizationKey.EntityHotel,
  room: LocalizationKey.EntityRoom,
  rider: LocalizationKey.EntityRider,
  ['rider status']: LocalizationKey.EntityRiderStatus,
  file: LocalizationKey.EntityFile,
  user: LocalizationKey.EntityUser,
  venue: LocalizationKey.EntityVenue,
  ['venue type']: LocalizationKey.EntityVenueType,
  stage: LocalizationKey.EntityStage,
  contact: LocalizationKey.EntityContact,
  ['contact type']: LocalizationKey.EntityContactType,
  ['contact association']: LocalizationKey.EntityContactAssociation,
  travel: LocalizationKey.EntityTravel,
  ['travel type']: LocalizationKey.EntityTravelType,
  ground: LocalizationKey.EntityGround,
  performance: LocalizationKey.EntityPerformance,
  station: LocalizationKey.EntityStation,
  ['station type']: LocalizationKey.EntityStationType,
  customaddress: LocalizationKey.EntityCustomAddress,
  notification: LocalizationKey.EntityNotification,
  ['external advancing link']: LocalizationKey.EntityExternalAdvancingLink,
  currency: LocalizationKey.EntityCurrency,
}

const ExternalAdvancing: Record<ALMSectionKey, string> = {
  deal: LocalizationKey.ExternalAdvancingDeal,
  timetable: LocalizationKey.ExternalAdvancingTimetable,
  contract: LocalizationKey.ExternalAdvancingContract,
  invoices: LocalizationKey.ExternalAdvancingInvoices,
  travels: LocalizationKey.ExternalAdvancingTravels,
  hotels: LocalizationKey.ExternalAdvancingHotels,
  ground: LocalizationKey.ExternalAdvancingGround,
  technical: LocalizationKey.ExternalAdvancingTechnical,
  partyCrew: LocalizationKey.ExternalAdvancingPartyCrew,
}

const LocalizationKeys = {
  ExternalAdvancing: ExternalAdvancing,
  Auth: {
    ForgotPassword: {
      Button: LocalizationKey.AuthForgotPasswordButton,
      Success: LocalizationKey.AuthForgotPasswordSuccess,
      Failure: LocalizationKey.AuthForgotPasswordFailure,
      BackToLogin: LocalizationKey.AuthForgotPasswordBackToLogin,
    },
    Invitation: {
      ErrorWhileAccepting: LocalizationKey.AuthInvitationErrorWhileAccepting,
      ErrorWhileLoading: LocalizationKey.AuthInvitationErrorWhileLoading,
      Title: LocalizationKey.AuthInvitationTitle,
      NeedsYou: LocalizationKey.AuthInvitationNeedsYou,
      Accept: LocalizationKey.AuthInvitationAccept,
      Register: LocalizationKey.AuthInvitationRegister,
      AlreadyHaveAnAccount: LocalizationKey.AuthInvitationAlreadyHaveAnAccount,
    },
    Login: {
      Error: LocalizationKey.AuthLoginError,
      SSOError: LocalizationKey.AuthLoginSSOError,
      SSO: LocalizationKey.AuthLoginSSO,
      Login: LocalizationKey.AuthLogin,
      ForgotPassword: LocalizationKey.AuthLoginForgotPassword,
      ContinueWithSSO: LocalizationKey.AuthLoginContinueWithSSO,
      LoginWithMicrosoft: LocalizationKey.AuthLoginLoginWithMicrosoft,
      EmailConnexion: LocalizationKey.AuthLoginEmailConnexion,
      Redirect: LocalizationKey.AuthLoginRedirect,
    },
    Register: {
      Error: LocalizationKey.AuthRegisterError,
      ErrorEmailAlreadyExists:
        LocalizationKey.AuthRegisterErrorEmailAlreadyExists,
      Title: LocalizationKey.AuthRegisterTitle,
      InvitedYou: LocalizationKey.AuthRegisterInvitedYouToJoinTheTeam,
      Register: LocalizationKey.AuthRegister,
      AlreadyHaveAnAccount: LocalizationKey.AuthRegisterAlreadyHaveAnAccount,
      Success: LocalizationKey.AuthRegisterSuccess,
      GoToLogin: LocalizationKey.AuthRegisterGoToLogin,
    },
  },
  Page: {
    Artists: {
      Title: LocalizationKey.PageArtistsTitle,
      Name: LocalizationKey.PageArtistsName,
      BookingsCount: LocalizationKey.PageArtistsBookingsCount,
    },
    Artist: {
      LastFee: LocalizationKey.PageArtistLastFee,
      AverageFee: LocalizationKey.PageArtistAverageFee,
      MinimumFee: LocalizationKey.PageArtistMinimumFee,
      MaximumFee: LocalizationKey.PageArtistMaximumFee,
      Bookings: LocalizationKey.PageArtistBookings,
      Listen: LocalizationKey.PageArtistListen,
      Crew: LocalizationKey.PageArtistCrew,
      Files: LocalizationKey.PageArtistFiles,
    },
    Preference: {
      Title: LocalizationKey.PagePreferenceTitle,
      Locale: LocalizationKey.PagePreferenceLocale,
    },
    Profile: {
      Title: LocalizationKey.PageProfileTitle,
      UpdateSuccess: LocalizationKey.PageProfileUpdateSuccess,
      UpdateFailure: LocalizationKey.PageProfileUpdateFailure,
    },
    Organization: {
      Title: LocalizationKey.PageSettingsOrganizationTitle,
      BillingInfos: LocalizationKey.PageSettingsOrganizationBillingInfos,
      Plan: LocalizationKey.PageSettingsOrganizationPlan,
      Roles: LocalizationKey.PageSettingsOrganizationRoles,
    },
    Activity: {
      Title: LocalizationKey.PageAccountActivityTitle,
      Toolbar: LocalizationKey.PageAccountActivityToolbar,
    },
    CRM: {
      Contacts: {
        Title: LocalizationKey.PageCRMContactsTitle,
        Name: LocalizationKey.PageCRMContactsName,
        Search: LocalizationKey.PageCRMContactsSearch,
        Merge: LocalizationKey.PageCRMContactsMerge,
      },
      Contact: {
        Loading: LocalizationKey.PageCRMContactLoading,
        Back: LocalizationKey.PageCRMContactBack,
        WorkFor: LocalizationKey.PageCRMContactWorkFor,
      },
      ContactMerger: {
        Success: LocalizationKey.PageCRMContactMergerSuccess,
        Failure: LocalizationKey.PageCRMContactMergerFailure,
        Title: LocalizationKey.PageCRMContactMergerTitle,
        Description: LocalizationKey.PageCRMContactMergerDescription,
        SelectContact: LocalizationKey.PageCRMContactMergerSelectContact,
        ContactToKeep: LocalizationKey.PageCRMContactMergerContactToKeep,
        ContactToDelete: LocalizationKey.PageCRMContactMergerContactToDelete,
        MergeButton: LocalizationKey.PageCRMContactMergerMergeButton,
      },
      Suppliers: {
        Title: LocalizationKey.PageCRMSuppliersTitle,
        Name: LocalizationKey.PageCRMSuppliersName,
        Type: LocalizationKey.PageCRMSuppliersType,
        SupplierBalance: LocalizationKey.PageCRMSuppliersSupplierBalance,
        Contacts: LocalizationKey.PageCRMSuppliersContacts,
        Search: LocalizationKey.PageCRMSuppliersSearch,
      },
      Supplier: {
        Loading: LocalizationKey.PageCRMSupplierLoading,
        Back: LocalizationKey.PageCRMSupplierBack,
        UpdatePicture: LocalizationKey.PageCRMSupplierUpdatePicture,
        Team: LocalizationKey.PageCRMSupplierTeam,
        Invoices: LocalizationKey.PageCRMSupplierInvoices,
      },
      User: {
        Loading: LocalizationKey.PageCRMUserLoading,
        Back: LocalizationKey.PageCRMUserBack,
        AssociatedContact: LocalizationKey.PageCRMUserAssociatedContact,
        Events: LocalizationKey.PageCRMUserEvents,
        Activities: LocalizationKey.PageCRMUserActivities,
      },
      Team: {
        Name: LocalizationKey.PageCRMTeamName,
        Role: LocalizationKey.PageCRMTeamRole,
        Title: LocalizationKey.PageCRMTeamTitle,
        Invitations: LocalizationKey.PageCRMTeamInvitations,
      },
      Invitations: {
        Title: LocalizationKey.PageCRMInvitationsTitle,
        Back: LocalizationKey.PageCRMInvitationsBack,
        InvitedAt: LocalizationKey.PageCRMInvitationsInvitedAt,
      },
    },
    EventDetails: {
      Contacts: LocalizationKey.PageEventDetailsContacts,
      Files: LocalizationKey.PageEventDetailsFiles,
      TimeTable: LocalizationKey.PageEventDetailsTimetable,
      PartyCrew: LocalizationKey.PageEventDetailsPartyCrew,
      Lineup: {
        Artist: LocalizationKey.PageEventDetailsLineUpArtist,
        Price: LocalizationKey.PageEventDetailsLineUpPrice,
        Type: LocalizationKey.PageEventDetailsLineUpType,
        MemoDeal: LocalizationKey.PageEventDetailsLineUpMemoDeal,
        Owner: LocalizationKey.PageEventDetailsLineUpOwner,
        Title: LocalizationKey.PageEventDetailsLineUp,
      },
      Contracts: {
        Title: LocalizationKey.PageEventDetailsContractsTitle,
        Booking: LocalizationKey.PageEventDetailsContractsBooking,
        Status: LocalizationKey.PageEventDetailsContractsStatus,
        Notes: LocalizationKey.PageEventDetailsContractsNotes,
      },
      Invoices: {
        Title: LocalizationKey.PageEventDetailsInvoicesTitle,
        AmountPaid: LocalizationKey.PageEventDetailsInvoicesAmountPaid,
        AmountToPay: LocalizationKey.PageEventDetailsInvoicesAmountToPay,
        InvoicesPaid: LocalizationKey.PageEventDetailsInvoicesInvoicesPaid,
        InvoicesToPay: LocalizationKey.PageEventDetailsInvoicesInvoicesToPay,
      },
      Reporting: {
        Title: LocalizationKey.PageEventDetailsReportingTitle,
      },
      Grounds: {
        Bookings: LocalizationKey.PageEventDetailsGroundsBookings,
        Infos: LocalizationKey.PageEventDetailsGroundsInfos,
        Driver: LocalizationKey.PageEventDetailsGroundsDriver,
        Passengers: LocalizationKey.PageEventDetailsGroundsPassengers,
        Notes: LocalizationKey.PageEventDetailsGroundsNotes,
        GroundNotes: LocalizationKey.PageEventDetailsGroundsGroundNotes,
        Ground: LocalizationKey.PageEventDetailsGroundsGround,
      },
      Hotels: {
        Booking: LocalizationKey.PageEventDetailsHotelsBooking,
        Hotel: LocalizationKey.PageEventDetailsHotelsHotel,
        Price: LocalizationKey.PageEventDetailsHotelsPrice,
        Dates: LocalizationKey.PageEventDetailsHotelsDates,
        Nights: LocalizationKey.PageEventDetailsHotelsNights,
        Travelers: LocalizationKey.PageEventDetailsHotelsTravelers,
        Notes: LocalizationKey.PageEventDetailsHotelsNotes,
        Title: LocalizationKey.PageEventDetailsHotelsTitle,
      },
      Technical: {
        Booking: LocalizationKey.PageEventDetailsTechnicalBooking,
        Status: LocalizationKey.PageEventDetailsTechnicalStatus,
        Notes: LocalizationKey.PageEventDetailsTechnicalNotes,
        Title: LocalizationKey.PageEventDetailsTechnicalTitle,
      },
      Travels: {
        Booking: LocalizationKey.PageEventDetailsTravelsBooking,
        Type: LocalizationKey.PageEventDetailsTravelsType,
        Travel: LocalizationKey.PageEventDetailsTravelsTravel,
        Travelers: LocalizationKey.PageEventDetailsTravelsTravelers,
        Title: LocalizationKey.PageEventDetailsTravelsTitle,
      },
    },
    Financials: {
      Invoices: {
        Title: LocalizationKey.PageFinancialInvoiceTitle,
      },
      Reports: {
        Title: LocalizationKey.PageFinancialReportsTitle,
      },
    },
    ReleaseNotes: {
      Title: LocalizationKey.PageReleaseNotesTitle,
    },
    Calendar: {
      Today: LocalizationKey.PageCalendarToday,
      All: LocalizationKey.PageCalendarAll,
      More: LocalizationKey.PageCalendarMore,
    },
    Settings: {
      Venues: {
        Name: LocalizationKey.PageSettingsVenuesName,
        Type: LocalizationKey.PageSettingsVenuesType,
        Stages: LocalizationKey.PageSettingsVenuesStages,
        OpeningRange: LocalizationKey.PageSettingsVenuesOpeningRange,
        Title: LocalizationKey.PageSettingsVenuesTitle,
        SeeArchived: LocalizationKey.PageSettingsVenuesSeeArchived,
      },
      ArchivedVenues: {
        Title: LocalizationKey.PageSettingsArchivedVenuesTitle,
        Restore: LocalizationKey.PageSettingsArchivedVenuesRestore,
      },
      Venue: {
        Staff: LocalizationKey.PageSettingsVenueStaff,
        Stages: LocalizationKey.PageSettingsVenueStages,
        Files: LocalizationKey.PageSettingsVenueFiles,
      },
      ContactTypes: LocalizationKey.PageSettingsContactTypesTitle,
      ContractStatus: LocalizationKey.PageSettingsContractStatusTitle,
      EventStatus: {
        DefaultStatus: LocalizationKey.PageSettingsEventStatusDefaultStatus,
        VisibleBy: LocalizationKey.PageSettingsEventStatusVisibleBy,
        Title: LocalizationKey.PageSettingsEventStatusTitle,
      },
      Hotels: {
        Address: LocalizationKey.PageSettingsHotelsAddress,
        Stars: LocalizationKey.PageSettingsHotelsStars,
        Title: LocalizationKey.PageSettingsHotelsTitle,
      },
      RiderStatus: LocalizationKey.PageSettingsRiderStatusTitle,
      Stations: LocalizationKey.PageSettingsStationsTitle,

      VenueTypes: LocalizationKey.PageSettingsVenueTypesTitle,
      Reporting: {
        Items: {
          Title: LocalizationKey.PageSettingsReportingItemsTitle,
          Reorder: LocalizationKey.PageSettingsReportingItemsReorder,
        },
        ReorderItems: {
          Title: LocalizationKey.PageSettingsReportingReorderItemsPageTitle,
          Costs: LocalizationKey.PageSettingsReportingReorderItemsPageCosts,
          Revenues:
            LocalizationKey.PageSettingsReportingReorderItemsPageRevenues,
        },
      },
      OrganizationPreferences: {
        Title: LocalizationKey.PageSettingsOrganizationPreferencesTitle,
        UnitsOfMeasurementDescription:
          LocalizationKey.PageSettingsOrganizationPreferencesUnitsOfMeasurementDescription,
        LocaleDescription:
          LocalizationKey.PageSettingsOrganizationPreferencesLocaleDescription,
        MetricLabel:
          LocalizationKey.PageSettingsOrganizationPreferencesMetricLabel,
        ImperialLabel:
          LocalizationKey.PageSettingsOrganizationPreferencesImperialLabel,
      },
    },
    EventSelector: {
      Title: LocalizationKey.PageEventSelectorTitle,
    },
    ExernalAdvancing: {
      YouCanEditUntill: LocalizationKey.PageExternalAdvancingYouCanEditUntill,
      OpenChat: LocalizationKey.PageExternalAdvancingOpenChat,
      YourContact: LocalizationKey.PageExternalAdvancingYourContact,
      YourArtist: LocalizationKey.PageExternalAdvancingYourArtist,
      DeadlineReadOnly: LocalizationKey.PageExternalAdvancingDeadlineReadOnly,
      DeadlineDescription:
        LocalizationKey.PageExternalAdvancingDeadlineDescription,
    },
  },
  Layout: {
    EventDetailsSider: {
      Lineup: LocalizationKey.LayoutEventDetailsSiderLineup,
      Timetable: LocalizationKey.LayoutEventDetailsSiderTimetable,
      Admin: LocalizationKey.LayoutEventDetailsSiderAdmin,
      Contracts: LocalizationKey.LayoutEventDetailsSiderContracts,
      Invoices: LocalizationKey.LayoutEventDetailsSiderInvoices,
      Reporting: LocalizationKey.LayoutEventDetailsSiderReporting,
      Production: LocalizationKey.LayoutEventDetailsSiderProduction,
      Travels: LocalizationKey.LayoutEventDetailsSiderTravels,
      Hotels: LocalizationKey.LayoutEventDetailsSiderHotels,
      Ground: LocalizationKey.LayoutEventDetailsSiderGround,
      Technical: LocalizationKey.LayoutEventDetailsSiderTechnical,
      PartyCrew: LocalizationKey.LayoutEventDetailsSiderPartyCrew,
      Files: LocalizationKey.LayoutEventDetailsSiderFiles,
      Contacts: LocalizationKey.LayoutEventDetailsSiderContacts,
      Settings: LocalizationKey.LayoutEventDetailsSiderSettings,
      Tasks: LocalizationKey.LayoutEventDetailsSiderTasks,
    },
    MainHeader: {
      Events: LocalizationKey.LayoutMainHeaderEvents,
      Artists: LocalizationKey.LayoutMainHeaderArtists,
      Financials: LocalizationKey.LayoutMainHeaderFinancials,
      CRM: LocalizationKey.LayoutMainHeaderCRM,
    },
    ProfileSider: {
      Informations: LocalizationKey.LayoutProfileSiderInformations,
      Activity: LocalizationKey.LayoutProfileSiderActivity,
      Preferences: LocalizationKey.LayoutProfileSiderPreferences,
      Logout: LocalizationKey.LayoutProfileSiderLogout,
      LogoutConfirmation: LocalizationKey.LayoutProfileSiderLogoutConfirmation,
    },
    RightHeader: {
      Profile: LocalizationKey.LayoutRightHeaderProfile,
      Settings: LocalizationKey.LayoutRightHeaderSettings,
      Lang: LocalizationKey.LayoutRightHeaderLang,
    },
    SettingsSider: {
      UploadOrganizationImage:
        LocalizationKey.LayoutSettingsSiderUploadOrganizationImage,
      Venues: LocalizationKey.LayoutSettingsSiderVenues,
      Hotels: LocalizationKey.LayoutSettingsSiderHotels,
      Stations: LocalizationKey.LayoutSettingsSiderStations,
      ReportingItems: LocalizationKey.LayoutSettingsSiderReportingItems,
      VenueTypes: LocalizationKey.LayoutSettingsSiderVenueTypes,
      ContactTypes: LocalizationKey.LayoutSettingsSiderContactTypes,
      EventStatuses: LocalizationKey.LayoutSettingsSiderEventStatuses,
      ContractStatuses: LocalizationKey.LayoutSettingsSiderContractStatuses,
      RiderStatuses: LocalizationKey.LayoutSettingsSiderRiderStatuses,
      Organization: LocalizationKey.LayoutSettingsSiderOrganization,
      Preferences: LocalizationKey.LayoutSettingsSiderPreferences,
    },
    Auth: {
      ContactUs: LocalizationKey.LayoutAuthContactUs,
      Discover: LocalizationKey.LayoutAuthDiscover,
      Legal: LocalizationKey.LayoutAuthLegal,
    },
    Financial: {
      Invoices: LocalizationKey.LayoutFinancialInvoices,
      Reports: LocalizationKey.LayoutFinancialReports,
    },
    CRM: {
      Contacts: LocalizationKey.LayoutCRMContacts,
      Suppliers: LocalizationKey.LayoutCRMSuppliers,
      Organization: LocalizationKey.LayoutCRMOrganization,
    },
    Footer: {
      ReleaseNotes: LocalizationKey.LayoutFooterReleaseNotes,
    },
  },
  FileSharing: {
    Expires: LocalizationKey.FileSharingExpires,
    Download: LocalizationKey.FileSharingDownload,
  },
  FileViewer: {
    Download: LocalizationKey.FileViewerDownload,
    PDFError: LocalizationKey.FileViewerPDFViewerError,
  },
  SearchDrawer: {
    Search: LocalizationKey.SearchDrawerSearch,
    Placeholder: LocalizationKey.SearchDrawerPlaceholder,
    Events: LocalizationKey.SearchDrawerEvents,
    Venues: LocalizationKey.SearchDrawerVenues,
    Artists: LocalizationKey.SearchDrawerArtists,
    Suppliers: LocalizationKey.SearchDrawerSuppliers,
  },
  SBRM: {
    EntityNotDefined: LocalizationKey.SBRMEntityNotDefined,
    Close: LocalizationKey.SBRMClose,
    Delete: LocalizationKey.SBRMDelete,
    Save: LocalizationKey.SBRMSave,
    Update: LocalizationKey.SBRMUpdate,
    ContactAssociatedWithUserNotEditable:
      LocalizationKey.SBRMContactAssociatedWithUserNotEditable,
    AddNew: LocalizationKey.SBRMAddNew,
    Duplicate: LocalizationKey.SBRMDuplicate,
    View: LocalizationKey.SBRMView,
    Comment: LocalizationKey.SBRMComment,
    Title: {
      'add-new': LocalizationKey.SBRMTitleAddNew,
      'create-new': LocalizationKey.SBRMTitleCreateNew,
      edit: LocalizationKey.SBRMTitleEdit,
      delete: LocalizationKey.SBRMTitleDelete,
      comment: LocalizationKey.SBRMTitleComment,
      view: LocalizationKey.SBRMTitleView,
      upload: LocalizationKey.SBRMTitleUpload,
      duplicate: LocalizationKey.SBRMTitleDuplicate,
      assign: LocalizationKey.SBRMTitleAssign,
      invite: LocalizationKey.SBRMTitleInvite,
      cancel: LocalizationKey.SBRMTitleCancel,
    },
  },
  SBAsyncSelect: {
    NotFound: LocalizationKey.SBAsyncSelectNotFound,
  },
  Components: {
    Booking: {
      ContactsTable: {
        ContactsFound:
          LocalizationKey.ComponentsBookingContactsTableContactsFound,
      },
      MissingContactsInPartyCrew:
        LocalizationKey.ComponentsBookingMissingContactsInPartyCrewTitle,
      Table: {
        Event: LocalizationKey.ComponentsBookingTableEvent,
        Venue: LocalizationKey.ComponentsBookingTableVenue,
        Price: LocalizationKey.ComponentsBookingTablePrice,
        MemoDeal: LocalizationKey.ComponentsBookingTableMemoDeal,
      },
    },
    Comment: {
      Editor: {
        MentionPlaceholder:
          LocalizationKey.ComponentsCommentEditorMentionPlaceholder,
        GIFPlaceholder: LocalizationKey.ComponentsCommentEditorGIFPlaceholder,
        Send: LocalizationKey.ComponentsCommentEditorSend,
      },
      List: {
        SendFirstMessage: LocalizationKey.ComponentsCommentListSendFirstMessage,
        UseTag: LocalizationKey.ComponentsCommentListUseTag,
      },
      SubscribeToConversation: {
        Subscribe:
          LocalizationKey.ComponentsCommentSubscribeToConversationSubscribe,
        Unsubscribe:
          LocalizationKey.ComponentsCommentSubscribeToConversationUnsubscribe,
      },
    },
    Contact: {
      LinkedToUser: LocalizationKey.ComponentsContactLinkedToUser,
      TableWithAssociation: {
        Contact: LocalizationKey.ComponentsContactTableWithAssociationContact,
        Type: LocalizationKey.ComponentsContactTableWithAssociationType,
      },
    },
    ContactType: {
      ListCheckboxes: {
        All: LocalizationKey.ComponentsContactTypeListCheckboxesAll,
        CreateType:
          LocalizationKey.ComponentsContactTypeListCheckboxesCreateType,
      },
    },
    CustomInput: {
      Select: {
        Footer: {
          Artist: {
            Spotify:
              LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotify,
            Manual:
              LocalizationKey.ComponentsCustomInputsSelectFooterArtistManual,
            SpotifyUrl:
              LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotifyUrl,
            ArtistName:
              LocalizationKey.ComponentsCustomInputsSelectFooterArtistArtistName,
          },
        },
      },
      Dropzone: LocalizationKey.ComponentsCustomInputsDropzone,
    },
    Event: {
      AutoImportVenueContacts:
        LocalizationKey.ComponentsEventAutoImportVenueContacts,
      Cover: LocalizationKey.ComponentsEventCover,
    },
    File: {
      Cell: LocalizationKey.ComponentsFileCell,
      Share: {
        Share: LocalizationKey.ComponentsFileShareShare,
        PublicLinkGenerated:
          LocalizationKey.ComponentsFileSharePublicLinkGenerated,
        PublicLinkError: LocalizationKey.ComponentsFileSharePublicLinkError,
        PublicLinkCopiedToClipboard:
          LocalizationKey.ComponentsFileSharePublicLinkCopiedToClipboard,
        CopyPublicLink: LocalizationKey.ComponentsFileShareCopyPublicLink,
      },
      Upload: LocalizationKey.ComponentsFileUpload,
    },
    Invitation: {
      ResendEmail: LocalizationKey.ComponentsInvitationResendEmail,
    },
    Performance: {
      Table: {
        Artist: LocalizationKey.ComponentsPerformanceTableArtist,
        SetTime: LocalizationKey.ComponentsPerformanceTableSetTime,
        Stage: LocalizationKey.ComponentsPerformanceTableStage,
      },
    },
    SBTable: {
      Empty: LocalizationKey.ComponentsSBTableEmpty,
    },
    SBActivityLog: {
      Created: LocalizationKey.ComponentsSBActivityLogCreated,
      Updated: LocalizationKey.ComponentsSBActivityLogUpdated,
      Deleted: LocalizationKey.ComponentsSBActivityLogDeleted,
    },
    NewVersionChecker: {
      Title: LocalizationKey.ComponentsNewVersionCheckerTitle,
      Description: LocalizationKey.ComponentsNewVersionCheckerDescription,
      Refresh: LocalizationKey.ComponentsNewVersionCheckerRefresh,
    },
    MaintenanceMode: {
      Title: LocalizationKey.ComponentsMaintenanceModeTitle,
      Description: LocalizationKey.ComponentsMaintenanceModeDescription,
      Reload: LocalizationKey.ComponentsMaintenanceModeReload,
    },
    Ground: {
      Cascader: {
        SelectBooking: LocalizationKey.ComponentsGroundCascaderSelectBooking,
      },
      CascaderItem: {
        Hotels: LocalizationKey.ComponentsGroundCascaderItemHotels,
        Venues: LocalizationKey.ComponentsGroundCascaderItemVenues,
        Inbound: LocalizationKey.ComponentsGroundCascaderItemInbound,
        Outbound: LocalizationKey.ComponentsGroundCascaderItemOutbound,
        CustomAddress:
          LocalizationKey.ComponentsGroundCascaderItemCustomAddress,
        NewCustomAddress:
          LocalizationKey.ComponentsGroundCascaderItemNewCustomAddress,
        From: LocalizationKey.ComponentsGroundCascaderItemFrom,
        To: LocalizationKey.ComponentsGroundCascaderItemTo,
      },
    },
    Invoice: {
      Filters: {
        Last7Days: LocalizationKey.ComponentsInvoiceFiltersLast7Days,
        Last14Days: LocalizationKey.ComponentsInvoiceFiltersLast14Days,
        Last30Days: LocalizationKey.ComponentsInvoiceFiltersLast30Days,
        Last90Days: LocalizationKey.ComponentsInvoiceFiltersLast90Days,
        Last1Year: LocalizationKey.ComponentsInvoiceFiltersLast1Year,
        Paid: LocalizationKey.ComponentsInvoiceFiltersPaid,
        PartiallyPaid: LocalizationKey.ComponentsInvoiceFiltersPartiallyPaid,
        Unpaid: LocalizationKey.ComponentsInvoiceFiltersUnpaid,
        All: LocalizationKey.ComponentsInvoiceFiltersAll,
        Venue: LocalizationKey.ComponentsInvoiceFiltersVenue,
        DueDate: LocalizationKey.ComponentsInvoiceFiltersDueDate,
        EventDate: LocalizationKey.ComponentsInvoiceFiltersEventDate,
        Search: LocalizationKey.ComponentsInvoiceFiltersSearch,
        SearchPlaceholder:
          LocalizationKey.ComponentsInvoiceFiltersSearchPlaceholder,
      },
      Table: {
        Reference: LocalizationKey.ComponentsInvoiceTableReference,
        Supplier: LocalizationKey.ComponentsInvoiceTableSupplier,
        Amount: LocalizationKey.ComponentsInvoiceTableAmount,
        DueDate: LocalizationKey.ComponentsInvoiceTableDueDate,
        Event: LocalizationKey.ComponentsInvoiceTableEvent,
        LinkedTo: LocalizationKey.ComponentsInvoiceTableLinkedTo,
        AddPayment: LocalizationKey.ComponentsInvoiceTableAddPayment,
      },
    },
    Notification: {
      Dropdown: {
        Title: LocalizationKey.ComponentsNotificationDropdownTitle,
        Description1:
          LocalizationKey.ComponentsNotificationDropdownDescription1,
        Description2:
          LocalizationKey.ComponentsNotificationDropdownDescription2,
        Accept: LocalizationKey.ComponentsNotificationDropdownAccept,
        Error: LocalizationKey.ComponentsNotificationDropdownError,
        EndMesssage: LocalizationKey.ComponentsNotificationDropdownEndMesssage,
        Read: LocalizationKey.ComponentsNotificationDropdownRead,
      },
      MarkAllAsRead: LocalizationKey.ComponentsNotificationMarkAllAsRead,
    },
    Organization: {
      BillingsInfos: {
        Manage: LocalizationKey.ComponentsOrganizationBillingInfosManage,
      },
      Plan: {
        YourOrganization:
          LocalizationKey.ComponentsOrganizationPlanYourOrganization,
        Users: LocalizationKey.ComponentsOrganizationPlanUsers,
        PricePerUser: LocalizationKey.ComponentsOrganizationPlanPricePerUser,
        TotalPrice: LocalizationKey.ComponentsOrganizationPlanTotalPrice,
        ToEditGoToPortal:
          LocalizationKey.ComponentsOrganizationPlanToEditGoToPortal,
        MoveTheCursor: LocalizationKey.ComponentsOrganizationPlanMoveTheCursor,
        TrialsEndsOn: LocalizationKey.ComponentsOrganizationPlanTrialsEndsOn,
        TrialsEndsOnDescription:
          LocalizationKey.ComponentsOrganizationPlanTrialsEndsOnDescription,
        NoDefaultPaymentMethod:
          LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethod,
        NoDefaultPaymentMethodDescription:
          LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethodDescription,
        Reset: LocalizationKey.ComponentsOrganizationPlanReset,
      },
      Roles: {
        Permissions: LocalizationKey.ComponentsOrganizationRolesPermissions,
        Marketing: LocalizationKey.ComponentsOrganizationRolesMarketing,
        Visitor: LocalizationKey.ComponentsOrganizationRolesVisitor,
        ProductionManager:
          LocalizationKey.ComponentsOrganizationRolesProductionManager,
        financialManager:
          LocalizationKey.ComponentsOrganizationRolesfinancialManager,
        GeneralManager:
          LocalizationKey.ComponentsOrganizationRolesGeneralManager,
        Admin: LocalizationKey.ComponentsOrganizationRolesAdmin,
      },
    },
    Payment: {
      List: {
        AddPayment: LocalizationKey.ComponentsPaymentListAddPayment,
        AddFirstPayment: LocalizationKey.ComponentsPaymentListAddFirstPayment,
      },
    },
    Travel: {
      Searcher: {
        NoTravelFound: LocalizationKey.ComponentsTravelSearcherNoTravelFound,
        Error: LocalizationKey.ComponentsTravelSearcherError,
        FillBeforeSearching:
          LocalizationKey.ComponentsTravelSearcherFillBeforeSearching,
        CreateManually: LocalizationKey.ComponentsTravelSearcherCreateManually,
        Search: LocalizationKey.ComponentsTravelSearcherSearch,
        Select: LocalizationKey.ComponentsTravelSearcherSelect,
        Change: LocalizationKey.ComponentsTravelSearcherChange,
        Validate: LocalizationKey.ComponentsTravelSearcherValidate,
      },
    },
    User: {
      AssociateContact: {
        Description: LocalizationKey.ComponentsUserAssociateContactDescription,
        ProfileLabel: LocalizationKey.ComponentsUserAssociateContactPofileLabel,
        Description2:
          LocalizationKey.ComponentsUserAssociateContactDescription2,
        SearchPlaceholder:
          LocalizationKey.ComponentsUserAssociateContactSearchPlaceholder,
        Associate: LocalizationKey.ComponentsUserAssociateContactAssociate,
      },
      Avatar: {
        DeletedUser: LocalizationKey.ComponentsUserAvatarDeletedUser,
      },
    },
    Reporting: {
      Wizard: {
        Step1: {
          NoItemsDescription:
            LocalizationKey.ComponentsReportingWizardStep1NoItemsDescription,
          NoItemsTitle:
            LocalizationKey.ComponentsReportingWizardStep1NoItemsTitle,
          NoItemsGoToSettings:
            LocalizationKey.ComponentsReportingWizardStep1NoItemsGoToSettings,
          Costs: LocalizationKey.ComponentsReportingWizardStep1Costs,
          Revenues: LocalizationKey.ComponentsReportingWizardStep1Revenues,
        },
        Error: LocalizationKey.ComponentsReportingWizardError,
        AtLeast1Filled: LocalizationKey.ComponentsReportingWizardAtLeast1Filled,
        Preview: LocalizationKey.ComponentsReportingWizardPreview,
        Misc: LocalizationKey.ComponentsReportingWizardMisc,
        CostsAndRevenues:
          LocalizationKey.ComponentsReportingWizardCostsAndRevenues,
      },
      EventAlert: {
        Description: LocalizationKey.ComponentsReportingEventAlertDescription,
        Title: LocalizationKey.ComponentsReportingEventAlertTitle,
        Button: LocalizationKey.ComponentsReportingEventAlertButton,
      },
      Overview: {
        Margin: LocalizationKey.ComponentsReportingOverviewMargin,
        Attendees: LocalizationKey.ComponentsReportingOverviewAttendees,
        Balance: LocalizationKey.ComponentsReportingOverviewBalance,
        Costs: LocalizationKey.ComponentsReportingOverviewCosts,
        Revenues: LocalizationKey.ComponentsReportingOverviewRevenues,
      },
      Chart: {
        Evolution: LocalizationKey.ComponentsReportingChartEvolution,
      },
    },
    Weather: {
      Title: LocalizationKey.ComponentsWeatherCardTitle,
      Temp: LocalizationKey.ComponentsWeatherCardTemp,
      Temperature: LocalizationKey.ComponentsWeatherCardTemperature,
      Humm: LocalizationKey.ComponentsWeatherCardHumm,
      Wind: LocalizationKey.ComponentsWeatherCardWind,
      Rain: LocalizationKey.ComponentsWeatherCardRain,
    },
    CompleteYourProfile: LocalizationKey.ComponentsCompleteYourProfile,
  },
  Misc: Misc,
  Entity: Entity,
  Help: {
    Article: {
      AddAndManageHotelRooms: LocalizationKey.HelpArticleAddAndManageHotelRooms,
      ManageEventStatusAndPermissions:
        LocalizationKey.HelpArticleManageEventStatusAndPermissions,
      AddAndManageGroundTransfers:
        LocalizationKey.HelpArticleAddAndManageGroundTransfers,
      AddAndManageTravels: LocalizationKey.HelpArticleAddAndManageTravels,
      CreateAVenue: LocalizationKey.HelpArticleCreateAVenue,
      AddAndManagePartyCrew: LocalizationKey.HelpArticleAddAndManagePartyCrew,
      CreateAndManageYourEvent:
        LocalizationKey.HelpArticleCreateAndManageYourEvent,
      AddAndManageTimetable: LocalizationKey.HelpArticleAddAndManageTimetable,
      ShareAFile: LocalizationKey.HelpArticleShareAFile,
      AddAndManageTechnicalSheets:
        LocalizationKey.HelpArticleAddAndManageTechnicalSheets,
      ManageTeamAndPermissions:
        LocalizationKey.HelpArticleManageTeamAndPermissions,
      AddAndManageContact: LocalizationKey.HelpArticleAddAndManageContact,
      AddContractOnEvent: LocalizationKey.HelpArticleAddContractOnEvent,
      AddAndManageHotels: LocalizationKey.HelpArticleAddAndManageHotels,
      AddAndManageLineUp: LocalizationKey.HelpArticleAddAndManageLineUp,
      HowToAddAnInvoice: LocalizationKey.HelpArticleHowToAddAnInvoice,
      HowToMarkAnInvoiceAsPaid:
        LocalizationKey.HelpArticleHowToMarkAnInvoiceAsPaid,
      HowToGenerateRoadsheet: LocalizationKey.HelpArticleHowToGenerateRoadsheet,
    },
    Alert: {
      Title: LocalizationKey.HelpAlertTitle,
      Description: LocalizationKey.HelpAlertDescription,
      Button: LocalizationKey.HelpAlertButton,
    },
  },
}

export { LocalizationKey }
export default LocalizationKeys
