import { selectAllExternalAdvancingLinks } from '../reducers/ExternalAdvancingLinkReducer'
import dayjs from 'dayjs'
import { HomeOutlined } from '@ant-design/icons'
import { Card, Modal, Space, Tag, Typography } from 'antd'
import { Venue } from './venue/Venue'
import { Artist } from './artist/Artist'
import { useAppSelector } from '../reducers/hooks'
import { EventWithBookings } from '../models/types'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const { Text, Title } = Typography

interface Props {
  event: EventWithBookings
}

const EventBookingCell = ({ event }: Props) => {
  const links = useAppSelector(selectAllExternalAdvancingLinks())
  const [bookingsModalIsOpen, setBookingsModalIsOpen] = useState<boolean>(false)

  const linkForBooking = (bookingId: number) =>
    links.find((link) => link.booking.id === bookingId)

  const onCardClick = () => {
    if (event.bookings.length === 1) return
    setBookingsModalIsOpen(true)
  }

  return (
    <>
      <ConditionalLinkWrapper
        condition={event.bookings.length === 1}
        to={linkForBooking(event.bookings[0].id)!.url}
      >
        <Card
          onClick={onCardClick}
          cover={
            <div
              className="cover-center"
              style={{
                height: 180,
                width: '100%',
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                background: `url(${event.event.header_picture})`,
              }}
            >
              {event.bookings.map((booking) => (
                <Tag
                  key={booking.id}
                  style={{ margin: 10, padding: '5px 10px' }}
                >
                  <Artist.Avatar
                    size={'small'}
                    model={booking.artist}
                    linkToDetails={false}
                  />
                </Tag>
              ))}
            </div>
          }
        >
          <Space size={15}>
            <Space
              size={0}
              direction="vertical"
              style={{ textAlign: 'center' }}
            >
              <Title level={5} style={{ margin: 0 }}>
                {dayjs(event.event.start_date).format('DD')}
              </Title>
              <Text>{dayjs(event.event.start_date).format('MMM')}</Text>
            </Space>

            <Space size={2} direction="vertical" wrap>
              <Title level={5} style={{ margin: 0 }}>
                {event.event.name}
              </Title>
              <Space size={2}>
                <HomeOutlined style={{ marginRight: 5 }} />
                <Space direction="vertical">
                  {event.event?.venues?.map((venue) => (
                    <Venue.Tag
                      model={venue}
                      key={venue.id}
                      linkToDetails={false}
                    />
                  ))}
                </Space>
              </Space>
            </Space>
          </Space>
        </Card>
      </ConditionalLinkWrapper>
      <Modal
        open={bookingsModalIsOpen}
        footer={<></>}
        onCancel={() => setBookingsModalIsOpen(false)}
      >
        <Title level={3}>{event.event.name}</Title>
        {event.bookings.map((booking) => (
          <Link key={booking.id} to={linkForBooking(booking.id)!.url}>
            <Tag style={{ margin: 10, padding: '5px 10px' }}>
              <Artist.Avatar
                size={'large'}
                model={booking.artist}
                linkToDetails={false}
              />
            </Tag>
          </Link>
        ))}
      </Modal>
    </>
  )
}

export default EventBookingCell
