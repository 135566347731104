import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Booking } from '../models/Booking'
import { BOOKINGS_URL } from '../utils/urls'
import { bookingSchema } from '../models/schema'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState: SBState<Booking> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getBookingsSuccess,
  setQuery: setBookingQuery,
  reset: resetBookingState,
  resetQueryAndIds: resetBookingQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Booking }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectBookings = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectAllBookings = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )

export const selectSelectedBooking = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectBookingById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectBookingsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedBooking =
  (booking: Booking | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Booking>(booking)))

export const getBookings = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Booking>(
    BOOKINGS_URL,
    params,
    [bookingSchema],
    slice.actions
  )

export const getBookingWithId = (id: number) =>
  SBAPIFetchDispatch<Booking>(
    `${BOOKINGS_URL}/${id}`,
    bookingSchema,
    slice.actions
  )

export const createBooking = (booking: Booking) =>
  SBAPICreate<Booking>(booking, BOOKINGS_URL, bookingSchema, slice.actions)

export const updateBooking = (booking: Booking) =>
  SBAPIUpdate<Booking>(booking, `${BOOKINGS_URL}/${booking.id}`, slice.actions)

export const deleteBooking = (booking: Booking) =>
  SBAPIDelete<Booking>(booking, `${BOOKINGS_URL}/${booking.id}`, slice.actions)

export const importContactsFromArtist = (booking: Booking) =>
  SBAPICreate<Booking>(
    booking,
    `${BOOKINGS_URL}/${booking.id}/contacts/import`,
    bookingSchema,
    slice.actions
  )
