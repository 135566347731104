import { useState } from 'react'
import { Alert, Form } from 'antd'
import { useIntl } from 'react-intl'
import { requiredRule } from '../../../utils/rules'
import { File } from '../../../components/file/File'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBRMFormStepProps } from '../../../modules/sbrm/SBRMModel'

interface Props extends SBRMFormStepProps {}

const Create = ({ form, isNested }: Props) => {
  const intl = useIntl()

  const [fileSizeWarning, setFileSizeWarning] = useState<boolean>(false)

  // 10 Mb
  const maxFileSize = 10 * 1024 * 1024

  const valuesChanged = () => {
    const selectedFile = form.getFieldValue('file')

    if (selectedFile?.file.size >= maxFileSize) {
      setFileSizeWarning(true)
      return false
    }
    setFileSizeWarning(false)
    return true
  }

  return (
    <Form form={form} layout="vertical" onValuesChange={() => valuesChanged()}>
      <File.Dropzone
        multiple={false}
        name="file"
        rules={[requiredRule(intl)]}
      />

      {fileSizeWarning && (
        <Alert
          type="warning"
          message={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Invoice.FileSizeWarning,
          })}
          showIcon
          style={{ marginTop: 10 }}
        />
      )}
    </Form>
  )
}

export default Create
