import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Performance } from '../models/Performance'
import { PERFORMANCES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { performanceSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { getExternalAdvancingSectionData } from './ExternalAdvancingLinkReducer'

const initialState: SBState<Performance> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'performance',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getPerformancesSuccess,
  setQuery: setPerformanceQuery,
  reset: resetPerformanceState,
  resetQueryAndIds: resetPerformanceQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Performance }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectPerformances = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedPerformance = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectPerformanceById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectPerformancesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedPerformance =
  (performance: Performance | number | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        performance ? getIdOrModelId<Performance>(performance) : undefined
      )
    )

export const getPerformances = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Performance>(
    PERFORMANCES_URL,
    params,
    [performanceSchema],
    slice.actions
  )

export const getPerformanceWithId = (id: number) =>
  SBAPIFetchDispatch<Performance>(
    `${PERFORMANCES_URL}/${id}`,
    performanceSchema,
    slice.actions
  )

export const createPerformance = (performance: Performance) =>
  SBAPICreate<Performance>(
    performance,
    PERFORMANCES_URL,
    performanceSchema,
    slice.actions
  )

export const updatePerformance = (performance: Performance) =>
  SBAPIUpdate<Performance>(
    performance,
    `${PERFORMANCES_URL}/${performance.id}`,
    slice.actions
  )

export const deletePerformance = (performance: Performance) =>
  SBAPIDelete<Performance>(
    performance,
    `${PERFORMANCES_URL}/${performance.id}`,
    slice.actions
  )

export const getTimetableSectionData = (params: TableParams) =>
  getExternalAdvancingSectionData(
    'timetable',
    params,
    [performanceSchema],
    slice.actions
  )
