import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import MainHeader from './components/MainHeader'

const { Content } = Layout

interface MainLayoutProps {}

export const MainLayout = ({}: MainLayoutProps) => {
  return (
    <Layout>
      <MainHeader />
      <Layout style={{ minHeight: '100vh', marginTop: 64 }}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
