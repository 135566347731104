export enum MetaDataKey {
  eventId,
  stageId,
  venueId,
  supplierId,
  venuesId,
  artistId,
  bookingId,
  contractStatusId,
  selectedDate,
  selectedFromDateTime,
  selectedToDateTime,
  fileRelation,
  stationType,
  contactAssociationType,
  contactId,
  fileParentType,
  fileParentId,
  invoiceId,
  deleteWithConfirmation,
  taskParentType,
  taskParentId,
}
export interface MetaData {
  key: MetaDataKey
  value: any | null | undefined
}
