import { DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { File } from '../../../models/File'
import { useEffect, useState } from 'react'
import { Button, Divider, Segmented, Space } from 'antd'
import { getFile } from '../helpers/FileHelper'
import { SBRMAction, SBRMType } from '../../sbrm/SBRMModel'
import { SBRMTypeInfos } from '../../sbrm/SBRMTypeInfos'
import { Link } from 'react-router-dom'
import { setMainAction } from '../../../reducers/SBRMReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import {
  ALMSectionOption,
  externalAdvancingSections,
} from '../../../models/types'

interface Props {
  entity: SBRMType
  handleDownloadCliked?: () => void
  handleShareCliked?: () => void
}

const Toolbar = ({ entity }: Props) => {
  const dispatch = useAppDispatch()
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)

  // Get selected resource based on the entity
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[resourceId!]
  )
  const { items: files } = useAppSelector((state) => state.file)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())
  const { isOpen: SBRMisOpen, action } = useAppSelector((state) => state.SBRM)

  const aclForType = (type: SBRMType): ALMSectionOption => {
    if (link === undefined) return 'hidden'
    const section = externalAdvancingSections.find((s) => s.SBRMType === type)
    if (section === undefined) return 'hidden'
    return link[section.propertyName] as ALMSectionOption
  }

  useEffect(() => {
    if (!SBRMisOpen || !resource) return // When closing SBRM do not set the resource
    setResourceFile(getFile(entity, resource, files))
  }, [SBRMisOpen, resource])

  useEffect(() => {
    /**
     * Reset the selected resource when the Drawer is closed
     */
    if (!SBRMisOpen) {
      setResourceFile(undefined)
    }
  }, [SBRMisOpen])

  return (
    <Space>
      {resourceFile && (
        <Link to={resourceFile.downloadUrl}>
          <Button icon={<DownloadOutlined />} />
        </Link>
      )}
      <Divider type="vertical" />
      <Segmented
        value={action}
        size="middle"
        options={
          // Exception as they cannot be edited, but new ones can be uploaded
          aclForType(entity) === 'edit' &&
          ![SBRMType.contract, SBRMType.invoice, SBRMType.rider].includes(
            entity
          )
            ? [
                { value: 'view', label: <EyeOutlined /> },
                { value: 'update', label: <EditOutlined /> },
              ]
            : []
        }
        onChange={(value) =>
          dispatch(
            setMainAction({
              action: value as SBRMAction,
            })
          )
        }
      />
    </Space>
  )
}

export default Toolbar
