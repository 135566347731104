import { useRef } from 'react'
import { SBRMFormInterface, SBRMType } from './SBRMModel'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMState } from '../../reducers/SBRMReducer'

// Form imports
import RoomForm from './room/RoomForm'
import RiderForm from './rider/RiderForm'
import { Contact } from '../../components/contact/Contact'
import { ContactAssociation } from '../../components/contact-association/ContactAssociation'
import { Contract } from '../../components/contract/Contract'
import { File } from '../../components/file/File'
import { Invoice } from '../../components/invoice/Invoice'
import { Ground } from '../../components/ground/Ground'
import { Travel } from '../../components/travel/Travel'

type useSBRMformsProps = {
  isNested: boolean
}

const useSBRMforms = ({
  isNested,
}: useSBRMformsProps): {
  refs: Record<SBRMType, React.RefObject<SBRMFormInterface>>
  forms: Record<SBRMType, JSX.Element>
} => {
  const SBRMrefs = {
    [SBRMType.artist]: useRef<SBRMFormInterface>(null),
    [SBRMType.venue]: useRef<SBRMFormInterface>(null),
    [SBRMType.venueType]: useRef<SBRMFormInterface>(null),
    [SBRMType.stage]: useRef<SBRMFormInterface>(null),
    [SBRMType.event]: useRef<SBRMFormInterface>(null),
    [SBRMType.eventStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.booking]: useRef<SBRMFormInterface>(null),
    [SBRMType.bookingType]: useRef<SBRMFormInterface>(null),
    [SBRMType.contract]: useRef<SBRMFormInterface>(null),
    [SBRMType.contractStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.invoice]: useRef<SBRMFormInterface>(null),
    [SBRMType.payment]: useRef<SBRMFormInterface>(null),
    [SBRMType.hotel]: useRef<SBRMFormInterface>(null),
    [SBRMType.room]: useRef<SBRMFormInterface>(null),
    [SBRMType.rider]: useRef<SBRMFormInterface>(null),
    [SBRMType.riderStatus]: useRef<SBRMFormInterface>(null),
    [SBRMType.file]: useRef<SBRMFormInterface>(null),
    [SBRMType.user]: useRef<SBRMFormInterface>(null),
    [SBRMType.contact]: useRef<SBRMFormInterface>(null),
    [SBRMType.contactType]: useRef<SBRMFormInterface>(null),
    [SBRMType.contactAssociation]: useRef<SBRMFormInterface>(null),
    [SBRMType.travel]: useRef<SBRMFormInterface>(null),
    [SBRMType.travelType]: useRef<SBRMFormInterface>(null),
    [SBRMType.ground]: useRef<SBRMFormInterface>(null),
    [SBRMType.performance]: useRef<SBRMFormInterface>(null),
    [SBRMType.station]: useRef<SBRMFormInterface>(null),
    [SBRMType.stationType]: useRef<SBRMFormInterface>(null),
    [SBRMType.customAddress]: useRef<SBRMFormInterface>(null),
    [SBRMType.notification]: useRef<SBRMFormInterface>(null),
    [SBRMType.externalAdvancingLink]: useRef<SBRMFormInterface>(null),
    [SBRMType.currency]: useRef<SBRMFormInterface>(null),
  }

  const SBRMforms = {
    [SBRMType.artist]: <></>,
    [SBRMType.venue]: <></>,
    [SBRMType.venueType]: <></>,
    [SBRMType.stage]: <></>,
    [SBRMType.event]: <></>,
    [SBRMType.eventStatus]: <></>,
    [SBRMType.booking]: <></>,
    [SBRMType.bookingType]: <></>,
    [SBRMType.hotel]: <></>,
    [SBRMType.room]: (
      <RoomForm ref={SBRMrefs[SBRMType.room]} isNested={isNested} />
    ),
    [SBRMType.contact]: (
      <Contact.Form ref={SBRMrefs[SBRMType.contact]} isNested={isNested} />
    ),
    [SBRMType.contactType]: <></>,
    [SBRMType.contactAssociation]: (
      <ContactAssociation.Form
        ref={SBRMrefs[SBRMType.contactAssociation]}
        isNested={isNested}
      />
    ),
    [SBRMType.contract]: (
      <Contract.Form ref={SBRMrefs[SBRMType.contract]} isNested={isNested} />
    ),
    [SBRMType.contractStatus]: <></>,
    [SBRMType.invoice]: (
      <Invoice.Form ref={SBRMrefs[SBRMType.invoice]} isNested={isNested} />
    ),
    [SBRMType.payment]: <></>,
    [SBRMType.rider]: (
      <RiderForm ref={SBRMrefs[SBRMType.rider]} isNested={isNested} />
    ),
    [SBRMType.riderStatus]: <></>,
    [SBRMType.file]: <></>,
    [SBRMType.user]: <></>,
    [SBRMType.travel]: (
      <Travel.Form ref={SBRMrefs[SBRMType.travel]} isNested={isNested} />
    ),
    [SBRMType.travelType]: <></>,
    [SBRMType.ground]: (
      <Ground.Form ref={SBRMrefs[SBRMType.ground]} isNested={isNested} />
    ),
    [SBRMType.performance]: <></>,
    [SBRMType.station]: <></>,
    [SBRMType.stationType]: <></>,
    [SBRMType.customAddress]: <></>,
    [SBRMType.notification]: <></>,
    [SBRMType.externalAdvancingLink]: <></>,
    [SBRMType.currency]: <></>,
  }
  return { refs: SBRMrefs, forms: SBRMforms }
}

const useSBRMData = (isNested: boolean): SBRMState => {
  const {
    isOpen: SBRMisOpen,
    entity: SBRMentity,
    action: SBRMaction,
    metadata: SBRMmetadata,
  } = useAppSelector((state) => state.SBRM)
  const {
    isOpen: SBRMnestedIsOpen,
    entity: SBRMnestedEntity,
    action: SBRMnestedAction,
    metadata: SBRMnestedMetadata,
  } = useAppSelector((state) => state.SBRM.nested)

  return {
    isOpen: isNested ? SBRMnestedIsOpen : SBRMisOpen,
    entity: isNested ? SBRMnestedEntity : SBRMentity,
    action: isNested ? SBRMnestedAction : SBRMaction,
    metadata: SBRMmetadata,
  }
}

export { useSBRMforms, useSBRMData }
