import { useAuth } from '../auth/Auth'

const useTenantSettings = () => {
  const auth = useAuth()

  const currencySymbol = getCurrencySymbol(auth.tenantSettings.currency)
  const tempSymbol = getTempSymbol(auth.tenantSettings.measurementUnit)
  const windSymbol = getWindSymbol(auth.tenantSettings.measurementUnit)

  return {
    currencySymbol,
    tempSymbol,
    windSymbol,
    locale: auth.tenantSettings.locale,
  }
}

const getCurrencySymbol = (currency: string | undefined) => {
  switch (currency) {
    case 'eur':
      return '€'
    case 'usd':
      return '$'

    default:
      return '$'
  }
}

const getTempSymbol = (measurementUnit: string | undefined) => {
  if (measurementUnit === 'metric') {
    return '°C'
  }
  return '°F'
}

const getWindSymbol = (measurementUnit: string | undefined) => {
  if (measurementUnit === 'metric') {
    return 'meter/sec'
  }
  return 'miles/hour'
}

export { useTenantSettings }
