import React, { PropsWithChildren } from 'react'
import { Approvable } from '../utils/helpers/crud/models'
import { Card, Tooltip } from 'antd'

interface ApprovableItemCardProps {
  model: Approvable
}

const ApprovableItemCard: React.FC<
  PropsWithChildren<ApprovableItemCardProps>
> = ({ model, children }) => {
  return (
    <Card
      styles={{ body: { padding: 10 } }}
      className="w-full"
      style={
        model.isApproved
          ? {}
          : {
              background: '#2b1d11',
              borderColor: '#593815',
              borderStyle: 'dashed',
            }
      }
    >
      {children}
    </Card>
  )
}

export default ApprovableItemCard
