import { useAppSelector } from '../../reducers/hooks'
import { Affix, Alert, Anchor, Button, Card, Space, Typography } from 'antd'
import { Artist } from '../../components/artist/Artist'
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor'
import { Contact } from '../../components/contact/Contact'
import {
  ALMSection,
  ALMSectionKey,
  externalAdvancingSections,
} from '../../models/types'
import { MessageOutlined } from '@ant-design/icons'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import RoadsheetGenerator from '../../modules/roadsheet/RoadsheetGenerator'
import { deadlineIsReached } from '../../utils/helpers/LinkHelper'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {}

export const ExternalAdvanceSider = ({}: Props) => {
  const intl = useIntl()
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  if (link === undefined) return <></>

  const menuItems = externalAdvancingSections
    .map((section: ALMSection) =>
      link[section.propertyName] !== 'hidden'
        ? {
            key: section.key,
            href: '#' + section.key,
            title: `${section.icon} ${intl.formatMessage({
              id: LocalizationKeys.ExternalAdvancing[section.key],
            })}`,
          }
        : undefined
    )
    .filter((e) => e !== undefined) as AnchorLinkItemProps[]

  return (
    <Affix offsetTop={120}>
      <Space direction="vertical" size={16} className="w-full">
        <Card
          title={intl.formatMessage({
            id: LocalizationKeys.Page.ExernalAdvancing.YourArtist,
          })}
          bordered={false}
          styles={{ body: { paddingTop: 0 } }}
        >
          <Space>
            <Artist.Avatar model={link.booking.artist} linkToDetails={false} />
          </Space>
        </Card>
        <Anchor
          affix={false}
          style={{
            margin: 0,
            background: '#1E1E1E',
            borderRadius: 8,
            padding: 24,
          }}
          items={menuItems}
          targetOffset={120}
          offsetTop={120}
          showInkInFixed={true}
        />
        <Space direction="vertical" className="w-full" size={16}>
          <Card
            title={intl.formatMessage({
              id: LocalizationKeys.Page.ExernalAdvancing.YourContact,
            })}
            bordered={false}
            styles={{ body: { paddingTop: 0 } }}
          >
            <Contact.Avatar
              model={{
                id: 0,
                isLinkedToUser: false,
                associations: [],
                ...link.owner,
              }}
              linkToDetails={false}
            />
            <Actions
              actions={['comment']}
              entity={SBRMType.externalAdvancingLink}
              entityId={link.id}
              section={ALMSectionKey.deal}
              trigger={
                <Button
                  type="default"
                  className="w-full"
                  style={{ flex: '1 1', marginTop: 20 }}
                  icon={<MessageOutlined />}
                >
                  {intl.formatMessage({
                    id: LocalizationKeys.Page.ExernalAdvancing.OpenChat,
                  })}
                </Button>
              }
            />
          </Card>
          <RoadsheetGenerator />
          {!deadlineIsReached(link) && (
            <Text type="secondary" italic>
              {intl.formatMessage(
                { id: LocalizationKeys.Page.ExernalAdvancing.YouCanEditUntill },
                {
                  date: dayjs(link.deadline).format('DD/MM/YYYY HH:mm'),
                  fromNow: dayjs(link.deadline).fromNow(),
                }
              )}
            </Text>
          )}
          {deadlineIsReached(link) && (
            <Alert
              type="warning"
              message={intl.formatMessage({
                id: LocalizationKeys.Page.ExernalAdvancing.DeadlineReadOnly,
              })}
              description={intl.formatMessage({
                id: LocalizationKeys.Page.ExernalAdvancing.DeadlineDescription,
              })}
            />
          )}
        </Space>
      </Space>
    </Affix>
  )
}
