import { Booking as UIKitBooking } from '@supplement-bacon/alela-uikit'
import { BookingWithRelation } from '../../models/Booking'

interface Props {
  model: BookingWithRelation
}

export const BookingAmountsTag = ({ model }: Props) => {
  const booking = model
  const currency = booking.currency

  if (booking === undefined || currency === undefined) return <></>

  return (
    <UIKitBooking.AmountsTag booking={booking as any} currency={currency} />
  )
}

export type BookingAmountsTagType = { AmountsTag: typeof BookingAmountsTag }
