import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Venue } from '../models/Venue'
import { VENUES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { venueSchema } from '../models/schema'

const initialState: SBState<Venue> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'venue',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getVenuesSuccess,
  setQuery: setVenueQuery,
  reset: resetVenueState,
  resetQueryAndIds: resetVenueQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Venue }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectVenues = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectAllVenues = () =>
  createSelector([selectRawItems, selectRawIds], (items, ids) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedVenue = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectVenueById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectVenuesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedVenue =
  (venue: Venue | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Venue>(venue)))

export const getVenues = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Venue>(
    VENUES_URL,
    params,
    [venueSchema],
    slice.actions
  )

export const getVenueWithId = (id: number) =>
  SBAPIFetchDispatch<Venue>(`${VENUES_URL}/${id}`, venueSchema, slice.actions)

export const restoreVenueWithId = (venue: Venue) =>
  SBAPIUpdate<Venue>(venue, `${VENUES_URL}/${venue.id}/restore`, slice.actions)

export const createVenue = (venue: Venue) =>
  SBAPICreate<Venue>(venue, VENUES_URL, venueSchema, slice.actions)

export const updateVenue = (venue: Venue) =>
  SBAPIUpdate<Venue>(venue, `${VENUES_URL}/${venue.id}`, slice.actions)

export const deleteVenue = (venue: Venue) =>
  SBAPIDelete<Venue>(venue, `${VENUES_URL}/${venue.id}`, slice.actions)
