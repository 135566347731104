import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Invoice } from '../models/Invoice'
import { INVOICES_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { invoiceSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { getExternalAdvancingSectionData } from './ExternalAdvancingLinkReducer'

const initialState: SBState<Invoice> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getInvoicesSuccess,
  setQuery: setInvoiceQuery,
  reset: resetInvoiceState,
  resetQueryAndIds: resetInvoiceQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Invoice }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectInvoices = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedInvoice = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectInvoiceById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectInvoicesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedInvoice =
  (invoice: Invoice | number | undefined) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        invoice ? getIdOrModelId<Invoice>(invoice) : undefined
      )
    )

export const getInvoices = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Invoice>(
    INVOICES_URL,
    params,
    [invoiceSchema],
    slice.actions
  )

export const getInvoiceWithId = (id: number) =>
  SBAPIFetchDispatch<Invoice>(
    `${INVOICES_URL}/${id}`,
    invoiceSchema,
    slice.actions
  )

export const createInvoice = (invoice: Invoice | FormData) =>
  SBAPICreate<Invoice | FormData>(
    invoice,
    INVOICES_URL,
    invoiceSchema,
    slice.actions
  )

export const updateInvoice = (invoice: Invoice) =>
  SBAPIUpdate<Invoice>(invoice, `${INVOICES_URL}/${invoice.id}`, slice.actions)

export const updateInvoiceFormData = (invoice: FormData) =>
  SBAPICreate<FormData>(
    invoice,
    `${INVOICES_URL}/${invoice.get('id')}`,
    invoiceSchema,
    slice.actions
  )

export const deleteInvoice = (invoice: Invoice) =>
  SBAPIDelete<Invoice>(invoice, `${INVOICES_URL}/${invoice.id}`, slice.actions)

export const getExternalAdvancingInvoiceSectionData = (params: TableParams) =>
  getExternalAdvancingSectionData(
    'invoices',
    params,
    [invoiceSchema],
    slice.actions
  )
