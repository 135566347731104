import { useAppSelector } from '../../reducers/hooks'
import { selectBookingById } from '../../reducers/BookingReducer'
import { Artist } from '../artist/Artist'

interface Props {
  id: number
}

/**
 * BookingAvatar is just a wrapper around Artist.Avatar
 */
export const BookingAvatar = ({ id }: Props) => {
  const booking = useAppSelector(selectBookingById(id))

  return booking && booking.artist ? (
    <Artist.Avatar id={booking.artist} />
  ) : (
    <></>
  )
}

export type BookingAvatarType = { Avatar: typeof BookingAvatar }
