import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { SBRMTypeInfos } from '../../modules/sbrm/SBRMTypeInfos'
import {
  getComments,
  resetCommentQueryAndIds,
  setCommentQuery,
} from '../../reducers/CommentReducer'
import { usePageVisibility } from '../usePageVisibility'
import { getAccountUsers, setUserQuery } from '../../reducers/UserReducer'

type CommentContextProps = {
  fetchComments: () => void
}

const initCommentContextPropsState: CommentContextProps = {
  fetchComments: () => {},
}

const CommentContext = createContext<CommentContextProps>(
  initCommentContextPropsState
)

const useComments = () => useContext(CommentContext)

interface Props {
  entity: SBRMType
}
const CommentContextProvider = ({
  children,
  entity,
}: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch()
  const isPageVisible = usePageVisibility()
  const timeIdRef = useRef<NodeJS.Timer | null>(null)

  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[resourceId!]
  )
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isPollingEnabled, setIsPollingEnabled] = useState<boolean>(true)

  const baseQuery: TableParams = {
    ...initialQuery,
    pagination: { current: 1, pageSize: 1000 },
    filters: { [entity]: [resourceId ?? 0] },
    columnKey: 'date',
    order: 'ascend',
  }

  const fetchComments = () => {
    dispatch(setCommentQuery(baseQuery))
    dispatch(getComments(baseQuery))
  }

  useEffect(() => {
    // const query = { ...initialQuery, pagination: { current: 1, pageSize: 100 } }
    // dispatch(setUserQuery(query))
    // dispatch(getAccountUsers(query))
  }, [dispatch])

  useEffect(() => {
    const pollingCallback = () => {
      fetchComments()
    }

    const startPolling = () => {
      pollingCallback()
      // Polling every 5 seconds
      timeIdRef.current = setInterval(pollingCallback, 5000)
    }

    const stopPolling = () => {
      if (!timeIdRef.current) {
        return
      }
      clearInterval(timeIdRef.current as NodeJS.Timeout)
    }

    if (
      isPageVisible &&
      isPollingEnabled &&
      SBRMIsOpen &&
      resource !== undefined
    ) {
      startPolling()
    } else {
      stopPolling()
    }

    if (!SBRMIsOpen) {
      // When SBRM is closing, clear notifications store
      dispatch(resetCommentQueryAndIds(initialQuery))
    }

    return () => {
      stopPolling()
    }
  }, [isPageVisible, isPollingEnabled, SBRMIsOpen, resource])

  return (
    <CommentContext.Provider value={{ fetchComments }}>
      {children}
    </CommentContext.Provider>
  )
}

export { CommentContextProvider, useComments }
