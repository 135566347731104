import { Form } from 'antd'
import { useIntl } from 'react-intl'
import Dragger from 'antd/es/upload/Dragger'
import { SBRMFormStepProps } from '../SBRMModel'
import { requiredRule, stringRule } from '../../../utils/rules'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import TextArea from 'antd/es/input/TextArea'

interface Props extends SBRMFormStepProps {}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="file" rules={[requiredRule(intl)]}>
        <Dragger
          accept="*"
          maxCount={1}
          multiple={false}
          beforeUpload={(file) => {
            return false
          }}
        >
          <p className="ant-upload-drag-icon">
            {intl.formatMessage({
              id: LocalizationKeys.Components.CustomInput.Dropzone,
            })}
          </p>
        </Dragger>
      </Form.Item>
      <Form.Item
        name="notes"
        rules={[stringRule(intl)]}
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
      >
        <TextArea rows={5} />
      </Form.Item>
    </Form>
  )
}

export default Create
