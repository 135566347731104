import { ExternalAdvancingLink } from '../models/ExternalAdvancing'
import { ALMSection } from '../models/types'
import { Space, Typography } from 'antd'
import { Actions } from '../modules/sbrm/components/Actions'
import { MetaDataKey } from '../models/MetaData'
import { deadlineIsReached } from '../utils/helpers/LinkHelper'
import useSectionLimit from '../utils/hooks/useSectionLimit'

const { Text } = Typography

interface SectionToolbarProps {
  section: ALMSection
  link: ExternalAdvancingLink
}

const SectionToolbar = ({ section, link }: SectionToolbarProps) => {
  const { sectionCanBeEdited, sectionHasLimit, currentCount, sectionLimit } =
    useSectionLimit(section.key)

  if (!sectionCanBeEdited || deadlineIsReached(link)) {
    return <></>
  }

  return (
    <Space direction="horizontal">
      {sectionHasLimit && (
        <Text type="secondary" italic>
          {currentCount} / {sectionLimit}
        </Text>
      )}
      <Actions
        actions={['create']}
        entity={section.SBRMType}
        section={section.key}
        metadata={[
          {
            key: MetaDataKey.bookingId,
            value: link.booking.id,
          },
          {
            key: MetaDataKey.eventId,
            value: link.booking.event.id,
          },
        ]}
      />
    </Space>
  )
}
export { SectionToolbar }
