import qs from 'qs'
import axios, { AxiosError } from 'axios'
import { schema, normalize, NormalizedSchema } from 'normalizr'
import { TableParams } from '../../models/TableParams'
import { AppDispatch } from '../../reducers/store'
import { APIResponse, MessageResponse } from '../../models/APIResponse'
import { getArtistsSuccess } from '../../reducers/ArtistReducer'
import { getVenuesSuccess } from '../../reducers/VenueReducer'
import { getStagesSuccess } from '../../reducers/StageReducer'
import { getVenueTypesSuccess } from '../../reducers/VenueTypeReducer'
import { getEventsSuccess } from '../../reducers/EventReducer'
import { getBookingsSuccess } from '../../reducers/BookingReducer'
import { getEventStatusesSuccess } from '../../reducers/EventStatusReducer'
import { getUsersSuccess } from '../../reducers/UserReducer'
import { getBookingTypesSuccess } from '../../reducers/BookingTypeReducer'
import { getHotelsSuccess } from '../../reducers/HotelReducer'
import { getRoomsSuccess } from '../../reducers/RoomReducer'
import { getContactTypesSuccess } from '../../reducers/ContactTypeReducer'
import { getContactsSuccess } from '../../reducers/ContactReducer'
import { getFilesSuccess } from '../../reducers/FileReducer'
import { getFileTypesSuccess } from '../../reducers/FileTypeReducer'
import { getTravelsSuccess } from '../../reducers/TravelReducer'
import { getTravelTypesSuccess } from '../../reducers/TravelTypeReducer'
import { getRidersSuccess } from '../../reducers/RiderReducer'
import { getContractsSuccess } from '../../reducers/ContractReducer'
import { getContractsStatusSuccess } from '../../reducers/ContractStatusReducer'
import { getGroundsSuccess } from '../../reducers/GroundReducer'
import { getInvoicesSuccess } from '../../reducers/InvoiceReducer'
import { getPerformancesSuccess } from '../../reducers/PerformanceReducer'
import { getStationsSuccess } from '../../reducers/StationReducer'
import { getStationTypesSuccess } from '../../reducers/StationTypeReducer'
import { getContactAssociationsSuccess } from '../../reducers/ContactAssociationReducer'
import { getAddressSuccess } from '../../reducers/AddressReducer'
import { getPaymentsSuccess } from '../../reducers/PaymentReducer'
import { getNotificationsSuccess } from '../../reducers/NotificationReducer'
import { getRidersStatusSuccess } from '../../reducers/RiderStatusReducer'
import { getCommentsSuccess } from '../../reducers/CommentReducer'
import { getExternalAdvancingLinksSuccess } from '../../reducers/ExternalAdvancingLinkReducer'
import { getExternalUsersSuccess } from '../../reducers/ExternalUserReducer'
import { getCurrenciesSuccess } from '../../reducers/CurrencyReducer'

export const SBAPIFetchPaginatedDispatch =
  <T>(
    endpoint: string,
    params: TableParams,
    schema: schema.Entity | schema.Entity[],
    actions: any,
    startLoading?: any,
    setIds?: any,
    setPagination?: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading ? startLoading() : actions.startLoading())
    return SBAPIFetchPaginated(endpoint, params)
      .then((data) => {
        const normalizedData = normalize(data.data, schema)
        // Fill store with Normalized data
        dispatch(fillStoreWithNormalizedData(normalizedData))

        // Set store IDs
        dispatch(
          setIds
            ? setIds(normalizedData.result)
            : actions.setIds(normalizedData.result)
        )

        // Set store pagination
        if (setPagination) {
          dispatch(
            setPagination({
              total: data.totalRecords,
              current: data.currentPage,
            })
          )
        } else if (actions.setPagination) {
          dispatch(
            actions.setPagination({
              total: data.totalRecords,
              current: data.currentPage,
            })
          )
        }

        actions.stopLoading ? dispatch(actions.stopLoading()) : {}

        // Return data for other .then()
        return data
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        actions.hasError ? dispatch(actions.hasError(reason.message)) : {}
        throw reason // Throw error again to allow execution of others catch()
      })
  }
export const SBAPIFetchPaginated = async <T>(
  endpoint: string,
  params: TableParams
) =>
  await axios
    .get(`${endpoint}?${qs.stringify(params)}`)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageResponse>
      const errorMessage = data.data?.message ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPIFetchDispatch =
  <T>(
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return SBAPIFetch(endpoint)
      .then((data) => {
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        actions.stopLoading ? dispatch(actions.stopLoading()) : {}
        return data
      })
      .catch((reason: { message: string | undefined; error: AxiosError }) => {
        actions.hasError ? dispatch(actions.hasError(reason.message)) : {}
        throw reason // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIFetch = async <T>(endpoint: string, params?: any) =>
  await axios
    .get(params ? `${endpoint}?${qs.stringify(params)}` : endpoint)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageResponse>
      const errorMessage = data.data?.message ?? ''
      throw { message: errorMessage, error: error } // Throw error again to allow execution of others catch()
    })

export const SBAPICreate =
  <T>(
    model: T,
    endpoint: string,
    schema: schema.Entity | schema.Entity[],
    actions: any
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return await axios
      .post(endpoint, model)
      .then((response) => {
        // TODO: Do we really need to process creation result ?
        // As index requests might be triggered after creation...
        const data = response.data
        if (!data.data) {
          // We still need to stop loading
          actions.stopLoading ? dispatch(actions.stopLoading()) : {}
          return
        }
        const normalizedData = normalize(data.data, schema)
        dispatch(fillStoreWithNormalizedData(normalizedData))
        actions.stopLoading ? dispatch(actions.stopLoading()) : {}
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}

        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIUpdate =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())

    /**
     * The plan is to immediately dispatch the updated item
     * However in case of error we need to copy a copy to revert the change
     */
    const oldModel = Object.assign({}, model)
    dispatch(actions.updateItemSuccess(model))

    return await axios
      .put(endpoint, model)
      .then((response) => {
        // We do not process the result here, kick back and relax ...
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        // Revert the model update
        dispatch(actions.updateItemSuccess(oldModel))
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIDelete =
  <T>(model: T, endpoint: string, actions: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startLoading())
    return await axios
      .delete(endpoint)
      .then(() => {
        dispatch(actions.deleteItemSuccess(model))
      })
      .catch((error: AxiosError) => {
        const data = error.response?.data as APIResponse<MessageResponse>
        actions.hasError
          ? dispatch(actions.hasError(data.data?.message ?? ''))
          : {}
        throw error // Throw error again to allow execution of others catch()
      })
  }

export const SBAPIPost = async (endpoint: string, body: any) =>
  await axios
    .post(endpoint, body)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error: AxiosError) => {
      const data = error.response?.data as APIResponse<MessageResponse>
      throw error // Throw error again to allow execution of others catch()
    })

const fillStoreWithNormalizedData =
  (
    normalizedData: NormalizedSchema<
      {
        [key: string]:
          | {
              [key: string]: any
            }
          | undefined
      },
      any
    >
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(getEventsSuccess(normalizedData.entities.event ?? {}))
    dispatch(getEventStatusesSuccess(normalizedData.entities.eventStatus ?? {}))
    dispatch(getVenuesSuccess(normalizedData.entities.venue ?? {}))
    dispatch(getVenueTypesSuccess(normalizedData.entities.venueType ?? {}))
    dispatch(getArtistsSuccess(normalizedData.entities.artist ?? {}))
    dispatch(getStagesSuccess(normalizedData.entities.stage ?? {}))
    dispatch(getBookingsSuccess(normalizedData.entities.booking ?? {}))
    dispatch(getBookingTypesSuccess(normalizedData.entities.bookingType ?? {}))
    dispatch(getUsersSuccess(normalizedData.entities.user ?? {}))
    dispatch(getHotelsSuccess(normalizedData.entities.hotel ?? {}))
    dispatch(getRoomsSuccess(normalizedData.entities.room ?? {}))
    dispatch(getContactsSuccess(normalizedData.entities.contact ?? {}))
    dispatch(getContactTypesSuccess(normalizedData.entities.contactType ?? {}))
    dispatch(getFileTypesSuccess(normalizedData.entities.fileType ?? {}))
    dispatch(getFilesSuccess(normalizedData.entities.file ?? {}))
    dispatch(getRidersSuccess(normalizedData.entities.rider ?? {}))
    dispatch(getRidersStatusSuccess(normalizedData.entities.riderStatus ?? {}))
    dispatch(getContractsSuccess(normalizedData.entities.contract ?? {}))
    dispatch(
      getContractsStatusSuccess(normalizedData.entities.contractStatus ?? {})
    )
    dispatch(getTravelsSuccess(normalizedData.entities.travel ?? {}))
    dispatch(getTravelTypesSuccess(normalizedData.entities.travelType ?? {}))
    dispatch(getGroundsSuccess(normalizedData.entities.ground ?? {}))
    dispatch(getInvoicesSuccess(normalizedData.entities.invoice ?? {}))
    dispatch(getPaymentsSuccess(normalizedData.entities.payment ?? {}))
    dispatch(getPerformancesSuccess(normalizedData.entities.performance ?? {}))
    dispatch(getStationsSuccess(normalizedData.entities.station ?? {}))
    dispatch(getStationTypesSuccess(normalizedData.entities.stationType ?? {}))
    dispatch(
      getContactAssociationsSuccess(
        normalizedData.entities.contactAssociation ?? {}
      )
    )
    dispatch(getAddressSuccess(normalizedData.entities.address ?? {}))
    dispatch(
      getNotificationsSuccess(normalizedData.entities.notification ?? {})
    )
    dispatch(getCommentsSuccess(normalizedData.entities.comment ?? {}))
    dispatch(
      getExternalUsersSuccess(normalizedData.entities.externalUser ?? {})
    )
    dispatch(
      getExternalAdvancingLinksSuccess(
        normalizedData.entities.externalAdvancingLink ?? {}
      )
    )
    dispatch(getCurrenciesSuccess(normalizedData.entities.currency ?? {}))
  }
