import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react'
import {
  getLoggedUserInfos,
  logout as logoutAPI,
  updateLoggedUserInfos,
} from './requests'

import { useNavigate } from 'react-router-dom'

import * as authHelper from '../helpers/AuthLocalStorageHelper'
import { resetStore } from '../helpers/AuthHelper'
import { ExternalAdvancingToken } from '../../models/AuthModel'
import { ExternalAdvancingUser } from '../../models/ExternalAdvancing'
import { useAppDispatch } from '../../reducers/hooks'
import { forgetCurrentDevice } from '../firebase'
import { useLang } from '../../i18n/useLang'
import { TenantSettings } from '../../models/Tenant'

type AuthContextProps = {
  auth: ExternalAdvancingToken | undefined
  saveAuth: (auth: ExternalAdvancingToken | undefined) => void
  currentUser: ExternalAdvancingUser | undefined
  setCurrentUser: Dispatch<SetStateAction<ExternalAdvancingUser | undefined>>
  isLogged: boolean | undefined
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>
  logout: () => void
  updateUser: (user: ExternalAdvancingUser) => Promise<boolean>

  tenantSettings: TenantSettings
  setTenantSettings: Dispatch<SetStateAction<TenantSettings>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isLogged: undefined,
  setIsLogged: () => {},
  logout: () => {},
  updateUser: ({}) => new Promise<boolean>(() => false),

  tenantSettings: {
    currency: 'eur',
    measurementUnit: 'metric',
    locale: 'fr',
  },
  setTenantSettings: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [currentUser, setCurrentUser] = useState<
    ExternalAdvancingUser | undefined
  >()
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined)
  const [auth, setAuth] = useState<ExternalAdvancingToken | undefined>(
    authHelper.getAuth()
  )
  const [tenantSettings, setTenantSettings] = useState<TenantSettings>({
    currency: 'eur',
    measurementUnit: 'metric',
    locale: 'fr',
  })

  const saveAuth = (auth: ExternalAdvancingToken | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    /**
     * Clear FCM Token
     * Need to be done first as it's an API request
     */
    forgetCurrentDevice()
      .then((_) => {
        // Whatever the request result
        // The user must be logged out
        logoutAPI().then(logoutHandler).catch(logoutHandler)
      })
      .catch((_) => {
        // Whatever the request result
        // The user must be logged out
        logoutAPI().then(logoutHandler).catch(logoutHandler)
      })
  }

  const logoutHandler = () => {
    setIsLogged(false)
    setCurrentUser(undefined)
    saveAuth(undefined)
    dispatch(resetStore())
    navigate('/external-advancing/auth/login')
  }

  const updateUser = (user: ExternalAdvancingUser) =>
    updateLoggedUserInfos(user).then((_) => {
      getLoggedUserInfos().then((data) => setCurrentUser(data.data.data))
      return true
    })

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        isLogged,
        setIsLogged,
        logout,
        updateUser,
        tenantSettings,
        setTenantSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<PropsWithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setIsLogged } = useAuth()
  const didRequest = useRef(false)
  const { selectedLocale, setLocale } = useLang()

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data } = await getLoggedUserInfos()
          if (data) {
            setCurrentUser(data.data)
            if (data.data.locale !== selectedLocale) {
              /**
               * The just fetched locale is considered as prefered locale
               * So in case the local locale is not the same let's update it
               */
              setLocale(data.data.locale)
            }
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      setIsLogged(true)
      requestUser()
    } else {
      setIsLogged(false)
    }
  }, [])

  return <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
