import { Avatar, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { AvatarProps } from 'antd/es/avatar'
import { Contact as ContactModel } from '../../models/Contact'
import { selectContactsByIds } from '../../reducers/ContactReducer'
import { Contact } from './Contact'

const { Text } = Typography
interface Props {
  ids?: number[]
  models?: ContactModel[]
  linkToDetails?: boolean
  size?: AvatarProps['size']
}

export const ContactAvatarGroup = ({
  ids,
  models,
  linkToDetails = true,
  size = 'default',
}: Props) => {
  const contacts = models
    ? models
    : useAppSelector(selectContactsByIds(ids ?? []))

  return contacts ? (
    <Avatar.Group
      maxCount={3}
      maxStyle={{ color: '#fff', backgroundColor: '#5d5df6' }}
    >
      {contacts.map((contact) => (
        <Contact.Avatar
          key={contact.id}
          model={contact}
          type="condensed"
          editable={false}
          linkToDetails={linkToDetails}
        />
      ))}
    </Avatar.Group>
  ) : (
    <></>
  )
}

export type ContactAvatarGroupType = { AvatarGroup: typeof ContactAvatarGroup }
