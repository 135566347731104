import { AxiosStatic } from 'axios'
import { ArgsProps } from 'antd/es/notification/interface'
import { useLang } from '../i18n/useLang'
import { getAuth } from './helpers/AuthLocalStorageHelper'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../reducers/ExternalAdvancingLinkReducer'

export function setupAxios(
  axios: AxiosStatic,
  displayNotification: (notification: ArgsProps) => void
) {
  const navigate = useNavigate()
  const { selectedLocale } = useLang()
  const link = useAppSelector(selectSelectedExternalAdvancingLink())
  // Requests default headers
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['Accept-Language'] = selectedLocale

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      config.headers.Accept = 'application/json'

      const auth = getAuth()
      if (auth !== undefined && auth.access_token) {
        // config.headers['Alela-Bearer'] = `Bearer ${accessToken}`
        config.headers['Authorization'] = `Bearer ${auth.access_token}`
      }

      if (link !== undefined) {
        config.headers['X-Alela-EAL'] = link.id
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        /**
         * The API returned a 401 - Unauthorized status code
         */

        // First of all, mark the request as retried
        originalRequest._retry = true

        // No refresh token system on External Advancing
        // Therefore redirect to login
        navigate('/auth/login')
      }

      return Promise.reject(error)
    }
  )
}
