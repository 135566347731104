import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  selectExternalAdvancingLinkById,
  selectSelectedExternalAdvancingLink,
} from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { List, Skeleton, Space, Tag, Tooltip } from 'antd'
import {
  getExternalAdvancingRoomSectionData,
  selectRooms,
} from '../../reducers/RoomReducer'
import { Hotel } from '../hotel/Hotel'
import { currencyFormat } from '@supplement-bacon/alela-uikit'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import dayjs from 'dayjs'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useIntl } from 'react-intl'
import { Contact } from '../contact/Contact'
import ApprovableItemCard from '../ApprovableItemCard'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { Room } from '../room/Room'

const HotelSection = ({}: SectionProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { currencySymbol } = useTenantSettings()

  const rooms = useAppSelector(selectRooms())
  const { isLoading } = useAppSelector((state) => state.room)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getExternalAdvancingRoomSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.room) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (isLoading) return <Skeleton />

  return (
    <List
      dataSource={rooms}
      renderItem={(room) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={room}>
            <Space
              className="w-full"
              direction="horizontal"
              style={{ justifyContent: 'space-between' }}
            >
              <Space size={5} direction="vertical">
                <Hotel.Avatar id={room.hotel!} linkToDetails={false} />
                {(room.reference != null || room.category != null) && (
                  <Space size={0}>
                    {room.reference && <Tag>{room.reference}</Tag>}
                    {room.category && <Tag>{room.category}</Tag>}
                  </Space>
                )}
              </Space>
              <Room.Price id={room.id} />
              <Tooltip
                title={`${dayjs(room.from).format('DD/MM (HH:mm)')} - ${dayjs(
                  room.to
                ).format('DD/MM (HH:mm)')}`}
              >
                {room.from && room.to && (
                  <Tag>
                    {Math.round(dayjs(room.to).diff(room.from, 'day', true))}{' '}
                    {intl.formatMessage({
                      id: LocalizationKeys.Page.EventDetails.Hotels.Nights,
                    })}
                  </Tag>
                )}
              </Tooltip>
              <Contact.AvatarGroup ids={room.contacts} linkToDetails={false} />
              <Actions
                section={ALMSectionKey.hotels}
                actions={['update', 'delete']}
                entity={SBRMType.room}
                entityId={room.id}
              />
            </Space>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default HotelSection
