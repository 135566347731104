import { FC } from 'react'
import { UserAvatar, UserAvatarType } from './Avatar'
import {
  CompleteProfileModal,
  CompleteProfileModalType,
} from './CompleteProfileModal'

export const User: FC &
  UserAvatarType &
  CompleteProfileModalType = (): JSX.Element => <></>

User.Avatar = UserAvatar
User.CompleteProfileModal = CompleteProfileModal
