import { useState } from 'react'
import { App, Button, Drawer, Modal, Space, Typography } from 'antd'
import SBRMTitle from './SBRMTitle'
import { close } from '../../reducers/SBRMReducer'
import DrawerFooter from './components/DrawerFooter'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { useSBRMforms } from './hooks'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography

interface Props {}

const NestedSBRM = ({}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { message } = App.useApp()
  const { refs: SBRMRefs, forms: SBRMForms } = useSBRMforms({ isNested: true })

  const {
    isOpen: nestedIsOpen,
    entity: nestedEntity,
    action: nestedAction,
  } = useAppSelector((state) => state.SBRM.nested)

  const [nestedIsLoading, setNestedIsLoading] = useState<boolean>(false)

  const fireThatSuccess = (result: any) => {
    message.open({
      type: 'success',
      content: 'Alelaaaaa',
    })
    setNestedIsLoading(false)
    onCloseNested()
  }

  const fireThatError = (e: any) => {
    message.open({
      type: 'error',
      content: 'Oops ...',
    })
    setNestedIsLoading(false)
  }

  const onSaveNested = () => {
    setNestedIsLoading(true)
    SBRMRefs[nestedEntity!].current
      ?.handleCreate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onUpdateNested = () => {
    setNestedIsLoading(true)
    SBRMRefs[nestedEntity!].current
      ?.handleUpdate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onDeleteNested = () => {
    setNestedIsLoading(true)
    SBRMRefs[nestedEntity!].current
      ?.handleDelete()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onCloseNested = () => {
    SBRMRefs[nestedEntity!].current?.handleReset()
    dispatch(close())
  }

  return (
    <>
      {nestedAction !== 'delete' && (
        <Drawer
          title={SBRMTitle(nestedEntity, nestedAction)}
          placement="right"
          onClose={onCloseNested}
          open={nestedIsOpen}
          width={500}
          footer={
            <DrawerFooter
              isLoading={nestedIsLoading}
              entity={nestedEntity}
              action={nestedAction}
              onSave={onSaveNested}
              onUpdate={onUpdateNested}
              onClose={onCloseNested}
            />
          }
        >
          {!nestedEntity && (
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.SBRM.EntityNotDefined,
              })}
            </Text>
          )}
          {nestedEntity && SBRMForms[nestedEntity]}
        </Drawer>
      )}

      {/* Modal for delete */}
      {nestedAction === 'delete' && (
        <Modal
          open={nestedIsOpen}
          onCancel={onCloseNested}
          footer={
            <Space className="p-2 text-right">
              <Button onClick={onCloseNested} disabled={nestedIsLoading}>
                {intl.formatMessage({ id: LocalizationKeys.SBRM.Close })}
              </Button>
              {nestedEntity && (
                <Button
                  onClick={onDeleteNested}
                  type="primary"
                  color="danger"
                  loading={nestedIsLoading}
                >
                  {intl.formatMessage({ id: LocalizationKeys.SBRM.Delete })}
                </Button>
              )}
            </Space>
          }
        >
          {!nestedEntity && (
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.SBRM.EntityNotDefined,
              })}
            </Text>
          )}
          {nestedEntity && SBRMForms[nestedEntity]}
        </Modal>
      )}
    </>
  )
}

export default NestedSBRM
