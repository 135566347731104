import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { Col, List, Row, Skeleton, Space } from 'antd'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import {
  getExternalAdvancingInvoiceSectionData,
  selectInvoices,
} from '../../reducers/InvoiceReducer'
import { Payment } from '../payment/Payment'
import { Invoice } from '../invoice/Invoice'
import ApprovableItemCard from '../ApprovableItemCard'
import { File } from '../file/File'

const InvoiceSection = ({}: SectionProps) => {
  const dispatch = useAppDispatch()

  const invoices = useAppSelector(selectInvoices())
  const { isLoading } = useAppSelector((state) => state.invoice)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getExternalAdvancingInvoiceSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.invoice) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (isLoading) return <Skeleton />

  return (
    <List
      dataSource={invoices}
      renderItem={(invoice) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={invoice}>
            <Row>
              <Col span={12} style={{ alignContent: 'center' }}>
                <File.Cell id={invoice.files[0]} maxWidth={300} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'center' }}
              >
                <Space>
                  <Invoice.ReferenceTag id={invoice.id} />
                  <Invoice.AmountsTag id={invoice.id} />
                </Space>
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'right' }}
              >
                <Actions
                  section={ALMSectionKey.invoices}
                  actions={['view', 'update', 'delete']}
                  entity={SBRMType.invoice}
                  entityId={invoice.id}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={24}>
                {invoice.payments !== undefined &&
                  invoice.payments.length > 0 && (
                    <List
                      dataSource={invoice.payments}
                      renderItem={(item) => (
                        <List.Item style={{ border: 0, paddingBottom: 0 }}>
                          <Payment.Card id={item} />
                        </List.Item>
                      )}
                    />
                  )}
              </Col>
            </Row>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default InvoiceSection
