import { resetAddressState } from '../../reducers/AddressReducer'
import { resetArtistState } from '../../reducers/ArtistReducer'
import { resetBookingState } from '../../reducers/BookingReducer'
import { resetBookingTypeState } from '../../reducers/BookingTypeReducer'
import { resetContactAssociationState } from '../../reducers/ContactAssociationReducer'
import { resetContactState } from '../../reducers/ContactReducer'
import { resetContactTypeState } from '../../reducers/ContactTypeReducer'
import { resetContractState } from '../../reducers/ContractReducer'
import { resetEventState } from '../../reducers/EventReducer'
import { resetEventStatusState } from '../../reducers/EventStatusReducer'
import { resetFileState } from '../../reducers/FileReducer'
import { resetFileTypeState } from '../../reducers/FileTypeReducer'
import { resetGroundState } from '../../reducers/GroundReducer'
import { resetHotelState } from '../../reducers/HotelReducer'
import { resetInvoiceState } from '../../reducers/InvoiceReducer'
import { resetPaymentState } from '../../reducers/PaymentReducer'
import { resetPerformanceState } from '../../reducers/PerformanceReducer'
import { resetRiderState } from '../../reducers/RiderReducer'
import { resetRoomState } from '../../reducers/RoomReducer'
import { resetSBRM } from '../../reducers/SBRMReducer'
import { resetStageState } from '../../reducers/StageReducer'
import { resetStationState } from '../../reducers/StationReducer'
import { resetStationTypeState } from '../../reducers/StationTypeReducer'
import { resetTravelState } from '../../reducers/TravelReducer'
import { resetTravelTypeState } from '../../reducers/TravelTypeReducer'
import { resetUserState } from '../../reducers/UserReducer'
import { resetVenueState } from '../../reducers/VenueReducer'
import { resetVenueTypeState } from '../../reducers/VenueTypeReducer'
import { AppDispatch } from '../../reducers/store'

/**
 * Reset Redux store to its initial state
 * Must be dispatched!
 */
const resetStore = () => (dispatch: AppDispatch) => {
  dispatch(resetAddressState())
  dispatch(resetArtistState())
  dispatch(resetBookingState())
  dispatch(resetBookingTypeState())
  dispatch(resetContactAssociationState())
  dispatch(resetContactState())
  dispatch(resetContactTypeState())
  dispatch(resetContractState())
  dispatch(resetEventState())
  dispatch(resetEventStatusState())
  dispatch(resetFileState())
  dispatch(resetFileTypeState())
  dispatch(resetGroundState())
  dispatch(resetHotelState())
  dispatch(resetInvoiceState())
  dispatch(resetPaymentState())
  dispatch(resetPerformanceState())
  dispatch(resetRiderState())
  dispatch(resetRoomState())
  dispatch(resetSBRM())
  dispatch(resetStageState())
  dispatch(resetStationState())
  dispatch(resetStationTypeState())
  dispatch(resetTravelState())
  dispatch(resetTravelTypeState())
  dispatch(resetUserState())
  dispatch(resetVenueState())
  dispatch(resetVenueTypeState())
}

export { resetStore }
