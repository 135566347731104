import { Empty, List, Tag } from 'antd'
import { Comment } from './Comment'
import { ExternalComment } from '../../models/Comment'
import { useAppSelector } from '../../reducers/hooks'
import { selectCommentsByIds } from '../../reducers/CommentReducer'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  ids?: number[]
  models?: ExternalComment[]
}

export const CommentList = ({ ids, models }: Props) => {
  const intl = useIntl()

  const comments = models
    ? models
    : useAppSelector(selectCommentsByIds(ids ?? []))

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef.current && listRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [comments.length])

  return comments.length > 0 ? (
    <>
      <List
        style={{ width: '100%' }}
        size="large"
        dataSource={comments}
        renderItem={(item) => <Comment.Cell id={item.id} />}
      />
      <div ref={listRef}></div>
    </>
  ) : (
    <Empty
      style={{
        position: 'relative',
        marginTop: 'calc(50% - 60px)',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={intl.formatMessage({
        id: LocalizationKeys.Components.Comment.List.SendFirstMessage,
      })}
    />
  )
}

export type CommentListType = { List: typeof CommentList }
