import {
  Avatar,
  Button,
  Typography,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Result,
} from 'antd'
import { useAuth } from '../../utils/auth/Auth'
import {
  CheckOutlined,
  PoweroffOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { useLang } from '../../i18n/useLang'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useState } from 'react'
import { handleLocaleSubmit } from '../../components/preference/Locale'
import { User } from '../../components/user/User'
import { Notification } from '../../components/notification/Notification'

const { Text } = Typography

const RightHeader = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  const { locales, selectedLocale, setLocale } = useLang()

  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)
  const [localeIsLoading, setLocaleIsLoading] = useState<boolean>(false)
  const [logoutModalIsVisible, setLogoutModalIsVisible] =
    useState<boolean>(false)
  const [profileModalIsVisible, setProfileModalIsVisible] =
    useState<boolean>(false)

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: intl.formatMessage({
        id: LocalizationKeys.Layout.RightHeader.Profile,
      }),
      onClick: () => setProfileModalIsVisible(true),
    },
    {
      key: 'lang',
      label: intl.formatMessage({
        id: LocalizationKeys.Layout.RightHeader.Lang,
      }),
      disabled: localeIsLoading,
      children: locales.map((locale) => ({
        key: locale.id,
        label: (
          <Space
            className="w-full"
            style={{ justifyContent: 'space-between', minWidth: 150 }}
          >
            {locale.label} {selectedLocale == locale.id && <CheckOutlined />}
          </Space>
        ),
        onClick: () =>
          handleLocaleSubmit(locale.id, setLocaleIsLoading, setLocale),
      })),
    },
    { type: 'divider' },
    {
      key: 'logout',
      onClick: () => setLogoutModalIsVisible(true),
      label: (
        <Space className="w-full">
          <PoweroffOutlined style={{ color: 'red' }} />
          <Text type="danger">
            {intl.formatMessage({
              id: LocalizationKeys.Layout.ProfileSider.Logout,
            })}
          </Text>
        </Space>
      ),
    },
  ]

  return (
    <Space size={12}>
      <Notification.Wrapper />
      <Dropdown
        arrow
        menu={{ items }}
        placement="bottomLeft"
        overlayStyle={{ width: 150 }}
      >
        <Avatar
          style={{
            marginBottom: 4,
            color: '#fff',
            backgroundColor: '#5d5df6',
            cursor: 'pointer',
          }}
        >
          {currentUser && currentUser.name
            ? currentUser!.name.slice(0, 1)
            : '--'}
        </Avatar>
      </Dropdown>
      <Modal
        open={logoutModalIsVisible}
        onCancel={() => setLogoutModalIsVisible(false)}
        footer={
          <Button
            type="primary"
            danger
            className="w-full"
            loading={isLoggingOut}
            onClick={() => {
              setIsLoggingOut(true)
              logout()
            }}
          >
            {intl.formatMessage({
              id: LocalizationKeys.Layout.ProfileSider.Logout,
            })}
          </Button>
        }
      >
        <Result
          title={intl.formatMessage({
            id: LocalizationKeys.Layout.ProfileSider.LogoutConfirmation,
          })}
          icon={<WarningOutlined style={{ color: '#dc4446' }} />}
        />
      </Modal>
      <User.CompleteProfileModal
        isOpen={profileModalIsVisible}
        onCancel={() => setProfileModalIsVisible(false)}
      />
    </Space>
  )
}

export default RightHeader
