import { useAppSelector } from '../../reducers/hooks'
import { Space, Tag, Tooltip, Typography } from 'antd'
import { selectPerformanceById } from '../../reducers/PerformanceReducer'
import { selectBookingById } from '../../reducers/BookingReducer'
import { Performance } from '../../models/Performance'
import dayjs from 'dayjs'
import { selectStageById } from '../../reducers/StageReducer'
import { ArrowRightOutlined } from '@ant-design/icons'
const { Text } = Typography

interface Props {
  id?: number
  model?: Performance
}

export const Cell = ({ id, model }: Props) => {
  const performance = model
    ? model
    : useAppSelector(selectPerformanceById(id ?? 0))
  const booking = useAppSelector(selectBookingById(performance?.booking ?? 0))
  const stage = useAppSelector(selectStageById(performance?.stage ?? 0))

  return performance && booking ? (
    <Tooltip title={stage?.name}>
      <Tag>
        <Space direction="horizontal" size={2}>
          {dayjs(performance.start).format('HH:mm')}
          <ArrowRightOutlined style={{ fontSize: 10 }} />
          {dayjs(performance.end).format('HH:mm')}
        </Space>
      </Tag>
    </Tooltip>
  ) : (
    <></>
  )
}

export type CellType = { Cell: typeof Cell }
