import dayjs from 'dayjs'
import Table from './Table'
import { commonStyle } from './style'
import { Text, View } from '@react-pdf/renderer'
import { PerformanceWithRelation } from '../../../models/Performance'

interface Props {
  data: PerformanceWithRelation[]
}

const TimeTable = ({ data }: Props) => {
  const performancesByStage = Object.entries(
    data.reduce(
      (
        accumulator: Record<string, PerformanceWithRelation[]>,
        performance: PerformanceWithRelation
      ) => {
        if (!accumulator[performance.stage.name])
          accumulator[performance.stage.name] = []
        accumulator[performance.stage.name].push(performance)
        return accumulator
      },
      {}
    )
  )

  const TimeTable = [
    {
      title: 'Set Time',
      key: 'from',
      render: (record: PerformanceWithRelation) => (
        <View style={commonStyle.spaceHorizontal}>
          <Text>{dayjs(record.start).format('HH:mm')}</Text>
          <Text>-</Text>
          <Text>{dayjs(record.end).format('HH:mm')}</Text>
        </View>
      ),
    },
    {
      title: 'Artist',
      key: 'to',
      render: (record: PerformanceWithRelation) => (
        <View style={commonStyle.spaceVertical}>
          <Text>{record.booking && record.booking.artist.name}</Text>
        </View>
      ),
    },
  ]

  return (
    <>
      {performancesByStage.map(([stageName, performances]) =>
        performances.length > 0 ? (
          <View key={stageName} style={commonStyle.col12} wrap={false}>
            <Text style={commonStyle.h5}>{stageName}</Text>
            <Table columns={TimeTable} data={performances!} />
          </View>
        ) : (
          <></>
        )
      )}
    </>
  )
}

export default TimeTable
