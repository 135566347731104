import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Alert, App, Button, Form, Input, Spin, Typography } from 'antd'
import { login, requestLoginMagicLink } from '../../utils/auth/requests'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { emailRule, requiredRule } from '../../utils/rules'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useAuth } from '../../utils/auth/Auth'
import { ExternalAdvancingLoginResult } from '../../models/AuthModel'

const { Text } = Typography

const HandleMagicLink = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { message } = App.useApp()
  const navigate = useNavigate()
  const { saveAuth, setCurrentUser, setIsLogged } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    setIsLoading(true)
    login(
      searchParams.get('expires'),
      searchParams.get('token'),
      searchParams.get('returnTo'),
      searchParams.get('signature')
    )
      .then((data) => {
        const response = data.data.data as ExternalAdvancingLoginResult
        saveAuth(response.token)
        setCurrentUser(response.user)
        setIsLogged(true)
      })
      .catch(() => {
        setError('Error - Link already used')
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      {error === undefined && (
        <>
          <Spin />
          <Text>On branche les platines ...</Text>
        </>
      )}
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        {error && (
          <>
            <Alert style={{ marginBottom: 25 }} message={error} type="error" />
            <Button
              className="w-full"
              type="text"
              onClick={() => navigate('/')}
            >
              <ArrowLeftOutlined /> Back to login
            </Button>
          </>
        )}
      </div>
    </>
  )
}

export default HandleMagicLink
