import { FC } from 'react'
import { CommentCell, CommentCellType } from './Cell'
import { CommentWrapper, CommentWrapperType } from './Wrapper'
import { CommentList, CommentListType } from './List'
import { CommentEditor, CommentEditorType } from './Editor'
import {
  CommentSubscribeToConversation,
  CommentSubscribeToConversationType,
} from './SubscribeToConversation'

export const Comment: FC &
  CommentCellType &
  CommentWrapperType &
  CommentListType &
  CommentEditorType &
  CommentSubscribeToConversationType = (): JSX.Element => <></>

Comment.Cell = CommentCell
Comment.Wrapper = CommentWrapper
Comment.List = CommentList
Comment.Editor = CommentEditor
Comment.SubscribeToConversation = CommentSubscribeToConversation
