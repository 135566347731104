import {
  SBState,
  SBSelectRaw,
  baseReducers,
  getUUIDOrModelUUID,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
} from '../utils/helpers/SBAPIHelper'
import { ExternalAdvancingLink } from '../models/ExternalAdvancing'
import { EXTERNAL_ADVANCING_USER_LINK_URL, SECTIONS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { externalAdvancingLinkSchema, travelSchema } from '../models/schema'
import { initialQuery } from '../utils/helpers/crud/models'
import { schema } from 'normalizr'

const initialState: SBState<ExternalAdvancingLink> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: initialQuery,
}

const slice = createSlice({
  name: 'externalAdvancingLink',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getExternalAdvancingLinksSuccess,
  setQuery: setExternalAdvancingLinkQuery,
  reset: resetExternalAdvancingLinkState,
  resetQueryAndIds: resetExternalAdvancingLinkQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: ExternalAdvancingLink }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectExternalAdvancingLinks = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )

export const selectAllExternalAdvancingLinks = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )

export const selectSelectedExternalAdvancingLink = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectExternalAdvancingLinkById = (id: string) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectExternalAdvancingLinksByIds = (ids: string[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedExternalAdvancingLink =
  (externalAdvancingLink: ExternalAdvancingLink | string | undefined) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        externalAdvancingLink
          ? getUUIDOrModelUUID<ExternalAdvancingLink>(externalAdvancingLink)
          : undefined
      )
    )

export const getExternalAdvancingLinks = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<ExternalAdvancingLink>(
    EXTERNAL_ADVANCING_USER_LINK_URL,
    params,
    [externalAdvancingLinkSchema],
    slice.actions
  )

export const getExternalAdvancingLinkWithId = (id: string) =>
  SBAPIFetchDispatch<ExternalAdvancingLink[]>(
    `${EXTERNAL_ADVANCING_USER_LINK_URL}/${id}`,
    externalAdvancingLinkSchema,
    slice.actions
  )

export const getExternalAdvancingSectionData = (
  section: string,
  params: TableParams,
  schema: schema.Entity | schema.Entity[],
  actions?: any
) =>
  SBAPIFetchPaginatedDispatch<ExternalAdvancingLink>(
    `${SECTIONS_URL}/${section}`,
    params,
    schema,
    actions ?? slice.actions
  )

export const getDealSectionData = () =>
  getExternalAdvancingSectionData('deal', initialQuery, travelSchema)
