import {
  getExternalAdvancingLinkWithId,
  selectSelectedExternalAdvancingLink,
  setSelectedExternalAdvancingLink,
} from '../reducers/ExternalAdvancingLinkReducer'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import { externalAdvancingSections } from '../models/types'
import { Card, Col, Row, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingFullScreen } from '../layouts/LoadingFullScreen'
import { ExternalAdvanceSider } from '../layouts/components/ExternalAdvanceSider'
import { useAppDispatch, useAppSelector } from '../reducers/hooks'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { useAuth } from '../utils/auth/Auth'
import { SectionToolbar } from '../components/SectionToolbar'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const ExternalAdvancing = () => {
  dayjs.extend(relativeTime)

  const intl = useIntl()
  const { uuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setTenantSettings } = useAuth()

  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  useEffect(() => {
    if (uuid === undefined) {
      navigate('/event-selector')
      return
    }

    dispatch(getExternalAdvancingLinkWithId(uuid))
      .then((response) => {
        setTenantSettings(response.data.tenant.settings)
      })
      .catch(() => {
        navigate('/event-selector')
      })
    dispatch(setSelectedExternalAdvancingLink(uuid))
  }, [uuid])

  if (link === undefined) {
    return <LoadingFullScreen />
  }

  return (
    <Row className="container-row">
      <Col xs={24} className="px-4">
        <Space direction="vertical" size={16} className="w-full">
          <Row gutter={16}>
            <Col span={6}>
              <ExternalAdvanceSider />
            </Col>
            <Col
              span={18}
              style={{
                display: 'flex',
                gap: 40,
                flexDirection: 'column',
                paddingTop: 24,
              }}
            >
              {externalAdvancingSections.map(
                (section) =>
                  link[section.propertyName] != 'hidden' && (
                    <Card
                      styles={{
                        body: { paddingTop: 0 },
                        header: { marginBottom: -10 },
                      }}
                      id={section.key}
                      bordered={false}
                      key={section.key}
                      title={
                        <PageTitle
                          level={5}
                          title={intl.formatMessage({
                            id: LocalizationKeys.ExternalAdvancing[section.key],
                          })}
                          toolbar={
                            <SectionToolbar section={section} link={link} />
                          }
                        />
                      }
                    >
                      {section.component({})}
                    </Card>
                  )
              )}
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  )
}

export default ExternalAdvancing
