import { Button, Form, Input, Modal, Select, Space, Typography } from 'antd'
import { useAuth } from '../../utils/auth/Auth'
import { requiredRule, stringRule } from '../../utils/rules'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import { useLang } from '../../i18n/useLang'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text, Title } = Typography

interface CompleteProfileModalProps {
  isOpen: boolean
  closable?: boolean
  onCancel?: () => void
}

const CompleteProfileModal = ({
  isOpen,
  closable = true,
  onCancel,
}: CompleteProfileModalProps) => {
  const intl = useIntl()
  const { locales } = useLang()
  const { currentUser, updateUser } = useAuth()
  const { locale } = useTenantSettings()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFormFinish = () => {
    setIsLoading(true)
    updateUser(form.getFieldsValue()).finally(() => setIsLoading(false))
  }

  if (currentUser === undefined) return <></>

  return (
    <Modal
      width={450}
      open={isOpen}
      closable={closable}
      footer={<></>}
      onCancel={onCancel}
      title={intl.formatMessage({
        id: LocalizationKeys.Components.CompleteYourProfile,
      })}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ marginTop: 15 }}
        className="w-full"
        onFinish={onFormFinish}
        initialValues={{
          name: currentUser.name,
          email: currentUser.email,
          locale: locale,
        }}
      >
        <Form.Item
          className="w-full"
          name={'name'}
          label={'Nom'}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          className="w-full"
          name={'email'}
          label={'Email'}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input type="text" disabled />
        </Form.Item>
        <Form.Item className="w-full" name="locale" label={'Locale'}>
          <Select
            className="w-full"
            options={locales.map((l) => ({
              value: l.id,
              label: l.label,
            }))}
          />
        </Form.Item>
        <Button
          className="w-full"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Button.Update,
          })}
        </Button>
      </Form>
    </Modal>
  )
}

type CompleteProfileModalType = {
  CompleteProfileModal: typeof CompleteProfileModal
}

export { CompleteProfileModal, type CompleteProfileModalType }
