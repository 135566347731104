import './SBRM.css'
import { useEffect, useState } from 'react'
import NestedSBRM from './NestedSBRM'
import { useSBRMforms } from './hooks'
import SBRMTitle from './SBRMTitle'
import { SBRMTypeInfos } from './SBRMTypeInfos'
import { close, setEntity } from '../../reducers/SBRMReducer'
import FileViewer from '../file-viewer/FileViewer'
import DrawerFooter from './components/DrawerFooter'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { App, Button, Drawer, Modal, Space, Typography } from 'antd'
import FileViewerToolBar from '../file-viewer/components/Toolbar'
import { Comment } from '../../components/comment/Comment'
import { CommentContextProvider } from '../../utils/context/CommentContext'
import { removeUrlParams } from './UrlHelper'
import { useSearchParams } from 'react-router-dom'
import { isSBRMAction, isSBRMType } from './SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { isUUID } from '../../utils/helpers/UUIDHelpers'

const { Text } = Typography

const SBRM = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { message } = App.useApp()
  const [searchParams, setSearchParams] = useSearchParams()
  const { refs: SBRMRefs, forms: SBRMForms } = useSBRMforms({ isNested: false })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { isOpen, entity, action } = useAppSelector((state) => state.SBRM)

  const onSave = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleCreate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onUpdate = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleUpdate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onDelete = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleDelete()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onClose = () => {
    if (SBRMRefs[entity!].current !== null) {
      SBRMRefs[entity!].current?.handleReset()
    }
    removeUrlParams(setSearchParams, [
      'action',
      'entity',
      'entityId',
      'metadata',
      'notificationId',
    ])
    dispatch(close())
  }

  const fireThatSuccess = (result: any) => {
    message.open({
      type: 'success',
      content: 'Alelaaaaa',
    })
    setIsLoading(false)
    onClose()
  }

  const fireThatError = (e: any) => {
    console.log(e)
    message.open({
      type: 'error',
      content: 'Oops ...',
    })
    setIsLoading(false)
  }

  const displayLeftPanel = (): boolean =>
    entity !== undefined
      ? SBRMTypeInfos[entity!].layout[action]?.leftPanelContent !== undefined
      : false

  const displayRightPanel = (): boolean =>
    entity !== undefined
      ? SBRMTypeInfos[entity!].layout[action]?.rightPanelContent !== undefined
      : false

  useEffect(() => {
    /**
     * On search parameters update we look for SBRM action & entity parameters
     * When present we must update the store
     *
     * In case the parameters are not present and the SBRM is open
     * Let's close the SBRM
     *
     * In case the parameters are not present and the SBRM is NOT open
     * Well ... nothing to do here
     */
    const searchAction = searchParams.get('action')
    const searchEntity = searchParams.get('entity')
    if (
      searchAction !== null &&
      searchEntity !== null &&
      isSBRMAction(searchAction) &&
      isSBRMType(searchEntity)
    ) {
      // Set selected ID if applicable
      const searchEntityId = searchParams.get('entityId')
      if (
        searchEntityId !== null &&
        (Number.isInteger(Number(searchEntityId)) || isUUID(searchEntityId))
      ) {
        dispatch(
          SBRMTypeInfos[searchEntity!].methods.setSelected(searchEntityId)
        )
      }
      // Set entity
      let metadata = []
      const metadataUrl = searchParams.get('metadata')
      if (metadataUrl !== null) {
        metadata = JSON.parse(metadataUrl)
      }
      dispatch(
        setEntity({
          entity: searchEntity,
          action: searchAction,
          metadata: metadata,
        })
      )
    } else if (isOpen) {
      onClose()
    }
  }, [searchParams])

  return (
    <>
      {/* Drawer is all action except delete - i.e. create, update & view */}
      {action !== 'delete' && (
        <Drawer
          open={isOpen}
          onClose={onClose}
          title={
            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
              {/* TITLE */}
              {SBRMTitle(entity, action)}

              {/* TOOLBAR */}
              <div>
                {entity && (
                  <>
                    {SBRMTypeInfos[entity!].layout[action]?.leftPanelContent ===
                      'file-viewer' && <FileViewerToolBar entity={entity!} />}
                  </>
                )}
              </div>
            </Space>
          }
          // --- Style
          styles={{ body: { padding: 0 } }}
          height={'100%'}
          width={displayLeftPanel() ? '100%' : 600} // si on doit aficher un viewer alors il nous faut de la place
          placement={displayRightPanel() ? 'right' : 'bottom'} // si pas de formulaire alors on vient d'en bas
        >
          <div className="SBRMDrawerWrapper">
            {!entity && (
              <Text>
                {intl.formatMessage({
                  id: LocalizationKeys.SBRM.EntityNotDefined,
                })}
              </Text>
            )}

            {/* Left Panel - i.e. File Viewer */}
            {displayLeftPanel() &&
              SBRMTypeInfos[entity!].layout[action]?.leftPanelContent ===
                'file-viewer' && (
                <div className="SBRMFileViewerWrapper">
                  <FileViewer entity={entity!} />
                </div>
              )}

            {/* Right Panel - i.e. Form & Footer */}
            {displayRightPanel() && (
              <div className="SBRMRightPanelWrapper">
                {SBRMTypeInfos[entity!].layout[action]?.rightPanelContent ===
                  'form' && (
                  <>
                    <div className="SBRMFormWrapper">
                      {entity && SBRMForms[entity]}
                    </div>
                    <div className="SBRMFooterWrapper">
                      <DrawerFooter
                        isLoading={isLoading}
                        entity={entity}
                        action={action}
                        onSave={onSave}
                        onUpdate={onUpdate}
                        onClose={onClose}
                      />
                    </div>
                  </>
                )}
                {SBRMTypeInfos[entity!].layout[action]?.rightPanelContent ===
                  'comments' && (
                  <CommentContextProvider entity={entity!}>
                    <div className="SBRMFormWrapper">
                      <Comment.Wrapper />
                    </div>
                    <div className="SBRMFooterWrapper" style={{ padding: 0 }}>
                      <Comment.Editor entity={entity!} />
                    </div>
                  </CommentContextProvider>
                )}
              </div>
            )}
          </div>

          {/* 
            Nested SBRM
            It manages itself Drawer/delete modal
           */}
          <NestedSBRM />
        </Drawer>
      )}

      {/* Modal for delete */}
      {action === 'delete' && (
        <Modal
          open={isOpen}
          onCancel={onClose}
          footer={
            entity && (
              <Button
                onClick={onDelete}
                type="primary"
                danger
                loading={isLoading}
                className="w-full"
              >
                {intl.formatMessage({ id: LocalizationKeys.SBRM.Delete })}
              </Button>
            )
          }
        >
          {!entity && (
            <Text>
              {intl.formatMessage({
                id: LocalizationKeys.SBRM.EntityNotDefined,
              })}
            </Text>
          )}
          {entity && SBRMForms[entity]}
        </Modal>
      )}
    </>
  )
}

export default SBRM
