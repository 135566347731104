import { ExternalAdvancingLink } from '../../models/ExternalAdvancing'
import { useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { ALMSectionKey, externalAdvancingSections } from '../../models/types'
import { selectBookings } from '../../reducers/BookingReducer'
import { selectPerformances } from '../../reducers/PerformanceReducer'
import { selectInvoices } from '../../reducers/InvoiceReducer'
import { selectContracts } from '../../reducers/ContractReducer'
import { selectTravels } from '../../reducers/TravelReducer'
import { selectGrounds } from '../../reducers/GroundReducer'
import { selectRiders } from '../../reducers/RiderReducer'
import { selectRooms } from '../../reducers/RoomReducer'
import { selectContacts } from '../../reducers/ContactReducer'

const useSectionLimit = (
  sectionKey: ALMSectionKey,
  ealink?: ExternalAdvancingLink
) => {
  const link = ealink
    ? ealink
    : useAppSelector(selectSelectedExternalAdvancingLink())!
  const section = externalAdvancingSections.find((s) => s.key === sectionKey)!

  const sectionCanBeEdited: boolean = link[section.propertyName] === 'edit'

  const sectionHasLimit: boolean =
    section.maxValuePropertyName !== undefined &&
    Number.isInteger(link[section.maxValuePropertyName])

  const sectionLimit: number =
    sectionHasLimit === true ? Number(link[section.maxValuePropertyName!]) : 0

  const selectorForSection: Record<ALMSectionKey, any> = {
    [ALMSectionKey.deal]: selectBookings,
    [ALMSectionKey.timetable]: selectPerformances,
    [ALMSectionKey.contract]: selectContracts,
    [ALMSectionKey.invoices]: selectInvoices,
    [ALMSectionKey.travels]: selectTravels,
    [ALMSectionKey.hotels]: selectRooms,
    [ALMSectionKey.ground]: selectGrounds,
    [ALMSectionKey.technical]: selectRiders,
    [ALMSectionKey.partyCrew]: selectContacts,
  }

  const currentItems = useAppSelector(selectorForSection[section.key]())
  const currentCount: number = Array.isArray(currentItems)
    ? currentItems.length
    : 0
  const limitIsReached: boolean =
    sectionHasLimit && currentCount >= sectionLimit

  return {
    sectionCanBeEdited,
    sectionHasLimit,
    sectionLimit,
    limitIsReached,
    currentCount,
  }
}

export default useSectionLimit
