import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { Col, List, Row, Skeleton, Space } from 'antd'
import {
  getContractSectionData,
  selectContracts,
} from '../../reducers/ContractReducer'
import { ContractStatus } from '../contract-status/ContractStatus'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import ApprovableItemCard from '../ApprovableItemCard'
import { FileFilled, FileOutlined } from '@ant-design/icons'
import { File } from '../file/File'

const ContractSection = ({}: SectionProps) => {
  const dispatch = useAppDispatch()

  const contracts = useAppSelector(selectContracts())
  const { isLoading } = useAppSelector((state) => state.contract)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getContractSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.contract) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (link === undefined || isLoading) return <Skeleton />

  return (
    <List
      dataSource={contracts}
      renderItem={(contract) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={contract}>
            <Row>
              <Col span={12} style={{ alignContent: 'center' }}>
                <File.Cell id={contract.file} maxWidth={300} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'center' }}
              >
                <ContractStatus.Tag id={contract.status} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'right' }}
              >
                <Actions
                  section={ALMSectionKey.contract}
                  actions={['view', 'update', 'delete']}
                  entity={SBRMType.contract}
                  entityId={contract.id}
                />
              </Col>
            </Row>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default ContractSection
