import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { Col, List, Row, Skeleton, Space } from 'antd'
import { useIntl } from 'react-intl'
import {
  getExternalAdvancingPartyCrewSectionData,
  selectContacts,
} from '../../reducers/ContactReducer'
import { Contact } from '../contact/Contact'
import { selectAllContactAssociations } from '../../reducers/ContactAssociationReducer'
import ApprovableItemCard from '../ApprovableItemCard'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'

const PartyCrewSection = ({}: SectionProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const contacts = useAppSelector(selectContacts())
  const { isLoading } = useAppSelector((state) => state.contact)
  const associations = useAppSelector(selectAllContactAssociations())
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const contactIsApproved = (associationIds: number[]) =>
    associations.filter(
      (a) => associationIds.includes(a.id) && a.isApproved === false
    ).length === 0

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      with: ['associations'],
    }
    dispatch(getExternalAdvancingPartyCrewSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.contactAssociation) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (isLoading) return <Skeleton />

  return (
    <List
      dataSource={contacts}
      renderItem={(contact) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard
            model={{
              isApproved: contactIsApproved(contact.associations ?? []),
            }}
          >
            <Row>
              <Col span={12} style={{ alignContent: 'center' }}>
                <Contact.Avatar id={contact.id} linkToDetails={false} />
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'center' }}
              >
                <Space direction="horizontal" wrap size={2}>
                  {(contact.associations ?? []).map((association) => (
                    <Contact.Association
                      key={association}
                      id={association}
                      closable={false}
                    />
                  ))}
                </Space>
              </Col>
              <Col
                span={6}
                style={{ alignContent: 'center', textAlign: 'right' }}
              >
                <Actions
                  section={ALMSectionKey.partyCrew}
                  actions={['update', 'delete']}
                  entity={SBRMType.contact}
                  entityId={contact.id}
                />
              </Col>
            </Row>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default PartyCrewSection
