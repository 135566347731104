import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { Alert, Form } from 'antd'
import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import {
  createInvoice,
  deleteInvoice,
  updateInvoice,
  updateInvoiceFormData,
} from '../../../reducers/InvoiceReducer'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const InvoiceForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.artist)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) => {
        const data = new FormData()

        data.append(
          'file',
          createForm.getFieldValue('file').fileList[0].originFileObj
        )

        return dispatch(createInvoice(data)).then(() => {
          createForm.resetFields()
          return true
        })
      })

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(
          updateInvoice({
            ...updateForm.getFieldsValue(),
            dueDate: updateForm.getFieldValue('dueDate').format('YYYY-MM-DD'),
          })
        ).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteInvoice(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create form={createForm} isNested={isNested} />
        )}
        {action === 'update' && (
          <Update form={updateForm} isNested={isNested} />
        )}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

InvoiceForm.displayName = 'InvoiceForm'

export type InvoiceFormType = { Form: typeof InvoiceForm }
