import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import axios from 'axios'
import { AppDispatch, RootState } from './store'
import { NOTIFICATIONS_URL } from '../utils/urls'
import { TableParams } from '../models/TableParams'
import { notificationSchema } from '../models/schema'
import { Notification } from '../models/Notification'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NotificationState extends SBState<Notification> {
  read: number | undefined
  unread: number | undefined
}

const initialState: NotificationState = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  read: undefined,
  unread: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    ...baseReducers,
    setReadUnread(
      state,
      action: PayloadAction<{ read: number; unread: number }>
    ) {
      state.read = action.payload.read
      state.unread = action.payload.unread
    },
    decrementUnread(state) {
      if (state.unread == undefined || state.unread == 0) {
        return
      }
      state.unread = state.unread - 1
      state.read = state.read! + 1
    },
    incrementUnread(state) {
      if (state.unread == undefined) {
        return
      }
      state.unread = state.unread + 1
      state.read = state.read! - 1
    },
  },
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getNotificationsSuccess,
  setQuery: setNotificationQuery,
  reset: resetNotificationState,
  resetQueryAndIds: resetNotificationQueryAndIds,
  setReadUnread,
  decrementUnread,
  incrementUnread,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Notification }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectNotifications = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) =>
      ids
        .map((id) => items[id])
        .filter((i) => i) // Filter allow to return only non-null elements
        .sort((a, b) => (a.read > b.read ? 1 : a.read < b.read ? -1 : 0))
    // Sort to have unread first, read last
  )
export const selectAllNotifications = () =>
  createSelector([selectRawItems, selectRawIds], (items, ids) =>
    Object.entries(items).map((i) => i[1])
  )
export const selectSelectedNotification = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectNotificationById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectNotificationsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedNotification =
  (notification: Notification | number) => async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(getIdOrModelId<Notification>(notification))
    )

export const getNotifications = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Notification>(
    NOTIFICATIONS_URL,
    params,
    [notificationSchema],
    slice.actions
  )

export const getNotificationWithId = (id: number) =>
  SBAPIFetchDispatch<Notification>(
    `${NOTIFICATIONS_URL}/${id}`,
    notificationSchema,
    slice.actions
  )

export const createNotification = (notification: Notification) =>
  SBAPICreate<Notification>(
    notification,
    NOTIFICATIONS_URL,
    notificationSchema,
    slice.actions
  )

export const deleteNotification = (notification: Notification) =>
  SBAPIDelete<Notification>(
    notification,
    `${NOTIFICATIONS_URL}/${notification.id}`,
    slice.actions
  )

export const readNotification =
  (notification: Notification) => async (dispatch: AppDispatch) => {
    dispatch(decrementUnread())
    return dispatch(
      SBAPIUpdate<Notification>(
        { ...notification, read: true },
        `${NOTIFICATIONS_URL}/${notification.id}/read`,
        slice.actions
      )
    ).catch((e) => {
      dispatch(incrementUnread())
      throw e
    })
  }

/**
 * Here we just want to send a request to the API
 * And doesn't want to interact with the store
 */
export const readNotificationWithId =
  (notificationId: number) => async (dispatch: AppDispatch) => {
    dispatch(decrementUnread())
    return axios
      .put(`${NOTIFICATIONS_URL}/${notificationId}/read`)
      .catch((e) => {
        dispatch(incrementUnread())
        throw e
      })
  }

export const markAllNotificationAsRead = () => async (dispatch: AppDispatch) =>
  axios.post(`${NOTIFICATIONS_URL}/read`)

export const getNotificationsCount = () => async (dispatch: AppDispatch) =>
  axios
    .get(`${NOTIFICATIONS_URL}/counts`)
    .then((response) => dispatch(setReadUnread(response.data.data)))
