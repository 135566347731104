import { Col, Row } from 'antd'
import { useEffect } from 'react'
import { useAuth } from '../utils/auth/Auth'
import { useAppDispatch, useAppSelector } from '../reducers/hooks'
import {
  getExternalAdvancingLinks,
  selectAllExternalAdvancingLinks,
  setSelectedExternalAdvancingLink,
} from '../reducers/ExternalAdvancingLinkReducer'
import dayjs from 'dayjs'
import EventBookingCell from '../components/EventBookingCell'
import { EventWithBookings } from '../models/types'
import { initialQuery } from '../utils/helpers/crud/models'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const EventSelector = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const dispatch = useAppDispatch()
  const links = useAppSelector(selectAllExternalAdvancingLinks())

  const eventsWithBookings = links
    .reduce((acc: EventWithBookings[], link) => {
      const index = acc.findIndex((e) => e.event.id === link.booking.event.id)

      if (index === -1) {
        acc.push({ event: link.booking.event, bookings: [link.booking] })
        return acc
      }

      acc[index].bookings.push(link.booking)

      return acc
    }, [])
    .sort((a, b) =>
      dayjs(a.event.start_date).isAfter(dayjs(b.event.start_date)) ? -1 : 1
    )

  useEffect(() => {
    // Reset selected link in case we're coming from link page
    dispatch(setSelectedExternalAdvancingLink(''))

    // Fetch all user links
    dispatch(
      getExternalAdvancingLinks({
        ...initialQuery,
        pagination: { current: 1, pageSize: 100 },
      })
    )
  }, [])

  return (
    <Row className="container-row">
      <Col xs={24} className="px-4">
        <PageTitle
          title={intl.formatMessage(
            {
              id: LocalizationKeys.Page.EventSelector.Title,
            },
            {
              name: `${currentUser?.name ?? currentUser?.email}`,
            }
          )}
        />
        <Row gutter={[16, 16]}>
          {eventsWithBookings.map((eventWithBookings) => (
            <Col key={eventWithBookings.event.id} span={6}>
              <EventBookingCell event={eventWithBookings} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default EventSelector
