import { FC } from 'react'
import BookingTypeTag, { BookingTypeTagType } from './TypeTag'
import { BookingAvatar, BookingAvatarType } from './Avatar'
import { BookingAmountsTag, BookingAmountsTagType } from './AmountsTag'

export const Booking: FC &
  BookingAvatarType &
  BookingTypeTagType &
  BookingAmountsTagType = (): JSX.Element => <></>

Booking.TypeTag = BookingTypeTag
Booking.Avatar = BookingAvatar
Booking.AmountsTag = BookingAmountsTag
