import {
  getDealSectionData,
  selectSelectedExternalAdvancingLink,
} from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect, useState } from 'react'
import { Booking } from '../booking/Booking'
import { Card, Skeleton, Space } from 'antd'
import { SectionProps } from '../../models/types'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { BookingWithRelation } from '../../models/Booking'

const DealSection = ({}: SectionProps) => {
  const dispatch = useAppDispatch()
  const link = useAppSelector(selectSelectedExternalAdvancingLink())
  const { isLoading } = useAppSelector((state) => state.booking)

  const [booking, setBooking] = useState<BookingWithRelation | undefined>(
    undefined
  )

  useEffect(() => {
    if (link === undefined) return
    /**
     * To avoid conflicts in the store with the Timetable section
     * The deal section won't go in the store - this is why travelSchema is specified inside getDealSectionData()
     * Therefore we use the data locally
     */
    dispatch(getDealSectionData()).then((response: any) => {
      setBooking(
        Array.isArray(response.data) && response.data.length > 0
          ? response.data[0]
          : undefined
      )
    })
  }, [link])

  if (isLoading || booking === undefined) return <Skeleton />

  return (
    <Card styles={{ body: { padding: 10 } }}>
      <Space size={20} style={{ justifyContent: 'space-between' }}>
        <Booking.AmountsTag model={booking} />
        <Booking.TypeTag model={booking.type} />
      </Space>
    </Card>
  )
}

export default DealSection
