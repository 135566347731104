import { forwardRef, Ref, useImperativeHandle } from 'react'
import { getMetadata, SBRMFormInterface, SBRMFormProps } from '../SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import {
  createRoom,
  deleteRoom,
  updateRoom,
} from '../../../reducers/RoomReducer'
import { MetaDataKey } from '../../../models/MetaData'
import { useSBRMData } from '../hooks'

const RoomForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.room)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(
          createRoom({
            ...createForm.getFieldsValue(),
            from: (createForm.getFieldValue('dates') ?? [null])[0],
            to: (createForm.getFieldValue('dates') ?? [null, null])[1],
          })
        ).then(() => {
          createForm.resetFields()
          return true
        })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(
          updateRoom({
            ...updateForm.getFieldsValue(),
            from: updateForm.getFieldValue('dates')[0],
            to: updateForm.getFieldValue('dates')[1],
          })
        ).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deleteRoom(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {(action === 'create' || action === 'duplicate') && (
          <Create
            form={createForm}
            duplicate={action === 'duplicate'}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
          />
        )}
        {action === 'update' && <Update form={updateForm} />}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

RoomForm.displayName = 'RoomForm'

export default RoomForm
