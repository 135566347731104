import { FC } from 'react'
import { FileCell, FileCellType } from './Cell'
import { FileDropzone, FileDropzoneType } from '../custom-inputs/Dropzone'
import { DownloadButton, DownloadButtonType } from './DownloadButton'

export const File: FC &
  FileCellType &
  DownloadButtonType &
  FileDropzoneType = (): JSX.Element => <></>

File.Cell = FileCell
File.Dropzone = FileDropzone
File.DownloadButton = DownloadButton
