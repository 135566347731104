import { useState } from 'react'
import Roadsheet from './Roadsheet'
import { Button, Tooltip } from 'antd'
import { ROADSHEETS_URL } from '../../utils/urls'
import { DownloadOutlined } from '@ant-design/icons'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RoadsheetData } from '../../models/Roadsheet'
import { SBAPIFetch } from '../../utils/helpers/SBAPIHelper'

interface Props {}

const RoadsheetGenerator = ({}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<RoadsheetData | undefined>(undefined)

  const fetchRoadsheetDataForBooking = () => {
    setIsLoading(true)
    SBAPIFetch<RoadsheetData>(`${ROADSHEETS_URL}/data`).then((response) => {
      setData(response.data)
      setIsLoading(false)
    })
  }

  return (
    <>
      {data === undefined && (
        <Tooltip title={'Generate roadsheet'}>
          <Button
            type="primary"
            className="w-full"
            loading={isLoading}
            style={{ flex: '1 1' }}
            icon={<DownloadOutlined />}
            onClick={fetchRoadsheetDataForBooking}
          >
            Generate roadsheet
          </Button>
        </Tooltip>
      )}
      {data !== undefined && (
        <PDFDownloadLink
          document={<Roadsheet data={data} />}
          fileName={`${data.bookings[0].artist.name}-${data.event.name}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              type="primary"
              className="w-full"
              style={{ flex: '1 1' }}
              icon={<DownloadOutlined />}
            >
              Download roadsheet
            </Button>
          )}
        </PDFDownloadLink>
      )}
    </>
  )
}

export default RoadsheetGenerator
