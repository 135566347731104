import { Tag } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { selectBookingTypeById } from '../../reducers/BookingTypeReducer'
import { BookingType } from '../../models/Booking'

interface Props {
  id?: number
  model?: BookingType
}

const BookingTypeTag = ({ id, model }: Props) => {
  const type = model ? model : useAppSelector(selectBookingTypeById(id!))

  return type ? <Tag key={type.id}>{type.name}</Tag> : <></>
}

export default BookingTypeTag

export type BookingTypeTagType = { TypeTag: typeof BookingTypeTag }
