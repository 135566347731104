import dayjs from 'dayjs'
import { Col, Divider, Row, Space, Steps, Tag, Typography } from 'antd'
import {
  ArrowRightOutlined,
  CarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import { useAppSelector } from '../../reducers/hooks'
import { selectHotelById } from '../../reducers/HotelReducer'
import { selectVenueById } from '../../reducers/VenueReducer'
import { selectGroundById } from '../../reducers/GroundReducer'
import { selectTravelById } from '../../reducers/TravelReducer'
import { selectStationById } from '../../reducers/StationReducer'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { GroundLocation, Ground as GroundModel } from '../../models/Ground'
import { selectAddressById } from '../../reducers/AddressReducer'

const { Text } = Typography

const groundLocationTitle = (location: GroundLocation | undefined) => {
  if (location == undefined) {
    return
  }

  // This is outside if === SBRMType.travel to avoid different number of selector between renders
  const travel = useAppSelector(selectTravelById(location.id))

  if (location.type === SBRMType.hotel) {
    return useAppSelector(selectHotelById(location.id))?.name
  } else if (location.type === SBRMType.venue) {
    return useAppSelector(selectVenueById(location.id))?.name
  } else if (location.type === SBRMType.travel) {
    return useAppSelector(
      selectStationById(
        (travel?.inbound ? travel?.arrival : travel?.departure) ?? 0
      )
    )?.name
  } else if (location.type === SBRMType.customAddress) {
    return useAppSelector(selectAddressById(location.customaddress?.address!))
      ?.label
  }
}

interface Props {
  id?: number
  model?: GroundModel
  width?: number
}

export const Infos = ({ id, model, width }: Props) => {
  const ground = model ? model : useAppSelector(selectGroundById(id!))

  return (
    <>
      {ground && (
        <Row
          style={
            width
              ? { alignItems: 'center', width: width }
              : { alignItems: 'center' }
          }
        >
          <Col span={8}>
            <Space direction="vertical" size={0}>
              <Space direction="horizontal">
                <EnvironmentOutlined />
                <Text>{groundLocationTitle(ground.from)}</Text>
              </Space>
              <Space direction="horizontal">
                <ClockCircleOutlined />
                <Text type="secondary">
                  {dayjs(ground.pickupTime).format('DD/MM HH:mm')}
                </Text>
              </Space>
            </Space>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Tag icon={<ArrowRightOutlined />}>{ground?.duration} mn</Tag>
          </Col>
          <Col span={8}>
            <Space direction="vertical" size={0}>
              <Space direction="horizontal">
                <EnvironmentOutlined />
                <Text>{groundLocationTitle(ground.to)}</Text>
              </Space>
              <Space direction="horizontal">
                <ClockCircleOutlined />
                <Text type="secondary">
                  {dayjs(ground.pickupTime)
                    .add(ground.duration, 'minute')
                    .format('DD/MM HH:mm')}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      )}
    </>
  )
}

export type InfosType = { Infos: typeof Infos }
