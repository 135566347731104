import { FC, useLayoutEffect } from 'react'
import App from '../App'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { LayoutNotLogged } from '../layouts/LayoutNotLogged'
import HandleMagicLink from '../pages/auth/HandleMagicLink'
import EventSelector from '../pages/EventSelector'
import ExternalAdvancing from '../pages/ExternalAdvancing'
import { LayoutLogged } from '../layouts/LayoutLogged'
import LoginPage from '../pages/auth/Login'
import { MainLayout } from '../layouts/MainLayout'

const AppRoutes: FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<App />}>
        <Route element={<LayoutNotLogged />}>
          <Route path="auth/login" element={<LoginPage />} />
          <Route path="auth/magic-link/handle" element={<HandleMagicLink />} />
        </Route>
        <Route element={<LayoutLogged />}>
          <Route element={<MainLayout />}>
            <Route path="event-selector" element={<EventSelector />} />
            <Route path=":uuid" element={<ExternalAdvancing />} />
          </Route>
        </Route>

        <Route index path="*" element={<Navigate to="/auth/login" />} />
      </Route>
    </Routes>
  )
}

export { AppRoutes }
