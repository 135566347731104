import React from 'react'
import { ExternalAdvancingLink } from './ExternalAdvancing'
import DealSection from '../components/sections/DealSection'
import TimetableSection from '../components/sections/TimetableSection'
import ContractSection from '../components/sections/ContractSection'
import { EventWithRelation } from './Event'
import { BookingWithRelation } from './Booking'
import InvoiceSection from '../components/sections/InvoiceSection'
import TravelSection from '../components/sections/TravelSection'
import HotelSection from '../components/sections/HotelSection'
import GroundSection from '../components/sections/GroundSection'
import RiderSection from '../components/sections/RiderSection'
import PartyCrewSection from '../components/sections/PartyCrewSection'
import GuestListSection from '../components/sections/GuestListSection'
import { SBRMType } from '../modules/sbrm/SBRMModel'

interface EventWithBookings {
  event: EventWithRelation
  bookings: BookingWithRelation[]
}

interface SectionProps {}

type ALMSection = {
  key: ALMSectionKey
  icon: React.ReactNode
  itemName: string
  propertyName: keyof ExternalAdvancingLink
  options: ALMSectionOption[]
  component: (props: SectionProps) => JSX.Element
  hasMaxValue?: boolean
  maxValuePropertyName?: keyof ExternalAdvancingLink
  maxValueItemName?: string
  SBRMType: SBRMType
}
enum ALMSectionKey {
  deal = 'deal',
  timetable = 'timetable',
  contract = 'contract',
  invoices = 'invoices',
  travels = 'travels',
  hotels = 'hotels',
  ground = 'ground',
  technical = 'technical',
  partyCrew = 'partyCrew',
}

type ALMSectionOption = 'view' | 'edit' | 'hidden'

const externalAdvancingSections: ALMSection[] = [
  {
    key: ALMSectionKey.deal,
    icon: '🤝',
    itemName: 'acl_deal',
    propertyName: 'aclDeal',
    options: ['view'],
    component: (props) => <DealSection {...props} />,
    SBRMType: SBRMType.booking,
  },
  {
    key: ALMSectionKey.timetable,
    icon: '⏰',
    itemName: 'acl_timetable',
    propertyName: 'aclTimetable',
    options: ['view'],
    component: (props) => <TimetableSection {...props} />,
    SBRMType: SBRMType.performance,
  },
  {
    key: ALMSectionKey.contract,
    icon: '📑',
    itemName: 'acl_contract',
    propertyName: 'aclContract',
    options: ['view'],
    component: (props) => <ContractSection {...props} />,
    SBRMType: SBRMType.contract,
  },
  {
    key: ALMSectionKey.invoices,
    icon: '🧾',
    itemName: 'acl_invoice',
    propertyName: 'aclInvoice',
    options: ['view', 'edit'],
    component: (props) => <InvoiceSection {...props} />,
    SBRMType: SBRMType.invoice,
  },
  {
    key: ALMSectionKey.partyCrew,
    icon: '👥',
    itemName: 'acl_partyCrew',
    propertyName: 'aclPartyCrew',
    options: ['view', 'edit'],
    component: (props) => <PartyCrewSection {...props} />,
    hasMaxValue: true,
    maxValuePropertyName: 'limitPartyCrew',
    maxValueItemName: 'limit_partyCrew',
    SBRMType: SBRMType.contactAssociation,
  },
  {
    key: ALMSectionKey.travels,
    icon: '🛩️',
    itemName: 'acl_travel',
    propertyName: 'aclTravel',
    options: ['view', 'edit'],
    component: (props) => <TravelSection {...props} />,
    hasMaxValue: true,
    maxValuePropertyName: 'limitTravel',
    maxValueItemName: 'limit_travel',
    SBRMType: SBRMType.travel,
  },
  {
    key: ALMSectionKey.hotels,
    icon: '🛏️',
    itemName: 'acl_hotel',
    propertyName: 'aclHotel',
    options: ['view', 'edit'],
    component: (props) => <HotelSection {...props} />,
    hasMaxValue: true,
    maxValuePropertyName: 'limitHotels',
    maxValueItemName: 'limit_hotels',
    SBRMType: SBRMType.room,
  },
  {
    key: ALMSectionKey.ground,
    icon: '🚐',
    itemName: 'acl_ground',
    propertyName: 'aclGround',
    options: ['view', 'edit'],
    component: (props) => <GroundSection {...props} />,
    hasMaxValue: true,
    maxValuePropertyName: 'limitGround',
    maxValueItemName: 'limit_ground',
    SBRMType: SBRMType.ground,
  },
  {
    key: ALMSectionKey.technical,
    icon: '🛠️',
    itemName: 'acl_technical',
    propertyName: 'aclTechnical',
    options: ['view', 'edit'],
    component: (props) => <RiderSection {...props} />,
    SBRMType: SBRMType.rider,
  },

  //   {
  //     key: 'guestList',
  //     icon: '📋',
  //     label: 'Guest list',
  //     itemName: 'acl_guestsList',
  //     propertyName: 'aclGuestsList',
  //     options: ['view', 'edit'],
  //     component: (props) => <GuestListSection {...props} />,
  //     hasMaxValue: true,
  //     maxValuePropertyName: 'limitGuestsList',
  //     maxValueItemName: 'limit_guestsList',
  //     SBRMType: SBRMType.contact,
  //   },
]

export {
  type SectionProps,
  type ALMSection,
  type ALMSectionOption,
  type EventWithBookings,
  ALMSectionKey,
  externalAdvancingSections,
}
