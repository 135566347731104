import { useIntl } from 'react-intl'
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
} from 'antd'
import { numberRule, requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect } from 'react'
import { selectSelectedInvoice } from '../../../reducers/InvoiceReducer'
import { fieldFormatter } from '../../../utils/formatters'
import dayjs from 'dayjs'
import TextArea from 'antd/es/input/TextArea'
import { SBRMFormStepProps, SBRMType } from '../../../modules/sbrm/SBRMModel'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useTenantSettings } from '../../../utils/hooks/useTenantSettings'
import { SBAsyncSelect } from '../../custom-inputs/sb-async-select/SBAsyncSelect'

const { Text } = Typography
interface Props extends SBRMFormStepProps {}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const { currencySymbol } = useTenantSettings()
  const invoice = useAppSelector(selectSelectedInvoice())

  useEffect(() => {
    form.setFieldsValue(invoice)
    if (invoice?.dueDate) {
      form.setFieldValue('dueDate', dayjs(invoice?.dueDate))
    }
  }, [invoice])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        rules={[requiredRule(intl)]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="reference"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.Reference,
            })}
            rules={[stringRule(intl)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dueDate"
            rules={[requiredRule(intl)]}
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.DueDate,
            })}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="amountExcludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountExclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currencySymbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="amountIncludingTaxes"
            label={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Invoice.AmountInclTaxes,
            })}
            rules={[requiredRule(intl), numberRule(intl)]}
          >
            <InputNumber
              min={0}
              className="w-full"
              addonBefore={currencySymbol}
              formatter={fieldFormatter}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">
        <Text style={{ fontSize: 12 }} type="secondary">
          {intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Invoice.Options,
          })}
        </Text>
      </Divider>
      <SBAsyncSelect
        size="middle"
        type={SBRMType.currency}
        name={'currency'}
        rules={[requiredRule(intl)]}
        label={intl.formatMessage({
          id: LocalizationKeys.Misc.Form.Currency,
        })}
      />
      <Form.Item
        name="notes"
        label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Notes })}
        rules={[stringRule(intl)]}
      >
        <TextArea rows={3} />
      </Form.Item>
    </Form>
  )
}

export default Update
