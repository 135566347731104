import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Alert, App, Button, Form, Input, Space, Typography } from 'antd'
import { requestLoginMagicLink } from '../../utils/auth/requests'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { emailRule, requiredRule } from '../../utils/rules'
import { useSearchParams } from 'react-router-dom'

const { Text } = Typography
const LoginPage = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { message } = App.useApp()
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const onSubmit = () => {
    setIsLoading(true)
    setError(undefined)
    requestLoginMagicLink(
      form.getFieldValue('email'),
      searchParams.get('returnTo')
    )
      .then(() => {
        message.success('Magic link sent')
        form.resetFields()
      })
      .catch(() => {
        setError('Unable to send the login magic link')
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Space direction="vertical" size={10}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={'/assets/images/logocurve.png'}
          height={45}
          style={{
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </div>
      <Text>Please enter your email to receive a login link</Text>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
          <Input
            placeholder={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Email,
            })}
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item style={{ margin: 0 }}>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {intl.formatMessage({ id: LocalizationKeys.Auth.Login.Login })}
          </Button>
        </Form.Item>
      </Form>

      {error && (
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <Alert style={{ marginBottom: 25 }} message={error} type="error" />
        </div>
      )}
    </Space>
  )
}

export default LoginPage
