import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './LocalStorageHelper'
import { ExternalAdvancingToken } from '../../models/AuthModel'

const AUTH_LOCAL_STORAGE_TOKEN_KEY = 'alela-external-advancing-auth-v1'

const getAuth = () =>
  getLocalStorageItem<ExternalAdvancingToken>(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const setAuth = (auth: ExternalAdvancingToken) =>
  setLocalStorageItem<ExternalAdvancingToken>(
    AUTH_LOCAL_STORAGE_TOKEN_KEY,
    auth
  )

const removeAuth = () => removeLocalStorageItem(AUTH_LOCAL_STORAGE_TOKEN_KEY)

export { getAuth, setAuth, removeAuth }
