import { Avatar, Space, Tag, Tooltip, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { AvatarProps } from 'antd/es/avatar'
import { Contact as ContactModel } from '../../models/Contact'
import { selectContactById } from '../../reducers/ContactReducer'
import { Contact } from './Contact'
import { ConditionalLinkWrapper } from '@supplement-bacon/alela-uikit'

const { Text } = Typography

interface Props {
  id?: number
  model?: ContactModel
  linkToDetails?: boolean
  size?: AvatarProps['size']
  displayCompany?: boolean
  type?: 'full' | 'condensed'
  editable?: boolean
  tag?: string
  tagIcon?: JSX.Element
}

export const ContactAvatar = ({
  id,
  model,
  displayCompany = true,
  size = 'default',
  type = 'full',
  editable = true,
  tag,
  tagIcon,
  linkToDetails = false,
}: Props) => {
  const contact = model ? model : useAppSelector(selectContactById(id!))

  if (!contact) {
    return <></>
  }

  return (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/crm/contacts/${contact.id}`}
    >
      <Space direction="horizontal">
        <Tooltip
          title={`${contact.firstName} ${contact.lastName ?? ''}`}
          open={type == 'full' ? false : undefined}
        >
          <Avatar
            size={size == 'large' ? 60 : size}
            style={{
              color: '#fff',
              backgroundColor: '#5d5df6',
            }}
          >
            <div style={{ textTransform: 'uppercase' }}>
              {contact.firstName && contact.firstName.slice(0, 1)}
              {contact.lastName && contact.lastName.slice(0, 1)}
              {!contact.firstName && !contact.lastName && '--'}
            </div>
          </Avatar>
        </Tooltip>
        {type == 'full' && (
          <Space.Compact direction="vertical">
            <Space direction="horizontal">
              <Text>
                {contact.firstName} {contact.lastName}
              </Text>
              <Contact.LinkedToUser model={contact} />
            </Space>
            {displayCompany && <Text type="secondary">{contact.company}</Text>}
            {size == 'large' && (
              <Space>
                {contact.email && (
                  <a href={`mailto:${contact.email}`}>
                    <Text type="secondary">{contact.email}</Text>
                  </a>
                )}
                {contact.phone && (
                  <a href={`tel:${contact.phone}`}>
                    <Text type="secondary">{contact.phone}</Text>
                  </a>
                )}
              </Space>
            )}
            {tag && <Tag icon={tagIcon}>{tag}</Tag>}
          </Space.Compact>
        )}
      </Space>
    </ConditionalLinkWrapper>
  )
}

export type ContactAvatarType = { Avatar: typeof ContactAvatar }
