import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import React from 'react'
import { ButtonType } from 'antd/es/button'
import { MetaData } from '../../../models/MetaData'
import { SBRMAction, SBRMType } from '../SBRMModel'
import { Button, Space, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { setEntity } from '../../../reducers/SBRMReducer'
import { selectContactById } from '../../../reducers/ContactReducer'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../UrlHelper'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { ConditionalWrapper } from '@supplement-bacon/alela-uikit'
import { selectSelectedExternalAdvancingLink } from '../../../reducers/ExternalAdvancingLinkReducer'
import { ALMSectionKey, externalAdvancingSections } from '../../../models/types'
import { SBRMTypeInfos } from '../SBRMTypeInfos'
import { deadlineIsReached } from '../../../utils/helpers/LinkHelper'
import useSectionLimit from '../../../utils/hooks/useSectionLimit'
import { selectContactAssociationsByIds } from '../../../reducers/ContactAssociationReducer'

interface Props {
  actions: SBRMAction[]
  entity: SBRMType
  entityId?: number | string
  metadata?: MetaData[]
  trigger?: React.ReactNode
  section: ALMSectionKey
}

export const Actions = ({
  actions,
  entity,
  entityId,
  metadata,
  trigger,
  section: sectionKey,
}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const link = useAppSelector(selectSelectedExternalAdvancingLink())
  const section = externalAdvancingSections.find((s) => s.key === sectionKey)!
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const { limitIsReached } = useSectionLimit(
    sectionKey ?? ALMSectionKey.contract
  )

  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[entityId ?? 0]
  )
  const associations = useAppSelector(
    selectContactAssociationsByIds(
      resource !== undefined && 'associations' in resource
        ? resource.associations
        : []
    )
  )

  const handleSBRMAction = (action: SBRMAction) => {
    if (!SBRMIsOpen) {
      // When the SBRM is closed set the url param
      // If the SBRM is already open it means we are about to open the nested drawer
      // We do not u pdate the url for the nested drawer
      // There is no need to call `setEntity` or `setSelected` here as the SBRM handles all of that when the url search changes
      addUrlParams(
        {
          action: action,
          entity: entity,
          entityId: (entityId ?? '').toString(),
        },
        metadata,
        setSearchParams
      )
    } else {
      // This will set the entity and open the nested QuickAdd drawer
      dispatch(
        setEntity({
          entity: entity,
          action: action,
          metadata: metadata,
        })
      )
    }
  }

  const contactAssociatedToUserMessage = intl.formatMessage({
    id: LocalizationKeys.SBRM.ContactAssociatedWithUserNotEditable,
  })
  const contactAssociatedWrapper = (children: React.ReactElement) => (
    <Tooltip title={contactAssociatedToUserMessage}>
      <fieldset disabled style={{ border: 0, margin: 0, padding: 0 }}>
        {children}
      </fieldset>
    </Tooltip>
  )
  const isAContactAssociatedToAnUser = (): boolean => {
    // Contact associated to users cannot be updated
    if (entityId && entity === SBRMType.contact) {
      const contact = useAppSelector(selectContactById(entityId))
      return contact?.isLinkedToUser ?? false
    }
    return false
  }

  // If trigger is defined we render it
  // Only the first actions - actions[0] - is considered when trigger is defined
  if (trigger) {
    return (
      <ConditionalWrapper
        wrapper={contactAssociatedWrapper}
        condition={isAContactAssociatedToAnUser()}
      >
        <div
          onClick={() => handleSBRMAction(actions[0])}
          style={{ cursor: 'pointer' }}
        >
          {trigger}
        </div>
      </ConditionalWrapper>
    )
  }

  const buttons: Record<
    SBRMAction,
    {
      key: SBRMAction
      type?: ButtonType
      danger?: boolean
      icon?: React.ReactNode
      label?: React.ReactNode
      displayButtonLabel?: boolean
      onClick: () => void
    }
  > = {
    create: {
      key: 'create',
      type: 'primary',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.AddNew }),
      displayButtonLabel: true,
      onClick: () => handleSBRMAction('create'),
    },
    update: {
      key: 'update',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.Update }),
      icon: <EditOutlined />,
      onClick: () => handleSBRMAction('update'),
    },
    delete: {
      key: 'delete',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.Delete }),
      danger: true,
      icon: <DeleteOutlined />,
      onClick: () => handleSBRMAction('delete'),
    },
    duplicate: {
      key: 'duplicate',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.Duplicate }),
      icon: <CopyOutlined />,
      onClick: () => handleSBRMAction('duplicate'),
    },
    view: {
      key: 'view',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.View }),
      icon: <EyeOutlined />,
      onClick: () => handleSBRMAction('view'),
    },
    comment: {
      key: 'comment',
      label: intl.formatMessage({ id: LocalizationKeys.SBRM.Comment }),
      icon: <MessageOutlined />,
      onClick: () => handleSBRMAction('comment'),
    },
  }

  const actionsFilter = (action: SBRMAction): boolean => {
    if (link === undefined) return false

    if (action === 'view') {
      // Only those entities are viewable
      return [SBRMType.invoice, SBRMType.contract, SBRMType.rider].includes(
        entity
      )
    }
    if (action === 'update' || action === 'delete') {
      // First of all, ensure the section is editable
      const linkSectionAcl = link[section.propertyName!]
      if (linkSectionAcl !== 'edit') return false

      // Handle special case for partycrew section
      if (sectionKey === ALMSectionKey.partyCrew) {
        const atLeastOneAssociationIsApproved: boolean = associations
          .map((a) => a.isApproved)
          .includes(true)
        return !atLeastOneAssociationIsApproved
      }

      const resourceIsNotApproved =
        resource !== undefined &&
        'isApproved' in resource &&
        resource.isApproved === false

      return resourceIsNotApproved && !deadlineIsReached(link)
    }
    if (action === 'create') {
      return !limitIsReached
    }

    return false
  }

  return (
    <ConditionalWrapper
      wrapper={contactAssociatedWrapper}
      condition={isAContactAssociatedToAnUser()}
    >
      <Space direction="horizontal">
        {actions.filter(actionsFilter).map((action) => (
          <ConditionalWrapper
            key={action}
            condition={buttons[action].displayButtonLabel !== true}
            wrapper={(children) => (
              <Tooltip
                title={
                  buttons[action].label +
                  ' ' +
                  intl.formatMessage({
                    id: LocalizationKeys.Entity[entity],
                  })
                }
              >
                {children}
              </Tooltip>
            )}
          >
            <Button
              type={buttons[action].type}
              danger={buttons[action].danger}
              icon={buttons[action].icon}
              onClick={buttons[action].onClick}
            >
              {buttons[action].displayButtonLabel === true &&
                buttons[action].label}
            </Button>
          </ConditionalWrapper>
        ))}
      </Space>
    </ConditionalWrapper>
  )
}
