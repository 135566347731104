import { Card, Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { Payment } from '../../models/Payment'
import { selectPaymentById } from '../../reducers/PaymentReducer'
import dayjs from 'dayjs'
import { File } from '../file/File'
import { CurrencyFormat } from '@supplement-bacon/alela-uikit'
import { selectCurrencyById } from '../../reducers/CurrencyReducer'

const { Text } = Typography
interface Props {
  id?: number
  model?: Payment
}

export const PaymentCard = ({ id, model }: Props) => {
  const item = model ? model : useAppSelector(selectPaymentById(id!))
  const currency = useAppSelector(
    selectCurrencyById(item ? item.currency ?? 0 : 0)
  )

  return item ? (
    <Card
      className="w-full"
      style={{ border: '1px solid #274916', background: '#162312' }}
      styles={{ body: { padding: 15 } }}
    >
      <Space className="w-full" style={{ justifyContent: 'space-between' }}>
        <Space direction="vertical" size={0}>
          <Text type="success" strong>
            +{' '}
            <CurrencyFormat
              amount={item.amount}
              currencySymbol={currency}
              type="success"
              strong
            />
          </Text>
          <Text type="success">
            {dayjs(item.date).format('YYYY-MM-DD')} by {item.type}
          </Text>
        </Space>
        <Space>{item.pop && <File.DownloadButton id={item.pop} />}</Space>
      </Space>
    </Card>
  ) : (
    <></>
  )
}

export type PaymentCardType = { Card: typeof PaymentCard }
