import { configureStore } from '@reduxjs/toolkit'
import SBRMReducer from './SBRMReducer'
import ArtistReducer from './ArtistReducer'
import VenueReducer from './VenueReducer'
import StageReducer from './StageReducer'
import VenueTypeReducer from './VenueTypeReducer'
import EventReducer from './EventReducer'
import BookingReducer from './BookingReducer'
import EventStatusReducer from './EventStatusReducer'
import UserReducer from './UserReducer'
import BookingTypeReducer from './BookingTypeReducer'
import HotelReducer from './HotelReducer'
import RoomReducer from './RoomReducer'
import ContactReducer from './ContactReducer'
import ContactTypeReducer from './ContactTypeReducer'
import ContactAssociationReducer from './ContactAssociationReducer'
import FileReducer from './FileReducer'
import FileTypeReducer from './FileTypeReducer'
import TravelReducer from './TravelReducer'
import TravelTypeReducer from './TravelTypeReducer'
import RiderReducer from './RiderReducer'
import ContractReducer from './ContractReducer'
import ContractStatusReducer from './ContractStatusReducer'
import GroundReducer from './GroundReducer'
import InvoiceReducer from './InvoiceReducer'
import PerformanceReducer from './PerformanceReducer'
import StationReducer from './StationReducer'
import StationTypeReducer from './StationTypeReducer'
import AddressReducer from './AddressReducer'
import CalendarReducer from './CalendarReducer'
import PaymentReducer from './PaymentReducer'
import NotificationReducer from './NotificationReducer'
import RiderStatusReducer from './RiderStatusReducer'
import CommentReducer from './CommentReducer'
import ExternalAdvancingLinkReducer from './ExternalAdvancingLinkReducer'
import ExternalUserReducer from './ExternalUserReducer'
import CurrencyReducer from './CurrencyReducer'

export const store = configureStore({
  reducer: {
    SBRM: SBRMReducer,
    event: EventReducer,
    eventStatus: EventStatusReducer,
    artist: ArtistReducer,
    venue: VenueReducer,
    venueType: VenueTypeReducer,
    stage: StageReducer,
    booking: BookingReducer,
    bookingType: BookingTypeReducer,
    user: UserReducer,
    hotel: HotelReducer,
    room: RoomReducer,
    contact: ContactReducer,
    contactType: ContactTypeReducer,
    contactAssociation: ContactAssociationReducer,
    file: FileReducer,
    fileType: FileTypeReducer,
    contract: ContractReducer,
    contractStatus: ContractStatusReducer,
    invoice: InvoiceReducer,
    payment: PaymentReducer,
    rider: RiderReducer,
    riderStatus: RiderStatusReducer,
    travel: TravelReducer,
    travelType: TravelTypeReducer,
    ground: GroundReducer,
    performance: PerformanceReducer,
    station: StationReducer,
    stationType: StationTypeReducer,
    address: AddressReducer,
    calendar: CalendarReducer,
    notification: NotificationReducer,
    comment: CommentReducer,
    externalUser: ExternalUserReducer,
    externalAdvancingLink: ExternalAdvancingLinkReducer,
    currency: CurrencyReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
