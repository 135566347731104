import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { List, Skeleton, Space } from 'antd'
import { useIntl } from 'react-intl'
import {
  getExternalAdvancingGroundSectionData,
  selectGrounds,
} from '../../reducers/GroundReducer'
import { Ground } from '../ground/Ground'
import { Contact } from '../contact/Contact'
import { CarOutlined } from '@ant-design/icons'
import ApprovableItemCard from '../ApprovableItemCard'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'
import { MetaDataKey } from '../../models/MetaData'

const GroundSection = ({}: SectionProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const grounds = useAppSelector(selectGrounds())
  const { isLoading } = useAppSelector((state) => state.ground)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getExternalAdvancingGroundSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.ground) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (link === undefined || isLoading) return <Skeleton />

  return (
    <List
      dataSource={grounds}
      renderItem={(ground) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={ground}>
            <Space
              className="w-full"
              style={{ justifyContent: 'space-between' }}
            >
              <Space>
                <Ground.Infos id={ground.id} width={400} />
              </Space>

              {ground.driver && (
                <Contact.Avatar
                  id={ground.driver}
                  displayCompany={false}
                  tag={ground.vehicleDetails}
                  tagIcon={<CarOutlined />}
                />
              )}

              <Contact.AvatarGroup
                ids={ground.passengers}
                linkToDetails={false}
              />
              <Actions
                section={ALMSectionKey.ground}
                actions={['update', 'delete']}
                entity={SBRMType.ground}
                entityId={ground.id}
                metadata={[
                  { key: MetaDataKey.eventId, value: link.booking.event.id },
                ]}
              />
            </Space>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default GroundSection
