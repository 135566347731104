import { useAuth } from '../utils/auth/Auth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { LoadingFullScreen } from './LoadingFullScreen'
import { User } from '../components/user/User'

export const LayoutLogged: React.FC = () => {
  const { pathname } = useLocation()
  const { isLogged, currentUser } = useAuth()

  if (isLogged === false) {
    // We're not checking !isLogged as isLogged might be undefined during the account fetching on init
    // Ensure redirection to home page if the user is logged
    return <Navigate to={'/auth/login?returnTo=' + pathname} />
  }

  if (currentUser === undefined) {
    // While the user is loading display loading state full screen
    return <LoadingFullScreen />
  }

  return (
    <>
      {!currentUser.name && (
        <User.CompleteProfileModal isOpen={true} closable={false} />
      )}
      <Outlet />
    </>
  )
}
