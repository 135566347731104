import { Navigate } from 'react-router-dom'
import { useAuth } from '../utils/auth/Auth'
import { LayoutSemiColumn } from '@supplement-bacon/alela-uikit'

export const LayoutNotLogged: React.FC = () => {
  const { isLogged } = useAuth()

  if (isLogged === true) {
    // Ensure redirection to home page if the user is logged
    return <Navigate to={'/event-selector'} />
  }

  return (
    <LayoutSemiColumn background="url('/assets/images/file-sharing-background.jpg')" />
  )
}
