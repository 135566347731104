import { ALMSectionKey, SectionProps } from '../../models/types'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedExternalAdvancingLink } from '../../reducers/ExternalAdvancingLinkReducer'
import { useEffect } from 'react'
import { initialQuery } from '../../utils/helpers/crud/models'
import { TableParams } from '../../models/TableParams'
import { List, Skeleton, Space } from 'antd'
import { Travel } from '../travel/Travel'
import {
  getExternalAdvancingTravelSectionData,
  selectTravels,
} from '../../reducers/TravelReducer'
import { Contact } from '../contact/Contact'
import ApprovableItemCard from '../ApprovableItemCard'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Actions } from '../../modules/sbrm/components/Actions'

const TravelSection = ({}: SectionProps) => {
  const dispatch = useAppDispatch()

  const travels = useAppSelector(selectTravels())
  const { isLoading } = useAppSelector((state) => state.travel)
  const { isOpen, entity } = useAppSelector((state) => state.SBRM)
  const link = useAppSelector(selectSelectedExternalAdvancingLink())

  const buildQueryAndFetch = () => {
    const query: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
    }
    dispatch(getExternalAdvancingTravelSectionData(query))
  }

  useEffect(() => {
    if (link === undefined) return
    buildQueryAndFetch()
  }, [link])

  useEffect(() => {
    if (isOpen || entity !== SBRMType.travel) return
    // Refresh list on SBRM closing entity
    buildQueryAndFetch()
  }, [isOpen])

  if (isLoading) return <Skeleton />

  return (
    <List
      dataSource={travels}
      renderItem={(travel) => (
        <List.Item style={{ border: 0, paddingBottom: 0 }}>
          <ApprovableItemCard model={travel}>
            <Space
              className="w-full"
              style={{ justifyContent: 'space-between' }}
            >
              <Travel.Type id={travel.id} />
              <Travel.Infos id={travel.id} />
              <Contact.AvatarGroup
                ids={travel.contacts}
                linkToDetails={false}
              />
              <Actions
                section={ALMSectionKey.travels}
                actions={['update', 'delete']}
                entity={SBRMType.travel}
                entityId={travel.id}
              />
            </Space>
          </ApprovableItemCard>
        </List.Item>
      )}
    />
  )
}

export default TravelSection
