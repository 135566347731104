import qs from 'qs'
import axios from 'axios'
import {
  LOGGED_USER_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REQUEST_MAGIC_LINK_URL,
} from '../urls'
import { APIResponse } from '../../models/APIResponse'
import { ExternalAdvancingLoginResult } from '../../models/AuthModel'
import { ExternalAdvancingUser } from '../../models/ExternalAdvancing'

export function requestLoginMagicLink(email: string, returnTo: string | null) {
  return axios.get<ExternalAdvancingLoginResult>(
    `${REQUEST_MAGIC_LINK_URL}?${qs.stringify({
      email: email,
      returnTo: returnTo,
    })}`
  )
}

export function login(
  expires: string | null,
  token: string | null,
  returnTo: string | null,
  signature: string | null
) {
  return axios.post(
    `${LOGIN_URL}?${qs.stringify({
      expires,
      token,
      returnTo,
      signature,
    })}`
  )
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

export function getLoggedUserInfos() {
  return axios.get<APIResponse<ExternalAdvancingUser>>(LOGGED_USER_URL)
}

export function updateLoggedUserInfos(user: ExternalAdvancingUser) {
  return axios.put(LOGGED_USER_URL, user)
}
