import { FC } from 'react'
import { ReferenceTag, ReferenceTagType } from './ReferenceTag'
import { AmountsTag, AmountsTagType } from './AmountsTag'
import { InvoiceForm, InvoiceFormType } from './form/Form'

export const Invoice: FC &
  AmountsTagType &
  ReferenceTagType &
  InvoiceFormType = (): JSX.Element => <></>

Invoice.ReferenceTag = ReferenceTag
Invoice.AmountsTag = AmountsTag
Invoice.Form = InvoiceForm
