import { LocalizationKey } from '../LocalizationKeys'

const EN: Record<LocalizationKey, string> = {
  [LocalizationKey.AuthForgotPasswordButton]: 'Reset password',
  [LocalizationKey.AuthForgotPasswordBackToLogin]: 'Back to login',
  [LocalizationKey.AuthForgotPasswordSuccess]:
    'In case you have an account with us you will receive a password reset link - Check also your spam inbox',
  [LocalizationKey.AuthForgotPasswordFailure]: 'An error occured',

  [LocalizationKey.AuthInvitationErrorWhileAccepting]:
    'Error while accepting invitation. Try again later...',
  [LocalizationKey.AuthInvitationErrorWhileLoading]:
    'Error while loading the invitation. Please make sure your invitation code is correct.',
  [LocalizationKey.AuthInvitationTitle]: 'You have been invited!',
  [LocalizationKey.AuthInvitationNeedsYou]: 'needs you in the team',
  [LocalizationKey.AuthInvitationAccept]: 'Accept the invitation',
  [LocalizationKey.AuthInvitationRegister]: 'Register',
  [LocalizationKey.AuthInvitationAlreadyHaveAnAccount]:
    'Already have an account ? Login',

  [LocalizationKey.AuthLoginError]:
    'Ensure your email/password is correct. If the problem persists contact your account administrator.',
  [LocalizationKey.AuthLoginSSOError]:
    'SAML SSO not configured for this Organization',
  [LocalizationKey.AuthLoginSSO]: 'SAML SSO',
  [LocalizationKey.AuthLogin]: 'Login',
  [LocalizationKey.AuthLoginForgotPassword]: 'Forgot password ?',
  [LocalizationKey.AuthLoginContinueWithSSO]: 'Continue with SAML SSO',
  [LocalizationKey.AuthLoginLoginWithMicrosoft]: 'Login with Microsoft',
  [LocalizationKey.AuthLoginEmailConnexion]: 'Email connexion',
  [LocalizationKey.AuthLoginRedirect]:
    'We need a few seconds to log you in ...',

  [LocalizationKey.AuthRegisterError]:
    'Error while registering, try again later ...',
  [LocalizationKey.AuthRegisterErrorEmailAlreadyExists]:
    'There is already an account registered with this email. Please login with the link below',
  [LocalizationKey.AuthRegisterTitle]: 'Join the future',
  [LocalizationKey.AuthRegisterInvitedYouToJoinTheTeam]:
    'invited you to join the team',
  [LocalizationKey.AuthRegister]: 'Register',
  [LocalizationKey.AuthRegisterAlreadyHaveAnAccount]:
    'Already have an account ? Login',
  [LocalizationKey.AuthRegisterSuccess]: 'You can now login',
  [LocalizationKey.AuthRegisterGoToLogin]: 'Go to login',

  [LocalizationKey.PageArtistsTitle]: 'Artists',
  [LocalizationKey.PageArtistsName]: 'Name',
  [LocalizationKey.PageArtistsBookingsCount]: 'Bookings count',
  [LocalizationKey.PageArtistLastFee]: 'Last fee',
  [LocalizationKey.PageArtistAverageFee]: 'Average fee',
  [LocalizationKey.PageArtistMinimumFee]: 'Minimum fee',
  [LocalizationKey.PageArtistMaximumFee]: 'Maximum fee',
  [LocalizationKey.PageArtistBookings]: 'Bookings',
  [LocalizationKey.PageArtistListen]: 'Listen',
  [LocalizationKey.PageArtistCrew]: 'Crew',
  [LocalizationKey.PageArtistFiles]: 'Files',

  [LocalizationKey.PagePreferenceTitle]: 'Preferences',
  [LocalizationKey.PagePreferenceLocale]: 'Locale',

  [LocalizationKey.PageProfileTitle]: 'Profile',
  [LocalizationKey.PageProfileUpdateSuccess]: 'Profile updated!',
  [LocalizationKey.PageProfileUpdateFailure]:
    'Error while updating profile... Try again later.',

  [LocalizationKey.PageSettingsOrganizationTitle]: 'Organization',
  [LocalizationKey.PageSettingsOrganizationBillingInfos]: 'Billing infos',
  [LocalizationKey.PageSettingsOrganizationPlan]: 'Plan',
  [LocalizationKey.PageSettingsOrganizationRoles]: 'Roles & access',

  [LocalizationKey.PageSettingsOrganizationPreferencesTitle]:
    "Préférences de l'organisation",
  [LocalizationKey.PageSettingsOrganizationPreferencesUnitsOfMeasurementDescription]:
    'Used for the event report weather',
  [LocalizationKey.PageSettingsOrganizationPreferencesLocaleDescription]:
    'Defines the default language for new users and notifications',
  [LocalizationKey.PageSettingsOrganizationPreferencesMetricLabel]:
    'Metric - °C / meter/sec',
  [LocalizationKey.PageSettingsOrganizationPreferencesImperialLabel]:
    'Imperial - °F / miles/hour',

  [LocalizationKey.PageAccountActivityTitle]: 'Activity',
  [LocalizationKey.PageAccountActivityToolbar]: '20 last actions',

  [LocalizationKey.PageCRMContactsTitle]: 'Contacts',
  [LocalizationKey.PageCRMContactsName]: 'Name',
  [LocalizationKey.PageCRMContactsSearch]: 'Search contact...',
  [LocalizationKey.PageCRMContactsMerge]: 'Merge contacts',
  [LocalizationKey.PageCRMContactLoading]: 'Loading contact ...',
  [LocalizationKey.PageCRMContactBack]: 'Back',
  [LocalizationKey.PageCRMContactWorkFor]: 'Work for',
  [LocalizationKey.PageCRMContactMergerSuccess]: 'Contacts merged with success',
  [LocalizationKey.PageCRMContactMergerFailure]:
    'Error while merging the contacts',
  [LocalizationKey.PageCRMContactMergerTitle]: 'Contact merger',
  [LocalizationKey.PageCRMContactMergerDescription]: `The contact merger allow you to eliminate duplicate contacts.
Contacts associated with user cannot be merged with an other contact.`,
  [LocalizationKey.PageCRMContactMergerSelectContact]: 'Select contact',
  [LocalizationKey.PageCRMContactMergerContactToKeep]: 'Contact to keep',
  [LocalizationKey.PageCRMContactMergerContactToDelete]: 'Contact to delete',
  [LocalizationKey.PageCRMContactMergerMergeButton]: 'Merge the contacts',

  [LocalizationKey.PageCRMSuppliersTitle]: 'Suppliers',
  [LocalizationKey.PageCRMSuppliersName]: 'Name',
  [LocalizationKey.PageCRMSuppliersType]: 'Type',
  [LocalizationKey.PageCRMSuppliersSupplierBalance]: 'Supplier balance',
  [LocalizationKey.PageCRMSuppliersContacts]: 'Contacts',
  [LocalizationKey.PageCRMSuppliersSearch]: 'Search supplier...',
  [LocalizationKey.PageCRMSupplierLoading]: 'Loading supplier ...',
  [LocalizationKey.PageCRMSupplierBack]: 'Back',
  [LocalizationKey.PageCRMSupplierUpdatePicture]: 'Update picture',
  [LocalizationKey.PageCRMSupplierTeam]: 'Team',
  [LocalizationKey.PageCRMSupplierInvoices]: 'Invoices',

  [LocalizationKey.PageCRMUserLoading]: 'Loading user details ...',
  [LocalizationKey.PageCRMUserBack]: 'Back',
  [LocalizationKey.PageCRMUserAssociatedContact]: 'Associated contact',
  [LocalizationKey.PageCRMUserEvents]: 'Events',
  [LocalizationKey.PageCRMUserActivities]: 'Activities',

  [LocalizationKey.PageCRMTeamName]: 'Name',
  [LocalizationKey.PageCRMTeamRole]: 'Role',
  [LocalizationKey.PageCRMTeamTitle]: 'Team',
  [LocalizationKey.PageCRMTeamInvitations]: 'Invitations',

  [LocalizationKey.PageCRMInvitationsTitle]: 'Pending Invitations',
  [LocalizationKey.PageCRMInvitationsBack]: 'Back',
  [LocalizationKey.PageCRMInvitationsInvitedAt]: 'Invited at',

  [LocalizationKey.PageEventDetailsContacts]: 'Contacts',
  [LocalizationKey.PageEventDetailsFiles]: 'Files',
  [LocalizationKey.PageEventDetailsTimetable]: 'Timetable',
  [LocalizationKey.PageEventDetailsPartyCrew]: 'Party Crew',
  [LocalizationKey.PageEventDetailsLineUpArtist]: 'Artist',
  [LocalizationKey.PageEventDetailsLineUpPrice]: 'Price',
  [LocalizationKey.PageEventDetailsLineUpType]: 'Type',
  [LocalizationKey.PageEventDetailsLineUpMemoDeal]: 'Memo deal',
  [LocalizationKey.PageEventDetailsLineUpOwner]: 'Owner',
  [LocalizationKey.PageEventDetailsLineUp]: 'Line-up',
  [LocalizationKey.PageEventDetailsContractsTitle]: 'Contracts',
  [LocalizationKey.PageEventDetailsContractsBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsContractsStatus]: 'Status',
  [LocalizationKey.PageEventDetailsContractsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsInvoicesTitle]: 'Invoices',
  [LocalizationKey.PageEventDetailsInvoicesAmountPaid]: 'Amount paid',
  [LocalizationKey.PageEventDetailsInvoicesAmountToPay]: 'Amount to pay',
  [LocalizationKey.PageEventDetailsInvoicesInvoicesPaid]: 'Invoices paid',
  [LocalizationKey.PageEventDetailsInvoicesInvoicesToPay]: 'Invoices to pay',
  [LocalizationKey.PageEventDetailsReportingTitle]: 'Reporting',
  [LocalizationKey.PageEventDetailsGroundsBookings]: 'Booking',
  [LocalizationKey.PageEventDetailsGroundsInfos]: 'Infos',
  [LocalizationKey.PageEventDetailsGroundsDriver]: 'Driver',
  [LocalizationKey.PageEventDetailsGroundsPassengers]: 'Passengers',
  [LocalizationKey.PageEventDetailsGroundsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsGroundsGroundNotes]: 'Ground notes',
  [LocalizationKey.PageEventDetailsGroundsGround]: 'Ground',
  [LocalizationKey.PageEventDetailsHotelsBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsHotelsHotel]: 'Hotel',
  [LocalizationKey.PageEventDetailsHotelsPrice]: 'Price',
  [LocalizationKey.PageEventDetailsHotelsDates]: 'Dates',
  [LocalizationKey.PageEventDetailsHotelsNights]: 'nights',
  [LocalizationKey.PageEventDetailsHotelsTravelers]: 'Travelers',
  [LocalizationKey.PageEventDetailsHotelsNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsHotelsTitle]: 'Hotels rooms',
  [LocalizationKey.PageEventDetailsTechnicalBooking]: 'Booking',
  [LocalizationKey.PageEventDetailsTechnicalStatus]: 'Status',
  [LocalizationKey.PageEventDetailsTechnicalNotes]: 'Notes',
  [LocalizationKey.PageEventDetailsTechnicalTitle]: 'Riders',
  [LocalizationKey.PageEventDetailsTravelsBooking]: 'Boooking',
  [LocalizationKey.PageEventDetailsTravelsType]: 'Type',
  [LocalizationKey.PageEventDetailsTravelsTravel]: 'Travel',
  [LocalizationKey.PageEventDetailsTravelsTravelers]: 'Travelers',
  [LocalizationKey.PageEventDetailsTravelsTitle]: 'Travels',

  [LocalizationKey.PageFinancialInvoiceTitle]: 'Invoices',
  [LocalizationKey.PageFinancialReportsTitle]: 'Reports',

  [LocalizationKey.PageReleaseNotesTitle]: 'Release notes',

  [LocalizationKey.PageCalendarToday]: 'Today',
  [LocalizationKey.PageCalendarAll]: 'All',
  [LocalizationKey.PageCalendarMore]: 'more',

  [LocalizationKey.PageSettingsVenuesName]: 'Name',
  [LocalizationKey.PageSettingsVenuesType]: 'Type',
  [LocalizationKey.PageSettingsVenuesStages]: 'Stages',
  [LocalizationKey.PageSettingsVenuesOpeningRange]: 'Opening range',
  [LocalizationKey.PageSettingsVenuesTitle]: 'Venues',
  [LocalizationKey.PageSettingsVenueStaff]: 'Staff',
  [LocalizationKey.PageSettingsVenueStages]: 'Stages',
  [LocalizationKey.PageSettingsVenueFiles]: 'Files',
  [LocalizationKey.PageSettingsVenuesSeeArchived]: 'Archived venues',
  [LocalizationKey.PageSettingsArchivedVenuesTitle]: 'Archived venues',
  [LocalizationKey.PageSettingsArchivedVenuesRestore]: 'Restore',

  [LocalizationKey.PageSettingsContactTypesTitle]: 'Contact types',
  [LocalizationKey.PageSettingsContractStatusTitle]: 'Contract statuses',

  [LocalizationKey.PageSettingsEventStatusDefaultStatus]: 'Default status',
  [LocalizationKey.PageSettingsEventStatusVisibleBy]: 'Visible by',
  [LocalizationKey.PageSettingsEventStatusTitle]: 'Event statuses',

  [LocalizationKey.PageSettingsHotelsAddress]: 'Address',
  [LocalizationKey.PageSettingsHotelsStars]: 'Stars',
  [LocalizationKey.PageSettingsHotelsTitle]: 'Hotels',

  [LocalizationKey.PageSettingsRiderStatusTitle]: 'Rider statuses',
  [LocalizationKey.PageSettingsStationsTitle]: 'Stations',
  [LocalizationKey.PageSettingsVenueTypesTitle]: 'Venue types',

  [LocalizationKey.PageSettingsReportingItemsTitle]: 'Report item',
  [LocalizationKey.PageSettingsReportingItemsReorder]: 'Reorder',
  [LocalizationKey.PageSettingsReportingReorderItemsPageTitle]:
    'Reorder report items',
  [LocalizationKey.PageSettingsReportingReorderItemsPageCosts]: 'Costs',
  [LocalizationKey.PageSettingsReportingReorderItemsPageRevenues]: 'Revenues',

  [LocalizationKey.PageEventSelectorTitle]: 'Hey, {name}',

  [LocalizationKey.PageExternalAdvancingYouCanEditUntill]:
    'You can edit sections until {date} ({fromNow})',
  [LocalizationKey.PageExternalAdvancingOpenChat]: 'Open chat',
  [LocalizationKey.PageExternalAdvancingYourContact]: 'Your contact',
  [LocalizationKey.PageExternalAdvancingYourArtist]: 'Your artist',
  [LocalizationKey.PageExternalAdvancingDeadlineReadOnly]: 'Read-only mode',
  [LocalizationKey.PageExternalAdvancingDeadlineDescription]:
    'The deadline has been reached. Therefore you cannot edit resources anymore.',

  /**
   * EXTERNAL ADVANCING
   */

  [LocalizationKey.ExternalAdvancingDeal]: 'Deal',
  [LocalizationKey.ExternalAdvancingTimetable]: 'Timetable',
  [LocalizationKey.ExternalAdvancingContract]: 'Contract',
  [LocalizationKey.ExternalAdvancingInvoices]: 'Invoices',
  [LocalizationKey.ExternalAdvancingTravels]: 'Travels',
  [LocalizationKey.ExternalAdvancingHotels]: 'Hotels',
  [LocalizationKey.ExternalAdvancingGround]: 'Ground',
  [LocalizationKey.ExternalAdvancingTechnical]: 'Technical riders',
  [LocalizationKey.ExternalAdvancingPartyCrew]: 'Party crew',

  /**
   * LAYOUT
   */

  [LocalizationKey.LayoutEventDetailsSiderLineup]: 'Lineup',
  [LocalizationKey.LayoutEventDetailsSiderTimetable]: 'Timetable',
  [LocalizationKey.LayoutEventDetailsSiderAdmin]: 'Admin',
  [LocalizationKey.LayoutEventDetailsSiderContracts]: 'Contracts',
  [LocalizationKey.LayoutEventDetailsSiderInvoices]: 'Invoices',
  [LocalizationKey.LayoutEventDetailsSiderReporting]: 'Reporting',
  [LocalizationKey.LayoutEventDetailsSiderProduction]: 'Production',
  [LocalizationKey.LayoutEventDetailsSiderTravels]: 'Travels',
  [LocalizationKey.LayoutEventDetailsSiderHotels]: 'Hotels',
  [LocalizationKey.LayoutEventDetailsSiderGround]: 'Ground',
  [LocalizationKey.LayoutEventDetailsSiderTechnical]: 'Technical',
  [LocalizationKey.LayoutEventDetailsSiderPartyCrew]: 'Party Crew',
  [LocalizationKey.LayoutEventDetailsSiderFiles]: 'Files',
  [LocalizationKey.LayoutEventDetailsSiderContacts]: 'Contacts',
  [LocalizationKey.LayoutEventDetailsSiderSettings]: 'Settings',
  [LocalizationKey.LayoutEventDetailsSiderTasks]: 'Checklist',

  [LocalizationKey.LayoutMainHeaderEvents]: 'Events',
  [LocalizationKey.LayoutMainHeaderArtists]: 'Artists',
  [LocalizationKey.LayoutMainHeaderFinancials]: 'Financials',
  [LocalizationKey.LayoutMainHeaderCRM]: 'CRM',

  [LocalizationKey.LayoutProfileSiderInformations]: 'Informations',
  [LocalizationKey.LayoutProfileSiderActivity]: 'Activity',
  [LocalizationKey.LayoutProfileSiderPreferences]: 'Preferences',
  [LocalizationKey.LayoutProfileSiderLogout]: 'Logout',
  [LocalizationKey.LayoutProfileSiderLogoutConfirmation]:
    'Are you sure you want to logout ?',

  [LocalizationKey.LayoutRightHeaderProfile]: 'Profile',
  [LocalizationKey.LayoutRightHeaderSettings]: 'Settings',
  [LocalizationKey.LayoutRightHeaderLang]: 'Lang',

  [LocalizationKey.LayoutSettingsSiderUploadOrganizationImage]:
    'Upload Organization image',
  [LocalizationKey.LayoutSettingsSiderVenues]: 'Venues',
  [LocalizationKey.LayoutSettingsSiderHotels]: 'Hotels',
  [LocalizationKey.LayoutSettingsSiderStations]: 'Stations',
  [LocalizationKey.LayoutSettingsSiderReportingItems]: 'Reporting',
  [LocalizationKey.LayoutSettingsSiderVenueTypes]: 'Venue types',
  [LocalizationKey.LayoutSettingsSiderContactTypes]: 'Contact types',
  [LocalizationKey.LayoutSettingsSiderEventStatuses]: 'Event statuses',
  [LocalizationKey.LayoutSettingsSiderContractStatuses]: 'Contract statuses',
  [LocalizationKey.LayoutSettingsSiderRiderStatuses]: 'Rider statuses',
  [LocalizationKey.LayoutSettingsSiderOrganization]: 'Organization',
  [LocalizationKey.LayoutSettingsSiderPreferences]: 'Preferences',

  [LocalizationKey.LayoutAuthContactUs]: 'Contact us',
  [LocalizationKey.LayoutAuthDiscover]: 'Discover Alela',
  [LocalizationKey.LayoutAuthLegal]: 'Legal',

  [LocalizationKey.LayoutFinancialInvoices]: 'Invoices',
  [LocalizationKey.LayoutFinancialReports]: 'Reports',

  [LocalizationKey.LayoutCRMContacts]: 'Contacts',
  [LocalizationKey.LayoutCRMSuppliers]: 'Suppliers',
  [LocalizationKey.LayoutCRMOrganization]: 'Organization',

  [LocalizationKey.LayoutFooterReleaseNotes]: 'Release notes',

  /**
   * FILE SHARING
   */

  [LocalizationKey.FileSharingExpires]: 'Expires',
  [LocalizationKey.FileSharingDownload]: 'Download',

  /**
   * FILE VIEWER
   */

  [LocalizationKey.FileViewerDownload]: 'Download',
  [LocalizationKey.FileViewerPDFViewerError]:
    'Error when trying to open the file ... try again later',

  /**
   * SEARCH DRAWER
   */

  [LocalizationKey.SearchDrawerSearch]: 'Search',
  [LocalizationKey.SearchDrawerPlaceholder]: 'Type to search',
  [LocalizationKey.SearchDrawerEvents]: 'Events',
  [LocalizationKey.SearchDrawerVenues]: 'Venue',
  [LocalizationKey.SearchDrawerArtists]: 'Artists',
  [LocalizationKey.SearchDrawerSuppliers]: 'Suppliers',

  /**
   * SBRM
   */

  [LocalizationKey.SBRMEntityNotDefined]:
    'Choose what to do first and come back later',
  [LocalizationKey.SBRMClose]: 'Close',
  [LocalizationKey.SBRMDelete]: 'Delete',
  [LocalizationKey.SBRMSave]: 'Save',
  [LocalizationKey.SBRMUpdate]: 'Update',
  [LocalizationKey.SBRMContactAssociatedWithUserNotEditable]:
    'Contacts associated with users are not editable',
  [LocalizationKey.SBRMAddNew]: 'Add new',
  [LocalizationKey.SBRMDuplicate]: 'Duplicate',
  [LocalizationKey.SBRMView]: 'View',
  [LocalizationKey.SBRMComment]: 'Comment',
  [LocalizationKey.SBRMTitleAddNew]: 'Add new {type}',
  [LocalizationKey.SBRMTitleCreateNew]: 'Create new {type}',
  [LocalizationKey.SBRMTitleEdit]: 'Edit {type}',
  [LocalizationKey.SBRMTitleDelete]: 'Delete {type}',
  [LocalizationKey.SBRMTitleComment]: 'Comment {type}',
  [LocalizationKey.SBRMTitleView]: 'View {type}',
  [LocalizationKey.SBRMTitleUpload]: 'Upload {type}',
  [LocalizationKey.SBRMTitleDuplicate]: 'Duplicate {type}',
  [LocalizationKey.SBRMTitleAssign]: 'Assign {type}',
  [LocalizationKey.SBRMTitleInvite]: 'Invite {type}',
  [LocalizationKey.SBRMTitleCancel]: 'Cancel {type}',

  /**
   * SBAsyncSelect
   */
  [LocalizationKey.SBAsyncSelectNotFound]: 'No {type} found',

  /**
   * COMPONENTS
   */

  [LocalizationKey.ComponentsBookingContactsTableContactsFound]:
    'Contacts found! Click here to assign artist contacts to this event !',
  [LocalizationKey.ComponentsBookingMissingContactsInPartyCrewTitle]:
    'Unattached party crew contacts',
  [LocalizationKey.ComponentsBookingTableEvent]: 'Event',
  [LocalizationKey.ComponentsBookingTableVenue]: 'Venue',
  [LocalizationKey.ComponentsBookingTablePrice]: 'Fee',
  [LocalizationKey.ComponentsBookingTableMemoDeal]: 'Memo deal',
  [LocalizationKey.ComponentsCommentEditorMentionPlaceholder]:
    'Type your answer here',
  [LocalizationKey.ComponentsCommentEditorGIFPlaceholder]: 'Search GIF',
  [LocalizationKey.ComponentsCommentEditorSend]: 'Send',
  [LocalizationKey.ComponentsCommentListSendFirstMessage]:
    'Send your first message in this thread',
  [LocalizationKey.ComponentsCommentListUseTag]:
    'Use {tag} to tag and notify a member',
  [LocalizationKey.ComponentsCommentSubscribeToConversationSubscribe]:
    'Subscribe to conversation',
  [LocalizationKey.ComponentsCommentSubscribeToConversationUnsubscribe]:
    'Unsubscribe to conversation',
  [LocalizationKey.ComponentsContactLinkedToUser]:
    'This contact is linked to an user',
  [LocalizationKey.ComponentsContactTableWithAssociationContact]: 'Contact',
  [LocalizationKey.ComponentsContactTableWithAssociationType]: 'Type',
  [LocalizationKey.ComponentsContactTypeListCheckboxesAll]: 'All',
  [LocalizationKey.ComponentsContactTypeListCheckboxesCreateType]:
    'Create type',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotify]: 'Spotify',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistManual]: 'Manual',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistSpotifyUrl]:
    'Spotify URL',
  [LocalizationKey.ComponentsCustomInputsSelectFooterArtistArtistName]:
    'Artist name',
  [LocalizationKey.ComponentsCustomInputsDropzone]: 'Upload a file',
  [LocalizationKey.ComponentsEventAutoImportVenueContacts]:
    "Contacts found on venue {venue}! Click here to assign venue's contacts to this events!",
  [LocalizationKey.ComponentsEventCover]: 'Upload event cover',
  [LocalizationKey.ComponentsFileCell]: 'Download',
  [LocalizationKey.ComponentsFileShareShare]: 'Share',
  [LocalizationKey.ComponentsFileSharePublicLinkGenerated]:
    'Public link generated and copied to clipboard',
  [LocalizationKey.ComponentsFileSharePublicLinkError]:
    'Error while generating the public link',
  [LocalizationKey.ComponentsFileSharePublicLinkCopiedToClipboard]:
    'Link copied to your clipboard',
  [LocalizationKey.ComponentsFileShareCopyPublicLink]:
    'Copy public link to clipboard',
  [LocalizationKey.ComponentsFileUpload]: 'Upload multiple files',
  [LocalizationKey.ComponentsInvitationResendEmail]: 'Resend invitation email',
  [LocalizationKey.ComponentsPerformanceTableArtist]: 'Artist',
  [LocalizationKey.ComponentsPerformanceTableSetTime]: 'Set time',
  [LocalizationKey.ComponentsPerformanceTableStage]: 'Stage',
  [LocalizationKey.ComponentsSBTableEmpty]: 'No {entity} found',
  [LocalizationKey.ComponentsSBActivityLogCreated]: 'Created',
  [LocalizationKey.ComponentsSBActivityLogUpdated]: 'Updated',
  [LocalizationKey.ComponentsSBActivityLogDeleted]: 'Deleted',
  [LocalizationKey.ComponentsNewVersionCheckerTitle]: 'New version available',
  [LocalizationKey.ComponentsNewVersionCheckerDescription]:
    'Great features are coming, refresh to start using them',
  [LocalizationKey.ComponentsNewVersionCheckerRefresh]: 'Refresh',
  [LocalizationKey.ComponentsMaintenanceModeTitle]: 'Under maintenance',
  [LocalizationKey.ComponentsMaintenanceModeDescription]:
    'We are enhancing your experience. Please check back soon!',
  [LocalizationKey.ComponentsMaintenanceModeReload]: 'Reload',
  [LocalizationKey.ComponentsGroundCascaderSelectBooking]:
    'Select a booking to display its resources',
  [LocalizationKey.ComponentsGroundCascaderItemFrom]: 'From',
  [LocalizationKey.ComponentsGroundCascaderItemTo]: 'To',
  [LocalizationKey.ComponentsGroundCascaderItemHotels]: 'Hotels',
  [LocalizationKey.ComponentsGroundCascaderItemVenues]: 'Venues',
  [LocalizationKey.ComponentsGroundCascaderItemInbound]: 'Inbound',
  [LocalizationKey.ComponentsGroundCascaderItemOutbound]: 'Outbound',
  [LocalizationKey.ComponentsGroundCascaderItemCustomAddress]: 'Custom address',
  [LocalizationKey.ComponentsGroundCascaderItemNewCustomAddress]:
    'New custom address',
  [LocalizationKey.ComponentsInvoiceFiltersLast7Days]: 'Last 7 days',
  [LocalizationKey.ComponentsInvoiceFiltersLast14Days]: 'Last 14 days',
  [LocalizationKey.ComponentsInvoiceFiltersLast30Days]: 'Last 30 days',
  [LocalizationKey.ComponentsInvoiceFiltersLast90Days]: 'Last 90 days',
  [LocalizationKey.ComponentsInvoiceFiltersLast1Year]: 'Last 1 year',
  [LocalizationKey.ComponentsInvoiceFiltersPaid]: 'Paid',
  [LocalizationKey.ComponentsInvoiceFiltersPartiallyPaid]: 'Partially paid',
  [LocalizationKey.ComponentsInvoiceFiltersUnpaid]: 'Unpaid',
  [LocalizationKey.ComponentsInvoiceFiltersAll]: 'All',
  [LocalizationKey.ComponentsInvoiceFiltersVenue]: 'Venue',
  [LocalizationKey.ComponentsInvoiceFiltersDueDate]: 'Due date',
  [LocalizationKey.ComponentsInvoiceFiltersEventDate]: 'Event date',
  [LocalizationKey.ComponentsInvoiceFiltersSearch]: 'Search',
  [LocalizationKey.ComponentsInvoiceFiltersSearchPlaceholder]:
    'Supplier, reference, event...',
  [LocalizationKey.ComponentsInvoiceTableReference]: 'Reference',
  [LocalizationKey.ComponentsInvoiceTableSupplier]: 'Supplier',
  [LocalizationKey.ComponentsInvoiceTableAmount]: 'Amount',
  [LocalizationKey.ComponentsInvoiceTableDueDate]: 'Due date',
  [LocalizationKey.ComponentsInvoiceTableEvent]: 'Event',
  [LocalizationKey.ComponentsInvoiceTableLinkedTo]: 'Linked to',
  [LocalizationKey.ComponentsInvoiceTableAddPayment]: 'Add payment',
  [LocalizationKey.ComponentsNotificationDropdownTitle]:
    'Subscribe to real-time updates',
  [LocalizationKey.ComponentsNotificationDropdownDescription1]:
    'Stay in the loop and never miss a beat!',
  [LocalizationKey.ComponentsNotificationDropdownDescription2]:
    'Enable notifications to get the latest updates and news from your team',
  [LocalizationKey.ComponentsNotificationDropdownAccept]:
    'Accept notifications',
  [LocalizationKey.ComponentsNotificationDropdownError]:
    "Looks like you denied the notification 🥺 You won't be receiving real-time updates from you team work ... But we're still friends, right ?",
  [LocalizationKey.ComponentsNotificationDropdownEndMesssage]:
    'It is all, nothing more 🤐',
  [LocalizationKey.ComponentsNotificationDropdownRead]: 'Read',
  [LocalizationKey.ComponentsNotificationMarkAllAsRead]: 'Mark all as read',
  [LocalizationKey.ComponentsOrganizationBillingInfosManage]:
    'Manage billing informations',
  [LocalizationKey.ComponentsOrganizationPlanYourOrganization]:
    'Your organization',
  [LocalizationKey.ComponentsOrganizationPlanUsers]: 'Paid users',
  [LocalizationKey.ComponentsOrganizationPlanPricePerUser]:
    'Price per user VAT ex. / month',
  [LocalizationKey.ComponentsOrganizationPlanTotalPrice]:
    'Total price VAT ex. / month',
  [LocalizationKey.ComponentsOrganizationPlanToEditGoToPortal]:
    "To edit your billing informations click the button below to be redirected to our partner's dedicated portal",
  [LocalizationKey.ComponentsOrganizationPlanMoveTheCursor]:
    'Move the cursor above to estimate your costs while your team will grow',
  [LocalizationKey.ComponentsOrganizationPlanTrialsEndsOn]: 'Trial period',
  [LocalizationKey.ComponentsOrganizationPlanTrialsEndsOnDescription]:
    'Trial ends on',
  [LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethod]:
    'No default payment method',
  [LocalizationKey.ComponentsOrganizationPlanNoDefaultPaymentMethodDescription]:
    'Without a default payment method your account will be suspended on next subscription renewal',
  [LocalizationKey.ComponentsOrganizationPlanReset]: 'Reset',
  [LocalizationKey.ComponentsOrganizationRolesPermissions]: 'Permissions',
  [LocalizationKey.ComponentsOrganizationRolesMarketing]: 'Marketing',
  [LocalizationKey.ComponentsOrganizationRolesVisitor]: 'Visitor',
  [LocalizationKey.ComponentsOrganizationRolesProductionManager]:
    'Production Manager',
  [LocalizationKey.ComponentsOrganizationRolesfinancialManager]:
    'Financial Manager',
  [LocalizationKey.ComponentsOrganizationRolesGeneralManager]:
    'General Manager',
  [LocalizationKey.ComponentsOrganizationRolesAdmin]: 'Admin',
  [LocalizationKey.ComponentsPaymentListAddPayment]: 'Add payment',
  [LocalizationKey.ComponentsPaymentListAddFirstPayment]:
    'Add your first payment',
  [LocalizationKey.ComponentsTravelSearcherNoTravelFound]: 'No travel found',
  [LocalizationKey.ComponentsTravelSearcherError]:
    'Error while fetching travels',
  [LocalizationKey.ComponentsTravelSearcherFillBeforeSearching]:
    'Fill date and number before searching',
  [LocalizationKey.ComponentsTravelSearcherCreateManually]: 'Create manually',
  [LocalizationKey.ComponentsTravelSearcherSearch]: 'Search',
  [LocalizationKey.ComponentsTravelSearcherSelect]: 'Select',
  [LocalizationKey.ComponentsTravelSearcherChange]: 'Change stations',
  [LocalizationKey.ComponentsTravelSearcherValidate]: 'Validate stations',
  [LocalizationKey.ComponentsUserAssociateContactDescription]:
    'By associating a contact to an user, the user will be able to receive notifications based on the contact associations. Notifications preferences are available for each user on their',
  [LocalizationKey.ComponentsUserAssociateContactPofileLabel]: 'profile page',
  [LocalizationKey.ComponentsUserAssociateContactDescription2]:
    "Associating a contact to this user will replace contact's details - name, email & phone - with the user's details",
  [LocalizationKey.ComponentsUserAssociateContactSearchPlaceholder]:
    'Search a contact to associate',
  [LocalizationKey.ComponentsUserAssociateContactAssociate]: 'Associate',
  [LocalizationKey.ComponentsUserAvatarDeletedUser]: 'Deleted user',
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsDescription]:
    'Prior creating event reports you need to configure your report items in the settings',
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsTitle]: 'No items yet',
  [LocalizationKey.ComponentsReportingWizardStep1NoItemsGoToSettings]:
    'Go to settings',
  [LocalizationKey.ComponentsReportingWizardStep1Costs]: 'Costs',
  [LocalizationKey.ComponentsReportingWizardStep1Revenues]: 'Revenues',
  [LocalizationKey.ComponentsReportingWizardError]:
    'Error while saving the report',
  [LocalizationKey.ComponentsReportingWizardAtLeast1Filled]:
    'At least 1 costs & revenues item must be filled',
  [LocalizationKey.ComponentsReportingWizardPreview]: 'Preview',
  [LocalizationKey.ComponentsReportingWizardMisc]: 'Misc',
  [LocalizationKey.ComponentsReportingWizardCostsAndRevenues]:
    'Costs & revenues',
  [LocalizationKey.ComponentsReportingEventAlertDescription]:
    "Keep track of your event's finances with the Event Report",
  [LocalizationKey.ComponentsReportingEventAlertTitle]: 'Event report',
  [LocalizationKey.ComponentsReportingEventAlertButton]: 'Open report',
  [LocalizationKey.ComponentsReportingOverviewMargin]: 'Profit margin',
  [LocalizationKey.ComponentsReportingOverviewAttendees]: 'Attendees',
  [LocalizationKey.ComponentsReportingOverviewBalance]: 'Balance',
  [LocalizationKey.ComponentsReportingOverviewCosts]: 'Costs',
  [LocalizationKey.ComponentsReportingOverviewRevenues]: 'Revenues',
  [LocalizationKey.ComponentsReportingChartEvolution]: 'Evolution',
  [LocalizationKey.ComponentsWeatherCardTitle]: 'Weather report',
  [LocalizationKey.ComponentsWeatherCardTemp]: 'Temp',
  [LocalizationKey.ComponentsWeatherCardTemperature]: 'Temperature',
  [LocalizationKey.ComponentsWeatherCardHumm]: 'Humm',
  [LocalizationKey.ComponentsWeatherCardWind]: 'Wind',
  [LocalizationKey.ComponentsWeatherCardRain]: 'Rain',

  [LocalizationKey.ComponentsCompleteYourProfile]: 'Complete your profile',

  /**
   * MISC
   */

  [LocalizationKey.MiscButtonUpdate]: 'Update',

  [LocalizationKey.MiscTableActions]: 'Actions',
  [LocalizationKey.MiscTableName]: 'Name',
  [LocalizationKey.MiscTableColor]: 'Color',
  [LocalizationKey.MiscTableIcon]: 'Icon',
  [LocalizationKey.MiscTableSearchSearch]: 'Search',
  [LocalizationKey.MiscTableSearchReset]: 'Reset',
  [LocalizationKey.MiscTableSearchClose]: 'Close',

  [LocalizationKey.MiscFormRole]: 'Role',
  [LocalizationKey.MiscFormTitle]: 'Title',
  [LocalizationKey.MiscFormDescription]: 'Description',
  [LocalizationKey.MiscFormName]: 'Name',
  [LocalizationKey.MiscFormFirstName]: 'First name',
  [LocalizationKey.MiscFormLastName]: 'Last name',
  [LocalizationKey.MiscFormEmail]: 'Email',
  [LocalizationKey.MiscFormPhone]: 'Phone',
  [LocalizationKey.MiscFormPassword]: 'Password',
  [LocalizationKey.MiscFormPasswordConfirmation]: 'Password confirmation',
  [LocalizationKey.MiscFormRememberMe]: 'Remember me',
  [LocalizationKey.MiscFormAdd]: 'Add',
  [LocalizationKey.MiscFormImport]: 'Import',
  [LocalizationKey.MiscFormUpdate]: 'Update',
  [LocalizationKey.MiscFormCancelModifications]: 'Cancel modifications',
  [LocalizationKey.MiscFormAddress]: 'Address',
  [LocalizationKey.MiscFormAddressSearch]: 'Search an address ...',
  [LocalizationKey.MiscFormAddressCloseManual]: 'Close manual panel',
  [LocalizationKey.MiscFormAddressOpenManual]: 'Create custom address manually',
  [LocalizationKey.MiscFormAddressLabel]: 'Label',
  [LocalizationKey.MiscFormAddressStreetNumber]: 'Street number',
  [LocalizationKey.MiscFormAddressStreet]: 'Street',
  [LocalizationKey.MiscFormAddressCity]: 'City',
  [LocalizationKey.MiscFormAddressPostCode]: 'Post code',
  [LocalizationKey.MiscFormAddressState]: 'State',
  [LocalizationKey.MiscFormAddressCountry]: 'Country',
  [LocalizationKey.MiscFormAddressLatitude]: 'Latitude',
  [LocalizationKey.MiscFormAddressLongitude]: 'Longitude',
  [LocalizationKey.MiscFormCompanyName]: 'Company name',
  [LocalizationKey.MiscFormTaxNumber]: 'Tax number',
  [LocalizationKey.MiscFormHowToCreate]: 'How to create?',
  [LocalizationKey.MiscFormAreYouSureToDelete]:
    'Are you sure to delete this item ?',
  [LocalizationKey.MiscFormBooking]: 'Booking',
  [LocalizationKey.MiscFormEvent]: 'Event',
  [LocalizationKey.MiscFormArtist]: 'Artist',
  [LocalizationKey.MiscFormType]: 'Type',
  [LocalizationKey.MiscFormPrice]: 'Price',
  [LocalizationKey.MiscFormMemoDeal]: 'Memo deal',
  [LocalizationKey.MiscFormActionCannotBeUndone]:
    'This action cannot be undone.',
  [LocalizationKey.MiscFormBookingResourcesDeleteDescription]:
    'All resources associated with this booking will also be deleted including performances, contracts, riders, grounds, rooms, ...',
  [LocalizationKey.MiscFormInputValueToConfirm]: 'Input {value} to confirm',
  [LocalizationKey.MiscFormOwner]: 'Owner',
  [LocalizationKey.MiscFormComment]: 'Comment',
  [LocalizationKey.MiscFormCompany]: 'Company',
  [LocalizationKey.MiscFormContact]: 'Contact',
  [LocalizationKey.MiscFormContactAssociationEventTitle]:
    'Associate the contact also to the event venues ?',
  [LocalizationKey.MiscFormContactAssociationEventDescription]:
    'In case multiple venues are associated to your event, the contact will be attached to all venues',
  [LocalizationKey.MiscFormContactAssociationBookinTitle]:
    'Associate the contact also on the artist page ?',
  [LocalizationKey.MiscFormColor]: 'Color',
  [LocalizationKey.MiscFormIcon]: 'Icon',
  [LocalizationKey.MiscFormStatus]: 'Status',
  [LocalizationKey.MiscFormAssignee]: 'Assignee',
  [LocalizationKey.MiscFormNotes]: 'Notes',
  [LocalizationKey.MiscFormReplaceTheFile]: 'Replace the file',
  [LocalizationKey.MiscFormUploadFileToReplaceCurrent]:
    'Upload a file to replace the current one',
  [LocalizationKey.MiscFormVenues]: 'Venue(s)',
  [LocalizationKey.MiscFormDate]: 'Date',
  [LocalizationKey.MiscFormDates]: 'Dates',
  [LocalizationKey.MiscFormEventUpdateDateChanging]:
    'When changing the start date to an other day, all performances will be moved to this new day',
  [LocalizationKey.MiscFormEventUpdateDateChangingWarning]:
    'Warning: All performances out of the new timerange will be deleted!',
  [LocalizationKey.MiscFormVisibleBy]: 'Visible by',
  [LocalizationKey.MiscFormIsDefaultStatus]:
    'Is default event status upon creation ?',
  [LocalizationKey.MiscFormSelectABooking]: 'Select a booking first',
  [LocalizationKey.MiscFormPickupTime]: 'Pickup time',
  [LocalizationKey.MiscFormDueDate]: 'Due date',
  [LocalizationKey.MiscFormDuration]: 'Duration',
  [LocalizationKey.MiscFormPassengers]: 'Passengers',
  [LocalizationKey.MiscFormDriver]: 'Driver',
  [LocalizationKey.MiscFormVehicleDetails]: 'Vehicle details',
  [LocalizationKey.MiscFormStars]: 'Stars',
  [LocalizationKey.MiscFormInvoiceHowToCreate]: 'How to create ?',
  [LocalizationKey.MiscFormInvoiceUploadPDF]: 'Upload PDF',
  [LocalizationKey.MiscFormInvoiceManual]: 'Manual',
  [LocalizationKey.MiscFormInvoiceFileSizeWarning]:
    "The file size is larger than 10 MB. Our AI will not be able to extract the invoice's informations automatically.",
  [LocalizationKey.MiscFormInvoiceReference]: 'Reference',
  [LocalizationKey.MiscFormInvoiceAmount]: 'Amount',
  [LocalizationKey.MiscFormInvoiceAmountInclTaxes]: 'Amount including taxes',
  [LocalizationKey.MiscFormInvoiceAmountExclTaxes]: 'Amount excluding taxes',
  [LocalizationKey.MiscFormInvoiceDueDate]: 'Due date',
  [LocalizationKey.MiscFormInvoiceSupplier]: 'Supplier',
  [LocalizationKey.MiscFormInvoicePayments]: 'Payments',
  [LocalizationKey.MiscFormInvoiceOptions]: 'Options',
  [LocalizationKey.MiscFormInvoiceCategory]: 'Category',
  [LocalizationKey.MiscFormPaymentCalculatePaidAmount]: 'Calculate paid amount',
  [LocalizationKey.MiscFormPaymentOperationType]: 'Operation type',
  [LocalizationKey.MiscFormPaymentBankTransfer]: 'Bank transfer',
  [LocalizationKey.MiscFormPaymentCard]: 'Card',
  [LocalizationKey.MiscFormPaymentCheck]: 'Check',
  [LocalizationKey.MiscFormPaymentCash]: 'Cash',
  [LocalizationKey.MiscFormPaymentOperationDate]: 'Operation date',
  [LocalizationKey.MiscFormPaymentPOP]: 'POP - Proof of payment',
  [LocalizationKey.MiscFormReportItemCost]: 'Cost',
  [LocalizationKey.MiscFormReportItemRevenue]: 'Revenue',
  [LocalizationKey.MiscFormStage]: 'Stage',
  [LocalizationKey.MiscFormRoomHotel]: 'Hotel',
  [LocalizationKey.MiscFormRoomReference]: 'Reference',
  [LocalizationKey.MiscFormRoomCategory]: 'Category',
  [LocalizationKey.MiscFormRoomTravelers]: 'Travelers',
  [LocalizationKey.MiscFormRoomPrice]: 'Price',
  [LocalizationKey.MiscFormRoomRoomCredits]: 'Room credits',
  [LocalizationKey.MiscFormStationNoStationFound]: 'No station found',
  [LocalizationKey.MiscFormStationError]: 'Error while fetching stations',
  [LocalizationKey.MiscFormStationMinLength]: 'Minimum length is 3 caracters',
  [LocalizationKey.MiscFormStationTransportType]: 'Station type',
  [LocalizationKey.MiscFormStationAirport]: 'Airport',
  [LocalizationKey.MiscFormStationTrainStation]: 'Train station',
  [LocalizationKey.MiscFormStationSearch]: 'Search',
  [LocalizationKey.MiscFormStationAdded]: 'Added',
  [LocalizationKey.MiscFormRegistrationNumber]: 'Registration number',
  [LocalizationKey.MiscFormTravelType]: 'Travel type',
  [LocalizationKey.MiscFormTravelInbound]: 'Inbound',
  [LocalizationKey.MiscFormTravelOutbound]: 'Outbound',
  [LocalizationKey.MiscFormTravelTransportType]: 'Transport type',
  [LocalizationKey.MiscFormTravelPlane]: 'Plane',
  [LocalizationKey.MiscFormTravelTrain]: 'Train',
  [LocalizationKey.MiscFormTravelFlightNumber]: 'Flight number',
  [LocalizationKey.MiscFormTravelTrainNumber]: 'Train number',
  [LocalizationKey.MiscFormTravelDeparture]: 'Departure',
  [LocalizationKey.MiscFormTravelDepartureTime]: 'Departure time',
  [LocalizationKey.MiscFormTravelArrival]: 'Arrival',
  [LocalizationKey.MiscFormTravelArrivalTime]: 'Arrival time',
  [LocalizationKey.MiscFormUserResourcesDeleteDescription]:
    'The user will be immediately deleted and will not longer have access to this organization. All the user activity logs will be deleted. All resources associated to its contact will not be deleted. Its contact will not be deleted.',
  [LocalizationKey.MiscFormEventColorOnCalendar]: 'Event color on calendar',
  [LocalizationKey.MiscFormOpening]: 'Opening',
  [LocalizationKey.MiscFormClosing]: 'Closing',
  [LocalizationKey.MiscFormClosingIsOnNextDay]: 'Closing is on next day',
  [LocalizationKey.MiscFormPrevious]: 'Previous',
  [LocalizationKey.MiscFormNext]: 'Next',
  [LocalizationKey.MiscFormCurrency]: 'Currency',
  [LocalizationKey.MiscFormUnitOfMeasurement]: 'Unit of measurement',
  [LocalizationKey.MiscFormLocale]: 'Locale',

  [LocalizationKey.MiscErrorRequire]: 'This field is required',
  [LocalizationKey.MiscErrorTypeString]: 'Input is not a valid string',
  [LocalizationKey.MiscErrorTypeNumber]: 'Input is not a valid number',
  [LocalizationKey.MiscErrorTypeLink]: 'Input is not a valid link',
  [LocalizationKey.MiscErrorTypeEmail]: 'Input is not a valid email',
  [LocalizationKey.MiscErrorNotValidPhone]: 'Phone number must be valid',
  [LocalizationKey.MiscErrorMinLength]:
    'The value must have at least {length} caracters',
  [LocalizationKey.MiscErrorCaps]:
    'The value must contain at least one capital letter',
  [LocalizationKey.MiscErrorLowercase]:
    'The value must contain at least one lowercase letter',
  [LocalizationKey.MiscErrorContainsNumber]:
    'The value must have at lest one number',
  [LocalizationKey.MiscErrorContainsSymbol]:
    'The value must have at least one symbol',
  [LocalizationKey.MiscErrorMatchValue]:
    'The value mismatch the expected value',

  [LocalizationKey.MiscColorPink]: 'Pink',
  [LocalizationKey.MiscColorRed]: 'Red',
  [LocalizationKey.MiscColorYellow]: 'Yellow',
  [LocalizationKey.MiscColorOrange]: 'Orange',
  [LocalizationKey.MiscColorCyan]: 'Cyan',
  [LocalizationKey.MiscColorGreen]: 'Green',
  [LocalizationKey.MiscColorBlue]: 'Blue',
  [LocalizationKey.MiscColorPurple]: 'Purple',
  [LocalizationKey.MiscColorGeekblue]: 'Geekblue',
  [LocalizationKey.MiscColorMagenta]: 'Magenta',
  [LocalizationKey.MiscColorVolcano]: 'Volcano',
  [LocalizationKey.MiscColorGold]: 'Gold',
  [LocalizationKey.MiscColorLime]: 'Lime',

  [LocalizationKey.EntityArtist]: 'artist',
  [LocalizationKey.EntityEvent]: 'event',
  [LocalizationKey.EntityEventStatus]: 'event status',
  [LocalizationKey.EntityBooking]: 'booking',
  [LocalizationKey.EntityBookingType]: 'booking type',
  [LocalizationKey.EntityContract]: 'contract',
  [LocalizationKey.EntityContractStatus]: 'contract status',
  [LocalizationKey.EntityInvoice]: 'invoice',
  [LocalizationKey.EntityPayment]: 'payment',
  [LocalizationKey.EntityHotel]: 'hotel',
  [LocalizationKey.EntityRoom]: 'room',
  [LocalizationKey.EntityRider]: 'rider',
  [LocalizationKey.EntityRiderStatus]: 'rider status',
  [LocalizationKey.EntityFile]: 'file',
  [LocalizationKey.EntityUser]: 'user',
  [LocalizationKey.EntityVenue]: 'venue',
  [LocalizationKey.EntityVenueType]: 'venue type',
  [LocalizationKey.EntityStage]: 'stage',
  [LocalizationKey.EntityContact]: 'contact',
  [LocalizationKey.EntityContactType]: 'contact type',
  [LocalizationKey.EntityContactAssociation]: 'contact association',
  [LocalizationKey.EntityTravel]: 'travel',
  [LocalizationKey.EntityTravelType]: 'travel type',
  [LocalizationKey.EntityGround]: 'ground',
  [LocalizationKey.EntityPerformance]: 'performance',
  [LocalizationKey.EntityStation]: 'station',
  [LocalizationKey.EntityStationType]: 'station type',
  [LocalizationKey.EntityPublicLink]: 'public link',
  [LocalizationKey.EntityRole]: 'role',
  [LocalizationKey.EntityCustomAddress]: 'customaddress',
  [LocalizationKey.EntitySupplier]: 'supplier',
  [LocalizationKey.EntitySupplierType]: 'supplier type',
  [LocalizationKey.EntityInvoiceCategory]: 'invoice category',
  [LocalizationKey.EntityInvitation]: 'invitation',
  [LocalizationKey.EntityNotification]: 'notification',
  [LocalizationKey.EntityReport]: 'report',
  [LocalizationKey.EntityReportItem]: 'report item',
  [LocalizationKey.EntityTask]: 'task',
  [LocalizationKey.EntityTaskStatus]: 'task status',
  [LocalizationKey.EntityExternalAdvancingLink]: 'external advancing link',
  [LocalizationKey.EntityCurrency]: 'currency',

  /**
   * HELP
   */
  [LocalizationKey.HelpArticleAddAndManageHotelRooms]:
    'Add and manage hotel rooms',
  [LocalizationKey.HelpArticleManageEventStatusAndPermissions]:
    'Manage event status and permissions',
  [LocalizationKey.HelpArticleAddAndManageGroundTransfers]:
    'Add and manage ground transfers',
  [LocalizationKey.HelpArticleAddAndManageTravels]: 'Add and manage travels',
  [LocalizationKey.HelpArticleCreateAVenue]: 'Create a venue',
  [LocalizationKey.HelpArticleAddAndManagePartyCrew]:
    'Add and manage party crew',
  [LocalizationKey.HelpArticleCreateAndManageYourEvent]:
    'Create and manage your event',
  [LocalizationKey.HelpArticleAddAndManageTimetable]:
    'Add and manage timetable',
  [LocalizationKey.HelpArticleShareAFile]: 'Share a file',
  [LocalizationKey.HelpArticleAddAndManageTechnicalSheets]:
    'Add and manage technical sheets',
  [LocalizationKey.HelpArticleManageTeamAndPermissions]:
    'Manage team and permissions',
  [LocalizationKey.HelpArticleAddAndManageContact]: 'Add and manage contact',
  [LocalizationKey.HelpArticleAddContractOnEvent]: 'Add contract on event',
  [LocalizationKey.HelpArticleAddAndManageHotels]: 'Add and manage hotels',
  [LocalizationKey.HelpArticleAddAndManageLineUp]: 'Add and manage line-up',
  [LocalizationKey.HelpArticleHowToAddAnInvoice]: 'How to add an invoice',
  [LocalizationKey.HelpArticleHowToMarkAnInvoiceAsPaid]:
    'How to mark an invoice as paid',
  [LocalizationKey.HelpArticleHowToGenerateRoadsheet]:
    'How to generate roadsheet',

  [LocalizationKey.HelpAlertTitle]: 'You need some help ?',
  [LocalizationKey.HelpAlertDescription]:
    'Read our docs to learn how to use Alela software !',
  [LocalizationKey.HelpAlertButton]: 'Open doc',
}

export default EN
